<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="col-sm" style="margin-top:25px;">
                    <div class="input-group mb-3">                        
                        <input type="number" v-on:keyup.enter="BuscarFamilia" v-model="documento" class="form-control" aria-label="Username" aria-describedby="basic-addon1">
                        <div class="input-group-prepend">
                            <button type="button" class="btn btn-secondary" @click="BuscarFamilia">Buscar</button>
                        </div>
                    </div>
                </div>
                <div class="col-sm" style="margin-top:25px;">
                    <div class="input-group">
                        <button type="button" class="btn btn-primary" @click="verNuevaFamilia=!verNuevaFamilia">Nuevo hogar</button>
                        <select v-if="verNuevaFamilia" class="form-control" v-model="tipo" @change="CrearFamilia">
                            <option selected value="0">Seleccione tipo creación</option>
                            <option value="2">a. No aparece, En valoración, No Incluido y vive en Medellín.</option>
                            <option value="1">b. Incluida y vive en Medellín.</option>
                        </select>
                    </div>
                </div>
                <div class="col-sm">
                </div>
                
            </div>
            <div v-if="grupoFamiliar.length != 0" class="row">
                <div class="col-sm">
                    <div v-if="grupoFamiliar.length != 0" class="row justify-content-md-end">
                        <div class="col-sm-2">
                            <b-button @click="nuevoIntegrante">Agregar integrante</b-button>
                        </div>
                    </div>
                    <table-lite v-if="grupoFamiliar.length != 0"
                        :is-slot-mode="true"
                        :columns="columnasGrupoFamiliar"
                        :rows="grupoFamiliar">
                        <template v-slot:nombre="data">
                            {{data.value.nombre}}
                        </template>
                        <template v-slot:idpersona="data">
                            {{data.value.idpersona}}
                        </template>
                        <template v-slot:parentesco="data">
                            {{data.value.parentesco}}
                        </template>
                        <template v-slot:edad="data">
                            {{data.value.edad}}
                        </template>
                        <template v-slot:actions="data">
                            <button type="button" class="btn btn-block btn-default" @click="mostrarPersona(data.value)">Actualizar</button>
                        </template>
                    </table-lite>
                </div>
            </div>
        </div>
        <div v-if="verFormPersona" class="container">            
            <div class="row" v-if="tipo == 2">
                <h5>Familia No aparece, En valoración, No Incluido y vive en Medellín.</h5>
            </div>
            <div class="row" v-if="tipo == 1">
                <h5>Familia Incluida y vive en Medellín.</h5>
            </div>
            <form @submit.prevent="guardarPersona">
                <div class="card">
                    <div class="card-header">
                        <h5 class="card-title">Datos Personales</h5>
                        <div class="row justify-content-md-end">
                            <div class="col-sm-2">
                                <input type="button" class="form-control" value="Volver" @click="verFormPersona=!verFormPersona">
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-4">
                                <label for="">Fecha creación: </label>
                                <span>{{persona.fechacreacion}}</span>
                            </div>
                            <div class="col-sm-4">
                                <label for="">Fecha última caracterización: </label>
                                <span>{{persona.fechamodificacion}}</span>
                            </div>
                            <div class="col-sm-4">{{persona.id}}</div>
                        </div>
                        <div class="row justify-content-md-end">
                            <div class="col-sm-3">
                                <div class="input-group">
                                    <span class="input-group-text"><b>Código RUV: </b></span>
                                    <input type="text" class="form-control" v-model="persona.nodeclaracion">
                                </div>
                                
                            </div>
                            <div class="col-sm-4">
                                <div class="input-group">
                                    <span class="input-group-text">F. de Valoración: </span>
                                    <input type="date" class="form-control" v-model="persona.fecharegistrosipod">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-3">
                                <div class="input-group">
                                    <span class="input-group-text">Tipo Identificación: </span>
                                    <select class="form-control" v-model="persona.tipoid" required>
                                        <option v-for="tipo in listas.tipoIdentificacion" 
                                            v-bind:key="tipo.ididentificacion"
                                            :value="tipo.ididentificacion">{{tipo.tipoidentificacion}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="input-group">
                                    <span class="input-group-text">Identificación: </span>
                                    <input type="text" class="form-control" v-model="persona.idpersona" required>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-5">
                                <div class="input-group">
                                    <span class="input-group-text">Primer Nombre: </span>
                                    <input type="text" class="form-control" v-model="persona.nombre1" required>
                                </div>
                            </div>
                            <div class="col-sm-2"></div>
                            <div class="col-sm-5">
                                <div class="input-group">
                                    <span class="input-group-text">Segundo Nombre: </span>
                                    <input type="text" class="form-control" v-model="persona.nombre2">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-5">
                                <div class="input-group">
                                    <span class="input-group-text">Primer Apellido:</span>
                                    <input type="text" class="form-control" v-model="persona.apellido1" required>
                                </div>
                            </div>
                            <div class="col-sm-2"></div>
                            <div class="col-sm-5">
                                <div class="input-group">
                                    <span class="input-group-text">Segundo Apellido: </span>
                                    <input type="text" class="form-control" v-model="persona.apellido2">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-3">
                                <div class="input-group">
                                    <span class="input-group-text">Parentesco:</span>
                                    <select  class="form-control" v-model="persona.idparentesco">
                                        <option v-for="item in listas.parentescos" 
                                            v-bind:key="item.idparentesco"
                                            :value="item.idparentesco"
                                            :disabled="soloJefe">{{item.parentesco}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="input-group">
                                    <span class="input-group-text">Estado RUV: </span>
                                    <select  class="form-control" v-model="persona.incluido" required>
                                        <option v-for="item in listas.estadosRuv" 
                                            v-bind:key="item.idestado"
                                            :value="item.idestado" >{{item.estado}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" 
                                        v-model="persona.lgtbi"
                                        :checked="persona.lgtbi">
                                    <label class="form-check-label" for="flexCheckChecked">
                                        LGBTIQ
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" 
                                        v-model="persona.fallecido"
                                        :checked="persona.fallecido">
                                    <label class="form-check-label" for="flexCheckChecked">
                                        Fallecido
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-5">
                                <div class="input-group">
                                    <span class="input-group-text">Celular: </span>
                                    <input type="text" class="form-control" v-model="persona.celular">
                                </div>
                            </div>
                            <div class="col-sm-5">
                                <div class="input-group">
                                    <span class="input-group-text">Email: </span>
                                    <input type="email" class="form-control" v-model="persona.email">
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <div class="card">
                    <div class="card-header">
                        <h5>Características Diferenciales</h5>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-3">
                                <div class="input-group">
                                    <span class="input-group-text">Sexo: </span>
                                    <select class="form-control" v-model="persona.sexo" required>
                                        <option v-for="item in listas.sexo" 
                                            v-bind:key="item.value"
                                            :value="item.value">{{item.text}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-5">
                                <div class="input-group">
                                    <span class="input-group-text">
                                         	F. Nacimiento:
                                    </span>
                                    <input class="form-control" type="date" 
                                        v-model="persona.fechaNacimiento" 
                                        @change="calculaEdad"
                                        required>
                                    <span class="input-group-text">
                                         	*Edad: {{persona.edad}}
                                    </span>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="input-group">
                                    <span class="input-group-text">Etnia: </span>
                                    <select class="form-control" v-model="persona.tipominoria">
                                        <option v-for="item in listas.etnias" 
                                            v-bind:key="item.idminoria"
                                            :value="item.idminoria">{{item.etnia}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="input-group">
                                    <span class="input-group-text">Discapacidad: </span>
                                    <select class="form-control" v-model="persona.tipodiscapacidad" 
                                        @change="disableCausaDiscapacidad"
                                        required>
                                        <option v-for="item in listas.discapacidades" 
                                            v-bind:key="item.iddiscapacidad"
                                            :value="item.iddiscapacidad">{{item.discapacidad}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-8">
                                <div class="input-group">
                                    <span class="input-group-text">Causa Discapacidad: </span>
                                    <select class="form-control" 
                                        v-model="persona.causa_discapacidad_id"
                                        :disabled="dCausaDiscapacidad">
                                        <option v-for="item in listas.causasDiscapacidad" 
                                            v-bind:key="item.causa_discapacidad_id"
                                            :value="item.causa_discapacidad_id">{{item.causa}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-2">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" 
                                        v-model="persona.gestante"
                                        :checked="persona.gestante"
                                        @change="gestanteCheck">
                                    <label class="form-check-label" for="flexCheckChecked">
                                        Gestante {{persona.gestante}}
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <div class="input-group">
                                    <span class="input-group-text">Meses: </span>
                                    <select class="form-control" 
                                        v-model="persona.mesesgestacion"
                                        :disabled="isGestanteSelected">
                                        <option v-for="item in listas.meses" 
                                            v-bind:key="item.value"
                                            :value="item.value">{{item.text}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="input-group">
                                    <span class="input-group-text">
                                        Fecha Gestancia:
                                    </span>
                                    <input type="date" class="form-control" 
                                        v-model="persona.fecha_gestancia"
                                        :disabled="isGestanteSelected">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-2">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" 
                                        v-model="persona.lactante"
                                        :checked="persona.lactante"
                                        @change="lactanteCheck">
                                    <label class="form-check-label" for="flexCheckChecked">
                                        Lactante 
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="input-group">
                                    <span class="input-group-text">
                                        Fecha Lactancia
                                    </span>
                                    <input type="date" class="form-control" 
                                        v-model="persona.fecha_lactancia"
                                        :disabled="isLactanteSelected">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                        <h5>Capacidades y Potencialidades de la PD</h5>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="input-group">
                                    <span class="input-group-text">Actividad. Actual: </span>
                                    <select class="form-control" v-model="persona.actactual">
                                        <option v-for="item in listas.actividadesPersona" 
                                            v-bind:key="item.idactividad"
                                            :value="item.idactividad">{{item.actividad}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-3"></div>
                            <div class="col-sm-4">
                                <div class="input-group">
                                    <span class="input-group-text">Oficio Actual: </span>
                                    <select class="form-control" v-model="persona.oficioactual">
                                        <option v-for="item in listas.tiposOficios" 
                                            v-bind:key="item.value"
                                            :value="item.value">{{item.text}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="input-group">
                                    <span class="input-group-text">
                                        Habilidades
                                    </span>
                                    <select class="form-control" multiple aria-label="Habilidades" v-model="personaHabilidades">
                                            <option selected>habilidades</option>
                                        </select>
                                </div>
                            </div>
                            <div class="col-sm-3"></div>
                            <div class="col-sm-4">
                                <div class="input-group">
                                    <span class="input-group-text">
                                        Intereses
                                    </span>
                                    <select class="form-control" multiple aria-label="Intereses" v-model="personaIntereses">
                                        <option selected>intereses</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                        <h5>Educación y Salud</h5>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="input-group">
                                    <span class="input-group-text">Asistía Escuela: </span>
                                    <select class="form-control" aria-label="Intereses" 
                                        v-model="persona.asistiaescuela"
                                        @change="asistiaEscuela">
                                        <option v-for="item in listas.sino" 
                                            v-bind:key="item.value"
                                            :value="item.value">{{item.text}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="input-group">
                                    <span class="input-group-text">Nivel Estudio: </span>
                                    <select class="form-control" aria-label="Intereses" 
                                        v-model="persona.tipoestudio" 
                                        :disabled="noAsistiaEscuela">
                                        <option v-for="item in listas.tiposEstudio" 
                                            v-bind:key="item.idtipoestudio"
                                            :value="item.idtipoestudio">{{item.descripcion}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="input-group">
                                    <span class="input-group-text">Años: </span>
                                    <input type="number" 
                                        v-model="persona.ultimogrado" 
                                        :disabled="noAsistiaEscuela">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="input-group">
                                    <span class="input-group-text">Asiste Escuela: </span>
                                    <select class="form-control" aria-label="Intereses" v-model="persona.asisteescuela">
                                        <option v-for="item in listas.sino" 
                                            v-bind:key="item.value"
                                            :value="item.value">{{item.text}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="input-group">
                                    <span class="input-group-text">Estudio Actual: </span>
                                    <select class="form-control" aria-label="Intereses" v-model="persona.tipoestudioactual" required>
                                        <option v-for="item in listas.tiposEstudio" 
                                            v-bind:key="item.idtipoestudio"
                                            :value="item.idtipoestudio">{{item.descripcion}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="input-group">
                                    <span class="input-group-text">Razón No estudia: </span>
                                    <select class="form-control" aria-label="Intereses" v-model="persona.razonnoestudio" required>
                                        <option v-for="item in listas.razonesNoEstudio" 
                                            v-bind:key="item.idrazonnoestudio"
                                            :value="item.idrazonnoestudio">{{item.razonnoestudio}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="input-group">
                                    <span class="input-group-text">Salud: </span>
                                    <select class="form-control" aria-label="Intereses" v-model="persona.salud" required>
                                        <option v-for="item in listas.tiposSalud" 
                                            v-bind:key="item.idsalud"
                                            :value="item.idsalud">{{item.descripcion}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="input-group">
                                    <span class="input-group-text">Razón No Salud: </span>
                                    <select class="form-control" aria-label="Intereses" v-model="persona.razonnosalud" required>
                                        <option v-for="item in listas.razonesNoSalud" 
                                            v-bind:key="item.idrazonnoestudio"
                                            :value="item.idrazonnoestudio">{{item.razonnoestudio}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="input-group">
                                    <span class="input-group-text">Sisben: </span>
                                    <select class="form-control" aria-label="Intereses" v-model="persona.sisben">
                                        <option v-for="item in listas.sino" 
                                            v-bind:key="item.value"
                                            :value="item.value">{{item.text}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="input-group">
                                    <span class="input-group-text">Lee/Escribe: </span>
                                    <select class="form-control" aria-label="Intereses" v-model="persona.leeescribe">
                                        <option v-for="item in listas.sino" 
                                            v-bind:key="item.value"
                                            :value="item.value">{{item.text}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div v-if="!guardandoPersona" class="col-sm-3">
                                <input type="submit" class="form-control" value="Guardar">
                            </div>
                            <div v-if="guardandoPersona" class="col-sm-3">
                                <input type="button" class="form-control" value="Guardando" disabled>
                            </div>
                            {{esActualizando}}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import store from '@/store/index';
import TableLite from "vue3-table-lite";
import listas from "@/pages/listas"
import {
    getListaTipo,
    calcularEdad
    } from "@/pages/helpers.js"

export default {
    name: 'nuevohogar',
    components: {
        "table-lite": TableLite
    },
    data() {
        return {
            documento: '',
            token: '',
            url: '',
            userPerfil: '',
            grupoFamiliar: [],
            columnasGrupoFamiliar: [
                { field: "nombre", label: "Nombre" },
                { field: "idpersona", label: "Documento" },
                { field: "parentesco", label: "Parentesco" },
                { field: "edad", label: "Edad" },
                { field: "actions", label: "Elegir"}
            ],
            persona: [],
            cabeza: 0,
            tipo:0,
            verNuevaFamilia: false,
            verFormPersona: false,
            personaHabilidades: '',
            personaIntereses: '',
            listas: listas,
            dCausaDiscapacidad: false,
            isGestanteSelected: false,
            isLactanteSelected: false,
            noAsistiaEscuela: false,
            soloJefe: false,
            guardandoPersona: false,
            esActualizando: false
        }
    },
    methods: {
        calculaEdad: function () {
            this.persona.edad = calcularEdad(this.persona.fechaNacimiento)
        },
        disableCausaDiscapacidad: function (){
            if(this.persona.tipodiscapacidad == 0 || this.persona.tipodiscapacidad == 4 ){
                this.dCausaDiscapacidad = true;
                this.persona.causa_discapacidad_id = 2
            }else{
                this.dCausaDiscapacidad = false;
            }
        },
        gestanteCheck: function() {
            if(!this.persona.gestante){
                this.isGestanteSelected = true
                this.persona.mesesgestacion = 0
                this.persona.fecha_gestancia = ""
            }else{
                this.isGestanteSelected = false
            }
        },
        asistiaEscuela: function(){
            console.log(this.persona.asistiaescuela)
            if(this.persona.asistiaescuela == "NO"){
                this.noAsistiaEscuela = true
                this.persona.tipoestudio = 0
                this.persona.ultimogrado = 0
            }else{
                this.noAsistiaEscuela = false
            }
        },
        lactanteCheck: function() {
            if(!this.persona.lactante){
                this.isLactanteSelected = true
                this.persona.fecha_lactancia = ""
            }else{
                this.isLactanteSelected = false
            }
        },
        BuscarFamilia: function () {
            this.esActualizando = false
            if(this.documento == ""){
                alert("Favor ingrese numero de documento.")
                return false;
            }
            fetch( this.url + 'getgrupofamiliar', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
                body: JSON.stringify ({
                    DOCUMENTO: this.documento
                })
            })
            .then(resp => resp.json())
            .then(data => {
                if(Object.values(data).length == 0){
                    alert("No se encontraron personas con el id: " + this.documento)
                }else{
                    this.grupoFamiliar = Object.values(data);
                    this.verFormPersona = false
                }
                             
            });
        },
        mostrarPersona: function (value){
            this.esActualizando = true
            fetch (this.url + 'getPersona', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
                body: JSON.stringify ({
                    DOCUMENTO: value.idpersona,
                    TIPODOCUMENTO: "TIPODOCUMENTO"
                })
            })
            .then(resp => resp.json())
            .then(data => {
                var dat = Object.values(data);
                if(typeof(dat[0]) === 'object'){
                    dat[0].nota = "Cabeza familia: " + dat[0].idcabezafamilia;
                }else{
                    dat = [{"nota" : dat[0]}]
                }
                this.persona = dat[0];

                this.persona.fallecido = (this.persona.fallecido =="SI")?true:false
                this.persona.gestante = (this.persona.gestante =="SI")?true:false        
                this.persona.lactante = (this.persona.lactante =="SI")?true:false             
                this.persona.lgtbi = (this.persona.lgtbi =="SI")?true:false
                this.persona.fechaNacimiento = new Date(
                    this.persona.anonaci, this.persona.mesnaci, this.persona.dianaci
                ).toISOString().slice(0, 10);
                this.verFormPersona = true;
                this.disableCausaDiscapacidad()
                this.gestanteCheck()
                this.lactanteCheck()
                this.asistiaEscuela()
                if(this.persona.idparentesco == 1){
                    this.soloJefe = true
                }else{this.soloJefe = false}
                console.log(this.persona)
            });            
        },
        guardarPersona: function () {
            this.guardandoPersona = true
            if(this.tipo == 2 || this.tipo == 1){
                this.cabeza = 1
                this.persona.idcabezafamilia = this.persona.idpersona
            }else{
                if(this.persona.idpersona == this.persona.idcabezafamilia){
                    this.cabeza = 1    
                }else{
                    this.cabeza = 0
                }
            }
            let gestante, lactante, lgtbi, fallecido;
            gestante = this.persona.gestante
            lactante = this.persona.lactante      
            lgtbi = this.persona.lgtbi            
            fallecido = this.persona.fallecido
            if(!gestante) {
                this.persona.fecha_gestancia = "1901-01-01"
                gestante = "NO"
            }else{
                gestante = "SI"
            }
            if (!lactante) {
                this.persona.fecha_lactancia = "1901-01-01"
                lactante = "NO"
            }
            let fech = new Date(this.persona.fechaNacimiento)
            this.persona.anonaci = fech.getUTCFullYear()
            this.persona.mesnaci = (fech.getUTCMonth() + 1)
            this.persona.dianaci = fech.getUTCDate()
            
            this.persona.tipo_auto_id = 0
            this.persona.municipio_cedula = 0
            lgtbi = (!this.persona.lgtbi)?"NO":"SI"
            fallecido = (!this.persona.fallecido)?"NO":"SI"
            if(this.persona.fecharegistrosipod == null){
                this.persona.fecharegistrosipod = '1901-01-01'
            }
            if(this.persona.estadocivil == null){
                this.persona.estadocivil = 0
            }

            if(this.esActualizando){
                fetch( this.url + 'updatePersona', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.token}`
                    },
                    body: JSON.stringify ({
                        id: this.persona.id,
                        idpersona: this.persona.idpersona,
                        asistiaescuela: this.persona.asistiaescuela,
                        asisteescuela: this.persona.asisteescuela,
                        tipoestudio: this.persona.tipoestudio,
                        tipoestudioactual: this.persona.tipoestudioactual,
                        ultimogrado: this.persona.ultimogrado,
                        razonnoestudio: this.persona.razonnoestudio,
                        salud: this.persona.salud,
                        razonnosalud: this.persona.razonnosalud,
                        sisben: this.persona.sisben,
                        leeescribe: this.persona.leeescribe,
                        fallecido: fallecido,
                        causa_discapacidad_id: this.persona.causa_discapacidad_id,
                        fecha_lactancia: this.persona.fecha_lactancia,
                        fecha_gestancia: this.persona.fecha_gestancia,
                        tipoid: this.persona.tipoid,
                        nombre1: this.persona.nombre1,
                        nombre2: this.persona.nombre2,
                        apellido1: this.persona.apellido1,
                        apellido2: this.persona.apellido2,
                        anonaci: this.persona.anonaci,
                        mesnaci: this.persona.mesnaci,
                        dianaci: this.persona.dianaci,
                        sexo: this.persona.sexo,
                        gestante: gestante,
                        mesesgestacion: this.persona.mesesgestacion,
                        tipodiscapacidad: this.persona.tipodiscapacidad,
                        tipominoria: this.persona.tipominoria,
                        estadocivil: this.persona.estadocivil,
                        oficioactual: this.persona.oficioactual,
                        incluido: this.persona.incluido,
                        lactante: lactante,
                        fecharegistrosipod: this.persona.fecharegistrosipod,
                        edad: this.persona.edad,
                        msacudiente: this.persona.msacudiente,
                        actactual: this.persona.actactual,
                        huella: this.persona.huella,
                        municipio_cedula: this.persona.municipio_cedula,
                        usuario: localStorage.getItem('login'),
                        lgtbi: lgtbi,
                        celular: this.persona.celular,
                        email: this.persona.email,
                        idcabezafamilia: this.persona.idcabezafamilia,
                        idparentesco: this.persona.idparentesco,
                        tipo: this.persona.tipo,
                        tipo_auto_id: this.persona.tipo_auto_id,
                        nodeclaracion: this.persona.nodeclaracion,
                        tipo: this.tipo,
                        tipo_auto_id: this.persona.tipo_auto_id,
                        nodeclaracion: this.persona.nodeclaracion,
                        cabeza: this.cabeza,
                    })
                })
                .then(resp => resp.json())
                .then(data => {
                    this.esActualizando = false
                    this.guardandoPersona = false
                    alert(data.mensaje);
                    this.BuscarFamilia()
                    return false;
                    this.verFormPersona = false
                    
                });
            }else {
                fetch( this.url + 'savePersona', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.token}`
                    },
                    body: JSON.stringify ({
                        idpersona: this.persona.idpersona,
                        asistiaescuela: this.persona.asistiaescuela,
                        asisteescuela: this.persona.asisteescuela,
                        tipoestudio: this.persona.tipoestudio,
                        tipoestudioactual: this.persona.tipoestudioactual,
                        ultimogrado: this.persona.ultimogrado,
                        razonnoestudio: this.persona.razonnoestudio,
                        salud: this.persona.salud,
                        razonnosalud: this.persona.razonnosalud,
                        sisben: this.persona.sisben,
                        leeescribe: this.persona.leeescribe,
                        fallecido: fallecido,
                        causa_discapacidad_id: this.persona.causa_discapacidad_id,
                        fecha_lactancia: this.persona.fecha_lactancia,
                        fecha_gestancia: this.persona.fecha_gestancia,
                        tipoid: this.persona.tipoid,
                        nombre1: this.persona.nombre1,
                        nombre2: this.persona.nombre2,
                        apellido1: this.persona.apellido1,
                        apellido2: this.persona.apellido2,
                        anonaci: this.persona.anonaci,
                        mesnaci: this.persona.mesnaci,
                        dianaci: this.persona.dianaci,
                        sexo: this.persona.sexo,
                        gestante: gestante,
                        mesesgestacion: this.persona.mesesgestacion,
                        tipodiscapacidad: this.persona.tipodiscapacidad,
                        tipominoria: this.persona.tipominoria,
                        estadocivil: this.persona.estadocivil,
                        oficioactual: this.persona.oficioactual,
                        incluido: this.persona.incluido,
                        lactante: lactante,
                        fecharegistrosipod: this.persona.fecharegistrosipod,
                        edad: this.persona.edad,
                        msacudiente: this.persona.msacudiente,
                        actactual: this.persona.actactual,
                        huella: this.persona.huella,
                        municipio_cedula: this.persona.municipio_cedula,
                        usuario: localStorage.getItem('login'),
                        lgtbi: lgtbi,
                        celular: this.persona.celular,
                        email: this.persona.email,
                        idcabezafamilia: this.persona.idcabezafamilia,
                        idparentesco: this.persona.idparentesco,
                        tipo: this.persona.tipo,
                        tipo_auto_id: this.persona.tipo_auto_id,
                        nodeclaracion: this.persona.nodeclaracion,
                        tipo: this.tipo,
                        tipo_auto_id: this.persona.tipo_auto_id,
                        nodeclaracion: this.persona.nodeclaracion,
                        cabeza: this.cabeza,
                    })
                })
                .then(resp => resp.json())
                .then(data => {
                    this.guardandoPersona = false
                    alert(data.estado);
                    this.BuscarFamilia()
                    return false;
                    this.verFormPersona = false
                    
                });
            }
            return false;
        },
        CrearFamilia: function () {
            this.esActualizando = false
            this.grupoFamiliar = []
            this.limpiarPersona()
            this.verFormPersona = true
            this.persona = this.listas.personaBase
            this.persona.tipo = this.tipo
            this.persona.idparentesco = 1
            this.soloJefe = true
            this.cabeza = 1
            this.disableCausaDiscapacidad()
            this.gestanteCheck()
            this.lactanteCheck()
            this.asistiaEscuela()
        },
        limpiarPersona: function () {
            this.listas.personaBase.id = 0
            this.listas.personaBase.fecha_lactancia = "" 
            this.listas.personaBase.fecha_gestancia = "" 
            this.listas.personaBase.causa_discapacidad_id = 0 
            this.listas.personaBase.idpersona = "" 
            this.listas.personaBase.tipoid = 0 
            this.listas.personaBase.nombre1 = "" 
            this.listas.personaBase.nombre2 = " " 
            this.listas.personaBase.apellido1 = "" 
            this.listas.personaBase.apellido2 = " " 
            this.listas.personaBase.incluido = ""
            this.listas.personaBase.oficioactual = 0 
            this.listas.personaBase.anonaci = 1901 
            this.listas.personaBase.mesnaci = 1 
            this.listas.personaBase.dianaci = 1 
            this.listas.personaBase.sexo = ""
            this.listas.personaBase.estadocivil = 0
            this.listas.personaBase.tipodiscapacidad = 0 
            this.listas.personaBase.tipominoria = 0 
            this.listas.personaBase.gestante = false
            this.listas.personaBase.mesesgestacion = 0 
            this.listas.personaBase.fechacreacion = ""
            this.listas.personaBase.lactante = false 
            this.listas.personaBase.idparentesco = 0
            this.listas.personaBase.idcabezafamilia = ""
            this.listas.personaBase.fecharegistrosipod = "1901-01-01"
            this.listas.personaBase.nodeclaracion = "" 
            this.listas.personaBase.edad = 0
            this.listas.personaBase.msacudiente = "" 
            this.listas.personaBase.actactual = 0
            this.listas.personaBase.fechamodificacion = "" 
            this.listas.personaBase.tipo = 0
            this.listas.personaBase.asistiaescuela = "" 
            this.listas.personaBase.asisteescuela = "" 
            this.listas.personaBase.tipoestudio = 0
            this.listas.personaBase.tipoestudioactual = 0 
            this.listas.personaBase.ultimogrado = 0
            this.listas.personaBase.razonnoestudio = 0 
            this.listas.personaBase.salud = 0
            this.listas.personaBase.razonnosalud = 0 
            this.listas.personaBase.sisben = "" 
            this.listas.personaBase.leeescribe = "" 
            this.listas.personaBase.huella = ""
            this.listas.personaBase.fallecido = false
            this.listas.personaBase.municipio_cedula = "" 
            this.listas.personaBase.lgtbi = false 
            this.listas.personaBase.celular = "" 
            this.listas.personaBase.email = "" 
            this.listas.personaBase.nota = ""
            this.listas.personaBase.fechaNacimiento = ""
        },
        nuevoIntegrante: function () {
            this.esActualizando = false
            this.tipo = 0
            this.soloJefe = false
            this.verFormPersona = true
            this.limpiarPersona()
            this.persona = this.listas.personaBase
            this.persona.tipo = this.tipo
            this.cabeza = 0
            this.grupoFamiliar.map(element => {
                console.log(element)
                if(element.parentesco == "Jefe de Hogar"){
                    this.persona.idcabezafamilia = element.idpersona
                }
            })
        }
    },
    async mounted () {
        this.token = localStorage.getItem('token');
        this.url = store.getters['auth/urlApi'];
        this.userPerfil = localStorage.getItem('userPerfil');
        
        this.listas.tipoIdentificacion = 
            await getListaTipo(this.url, 'getTiposIdentificacion', this.token)
        this.listas.parentescos = 
            await getListaTipo(this.url, 'getParentescos', this.token)
        this.listas.estadosRuv = 
            await getListaTipo(this.url, 'getEstadosValoracion', this.token)
        this.listas.etnias = 
            await getListaTipo(this.url, 'getMinorias', this.token)
        this.listas.discapacidades = 
            await getListaTipo(this.url, 'getDiscapacidades', this.token)
        this.listas.causasDiscapacidad = 
            await getListaTipo(this.url, 'getCausasDiscapacidad', this.token)
        this.listas.actividadesPersona = 
            await getListaTipo(this.url, 'getActividadesPersona', this.token)
        this.listas.tiposEstudio = 
            await getListaTipo(this.url, 'getTipoEstudio', this.token)
        this.listas.razonesNoEstudio = 
            await getListaTipo(this.url, 'getRazonesNoEstudio', this.token)
        this.listas.tiposSalud = 
            await getListaTipo(this.url, 'getTipoSalud', this.token)
        this.listas.razonesNoSalud = 
            await getListaTipo(this.url, 'tiposSalud', this.token)
        
    }
}
</script>