<template>
    <div>
        <b-row>
            <b-col>
                <p>Se generara un archivo para enviar a notificaciones y se guardara la fecha de envio a hoy</p>
                <button @click="generarArchivo()">Generar</button>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <p v-if="pendientesEnvioNotificar.length == 0">Noy hay datos para mostrar</p>                    
                <b-table
                    :columns="pendientesEnvioNotificarFields"
                    :rows="pendientesEnvioNotificar">                    
                </b-table>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import TableLite from "vue3-table-lite"
import moment from 'moment-timezone'
import store from '@/store/index'
export default {
    name: 'ListaEnviadosNotificar',
    components: {
        'b-table': TableLite
    },
    data() {
        return {
            pendientesEnvioNotificarFields: [
                { field: 'id', label: 'id'},
                { field: 'FECHADECLARACION', label: 'FECHADECLARACION'},
                { field: 'LUGARDECLARACION', label: 'LUGARDECLARACION'},
                { field: 'FECHAINGRESO', label: 'FECHAINGRESO'},
                { field: 'PRIMERNOMBRE', label: 'PRIMERNOMBRE'},
                { field: 'SEGUNDONOMBRE', label: 'SEGUNDONOMBRE'},
                { field: 'PRIMERAPELLIDO', label: 'PRIMERAPELLIDO'},
                { field: 'SEGUNDOAPELLIDO', label: 'SEGUNDOAPELLIDO'},
                { field: 'DOCUMENTO', label: 'DOCUMENTO'},
                { field: 'TELEFONO', label: 'TELEFONO'},
                { field: 'dias_transcurridos', label: 'dias_transcurridos'},
                { field: 'FUD_FICHA', label: 'FUD_FICHA'},
                { field: 'ESTADOVICTIMA', label: 'ESTADOVICTIMA'}
            ],
            pendientesEnvioNotificar: [],
            token: '',
        }
    },
    methods: {
        generarArchivo: function () {
            let url = store.getters['auth/urlApi'];
            this.pendientesEnvioNotificar.forEach( element => {                
                fetch( url + "setfechaenviadovaloracion/" + element.id, {
                    method: 'POST',
                    headers: new Headers({
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.token}`
                    }),
                    body: JSON.stringify({
                        ENVIADOVALORACION: moment().format("YYYY-MM-DD")
                    })
                })
            })
            
            this.csvExport(this.pendientesEnvioNotificar); 
        },
        csvExport(arrData) {
            let csvContent = "data:text/csv;charset=utf-8,";
            csvContent += [
                Object.keys(arrData[0]).join(";"),
                ...arrData.map(item => Object.values(item).join(";"))
            ]
                .join("\n")
                .replace(/(^\[)|(\]$)/gm, "");

            const data = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", data);
            link.setAttribute("download", "export.csv");
            link.click();
        }    
    },
    mounted () {
        this.token = localStorage.getItem('token');
        let url = store.getters['auth/urlApi']; 
        fetch( url + "pendientesenviarvaloracion", {
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${this.token}`
            })
        })
        .then( resp => resp.json())
        .then( resp => {
            this.pendientesEnvioNotificar = resp.data
        })
    }

}
</script>