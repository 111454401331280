import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "content-wrapper" }

export function render(_ctx, _cache) {
  const _component_app_header = _resolveComponent("app-header")
  const _component_menu_sidebar = _resolveComponent("menu-sidebar")
  const _component_router_view = _resolveComponent("router-view")
  const _component_app_footer = _resolveComponent("app-footer")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_app_header, { onToggleMenuSidebar: _ctx.toggleMenuSidebar }, null, 8, ["onToggleMenuSidebar"]),
    _createVNode(_component_menu_sidebar),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view)
    ]),
    _createVNode(_component_app_footer),
    (_ctx.screenSize === 'xs' && _ctx.isSidebarMenuCollapsed)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          id: "sidebar-overlay",
          onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.toggleMenuSidebar && _ctx.toggleMenuSidebar(...args)))
        }))
      : _createCommentVNode("", true)
  ]))
}