import {IUser} from '@/interfaces/user';
import {Options, Vue} from 'vue-class-component';
import MenuItem from '@/components/menu-item/menu-item.vue';

@Options({
    name: 'app-menu-sidebar',
    components: {
        'app-menu-item': MenuItem
    }
})
export default class MenuSidebar extends Vue {
    public menu = MENU;
    public albergues = ALBERGUES;
    public bodega = BODEGA;
    public perfil = localStorage.getItem("userPerfil");
    get user(): string {
        return this.$store.getters['auth/user'];
    }
}

export const BODEGA = [
    {
        name: 'labels.bodega',
        children: [
            {
                name: 'labels.informeBodega',
                path: '/informeBodega'
            }
        ]
    }
];

export const ALBERGUES = [
    {
        name: 'labels.albergues',
        children: [
            {
                name: 'labels.ingresos',
                path: '/albergues'
            },
            {
                name: 'labels.informeAlbergue',
                path: '/informeAlbergue'
            },
            {
                name: 'labels.controlAlbergue',
                path: '/ControlAlbergues'
            }
        ]
    }
];

export const MENU = [
    {
        name: 'labels.diagnostico',
        children: [
            {
                name: 'labels.caracterizacion',
                path: '/caracterizacion'
            },
            {
                name: 'labels.revisiondx',
                path: '/revisiondx'
            },
            {
                name: 'labels.realizarDx',
                path: '/sub-menu-1'
            },
            {
                name: 'labels.informe',
                path: '/sub-menu-2'
            }
        ]
    },
    {
        name: 'labels.citas',
        children: [
            {
                name: 'labels.realizarDx',
                path: '/sub-menu-1'
            },

            {
                name: 'labels.informe',
                path: '/sub-menu-2'
            }
        ]
    },
    {
        name: 'labels.bodega',
        children: [
            {
                name: 'labels.informeBodega',
                path: '/informeBodega'
            }
        ]
    },
    {
        name: 'labels.retornos',
        children: [
            {
                name: 'labels.retornos',
                path: '/retornos'
            }
        ]
    }    
];
