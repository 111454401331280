<template>
    <div>     
        <p v-if="!perfilPermitido">Usted no puede acceder a esta area.</p>
        <router-link v-if="bAlberguefull" to="/ControlAlbergues">Albergue control</router-link>
        <div v-if="arr.showListaIngresos && perfilPermitido" class="card card-secondary">
            <div class="card-header">
                <h3 class="card-title">Permanencia albergues</h3>
            </div>
            <div class="card-body">
                <b-row class="mb-5">
                    <b-col md="5">
                        <b-button-group>
                            <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Busqueda"></b-form-input>                        
                        </b-button-group>
                        <b-button v-if="perfil=='18' || perfil == '2'" @click="nuevoIngreso">NUEVA FAMILIA</b-button>
                    </b-col>
                    <b-col>
                        <b-table 
                            
                            :columns="fieldsDatoDia"
                            :rows="datoDia">
                            <template v-slot:dx_fecha="data">
                                {{data.value.FAMILIAS}}
                            </template>
                        </b-table>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <div style="text-align: right; width: 30%;">
                            <div class="input-group input-group-sm">
                                <span class="input-group-append">
                                   Buscar por: </span>
                                <input class="form-control" v-model="searchTerm" />    
                            </div>
                        </div>
                        <div class="table-responsive">
                            <b-table 
                                :is-slot-mode="true"
                                responsive
                                striped 
                                hover 
                                outlined 
                                :filter="filter"
                                :rows="ingresos"
                                :columns="fields"
                                @do-search="searchDeclaracion">
                                <template v-slot:id="data">
                                    {{data.value.id}}
                                </template>
                                <template v-slot:FUD="data">
                                    {{data.value.FUD}}
                                </template>
                                <template v-slot:DOCUMENTO="data">
                                    {{data.value.DOCUMENTO}}
                                </template>
                                <template v-slot:PRIMERNOMBRE="data">
                                    {{data.value.PRIMERNOMBRE}}
                                </template>
                                <template v-slot:SEGUNDONOMBRE="data">
                                    {{data.value.SEGUNDONOMBRE}}
                                </template>
                                <template v-slot:PRIMERAPELLIDO="data">
                                    {{data.value.PRIMERAPELLIDO}}
                                </template>
                                <template v-slot:SEGUNDOAPELLIDO="data">
                                    {{data.value.SEGUNDOAPELLIDO}}
                                </template>
                                <template v-slot:NPARENTESCO="data">
                                    {{data.value.NPARENTESCO}}
                                </template>
                                <template v-slot:FECHAINGRESO="data">
                                    {{data.value.FECHAINGRESO}}
                                </template>
                                <template v-slot:actions="data">
                                    <b-button-group>
                                        <b-button 
                                            v-if="perfil == '18' || perfil == '2'"
                                            size="sm" 
                                            @click="cargarForm(data.value, data.value.Id)" 
                                            class="mr-1">
                                        Actualizar
                                        </b-button>
                                        <b-button 
                                            size="sm" 
                                            v-if="data.value.PARENTESCO == 1 && (perfil == '10' || perfil == '2')" 
                                            @click="cargarAsistencia(data.value, data.value.Id)"
                                            variant="success">AHI</b-button>
                                    </b-button-group>
                                </template>
                            </b-table>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </div>
        <div v-if="arr.showFormDeclara && perfilPermitido" class="card card-secondary">
            <div class="card-header">
                <h3 class="card-title">DECLARACIÓN</h3>
                <button type="button" @click="cancelarFamilia" class="close" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="card-body">
                <b-form @submit.prevent="saveFamilia">                
                    <b-row align-h="end">
                        <b-button class="btn-close" aria-label="Salir" @click="cancelarFamilia"></b-button>
                    </b-row>                
                    <b-row>
                        <h4>Datos registro: {{objs.declara.id}}</h4> 

                    </b-row>
                    <b-row>
                        <b-col sm="3">                        
                            <b-input-group>
                                <b-form-input v-model="objs.declara.FUD_FICHA" name="FUD_FICHA" placeholder="FUD" required></b-form-input>
                                <b-input-group-append>
                                    <b-button @click="buscaDeclaracion(objs.declara.FUD_FICHA)">
                                        <b-icon icon="search" aria-hidden="true"></b-icon>
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>                       
                        </b-col>
                        <b-col sm="2">
                            <b-form-select 
                                class="form-control" 
                                v-model="objs.declara.TIPODOCUMENTO" 
                                :selected="objs.declara.TIPODOCUMENTO" 
                                :options="listas.tipoDocumentos"
                                required></b-form-select>
                        </b-col>
                        <b-col sm="3">
                            <b-form-input v-model="objs.declara.DOCUMENTO" placeholder="Documento" required></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>    
                        <b-col sm="6">                             
                            <div class="input-group mb-3">
                                <div class="input-group-append">
                                    <span class="input-group-text" id="">Cargar acta ingreso</span>
                                </div>
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input" accept=".pdf"
                                        :v-model="scannedCedula" @change="prevFilesin">
                                    <label class="custom-file-label" for="inputGroupFile02">{{inFiles}}</label>
                                </div>
                            </div>                                            
                        </b-col>
                        <b-col sm="6">
                            <div class="input-group mb-3">
                                <div class="input-group-append">
                                    <span class="input-group-text" id="">Cargar acta salida</span>
                                </div>
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input" accept=".pdf"
                                        :v-model="actaSalida" @change="prevFiles">
                                    <label class="custom-file-label" for="inputGroupFile02" >{{salFiles}}</label>
                                </div>
                            </div>                      
                        </b-col>
                        <b-col v-if="objs.declara.scannedCedula != 0">
                            Acta ingreso - {{objs.declara.DOCUMENTO}}
                            <b-button @click="verImagen(objs.declara)">Descarga</b-button>
                        </b-col>
                        <b-col v-if="objs.declara.actaSalida != 0">
                            Acta salida - {{objs.declara.DOCUMENTO}}
                            <b-button @click="verImgSalida(objs.declara)">Descargar</b-button>
                        </b-col>
                    </b-row>
                    <b-row><b-col>Nombres y apellidos</b-col></b-row>
                    <b-row>
                        <b-col sm="3">
                            <b-form-input v-model="objs.declara.PRIMERNOMBRE" placeholder="Primer nombre" required></b-form-input>
                        </b-col>                        
                        <b-col sm="3">
                            <b-form-input v-model="objs.declara.SEGUNDONOMBRE" placeholder="Segundo nombre"></b-form-input>
                        </b-col>
                        <b-col sm="3">
                            <b-form-input v-model="objs.declara.PRIMERAPELLIDO" placeholder="Primer apellido" required></b-form-input>
                        </b-col>
                        <b-col sm="3">
                            <b-form-input v-model="objs.declara.SEGUNDOAPELLIDO" placeholder="Segundo apellido"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row align-h="center">
                        <b-col hidden sm="2">
                            <label for="NUCLEOVIVANTO">Nucleo VIVANTO</label>
                            <b-form-input type="number" v-model="objs.declara.NUCLEOVIVANTO" name="NUCLEOVIVANTO" placeholder=""></b-form-input>
                        </b-col>
                        <b-col sm="2">
                            <label for="NUCLEOINGRESO">Nucleo ingreso</label>
                            <b-form-input type="number" v-model="objs.declara.NUCLEOINGRESO" name="NUCLEOINGRESO" placeholder="" required></b-form-input>
                        </b-col>
                        <b-col>
                            <label for="LUGARDECLARACION">Lugar declaración</label>
                            <b-form-select v-model="objs.declara.LUGARDECLARACION" name="LUGARDECLARACION"
                                    class="form-control" 
                                    :options="listas.lugarDeclaracion"
                                    required></b-form-select>
                        </b-col>
                        <b-col sm="3">
                            <label for="ESTADOVICTIMA">Estado VIVANTO</label>
                            <b-form-select v-model="objs.declara.ESTADOVICTIMA" class="form-control" :selected="objs.declara.ESTADOVICTIMA" :options="listas.estadosVictima" required></b-form-select>
                        </b-col>  
                        <b-col>
                            <label for="FECHAESTADO">Fecha declaracion</label>
                            <b-form-datepicker
                            v-model="fechaEstado"
                            right
                            locale="es-ES"
                            aria-controls="fechaest"
                            @context="onContext"
                            ></b-form-datepicker>
                        </b-col>                  
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-input-group prepend="Barrio solo Medellín">
                                <b-form-select v-model="objs.declara.ENTORNO" name="ENTORNO"
                                    class="form-control"
                                    @change="asignarComuna($event)"
                                    required>
                                    <b-form-select-option 
                                        v-for="barrio in lugares.barrio" 
                                        :key="barrio.id" 
                                        :value="barrio.id">{{ barrio.barrio }} - {{ barrio.comuna}}</b-form-select-option>    </b-form-select>
                            </b-input-group>
                        </b-col>
                        <b-col>
                            <b>Comuna: </b> {{ objs.declara.COMUNA }}
                        </b-col>
                    </b-row>
                    <b-row hidden><br></b-row>
                    <b-row hidden><b-col>Desplazamiento</b-col><b-col> <b-form-select v-model="objs.declara.DESPLAZAMIENTO" class="form-control" :selected="objs.declara.DESPLAZAMIENTO" :options="listas.estadosVictima" ></b-form-select></b-col></b-row>
                    <b-row hidden><b-col>Amenaza</b-col><b-col> <b-form-select v-model="objs.declara.AMENAZA" class="form-control" :selected="objs.declara.AMENAZA" :options="listas.estadosVictima" ></b-form-select></b-col></b-row>
                    <b-row hidden><b-col>Homicidio</b-col><b-col> <b-form-select v-model="objs.declara.HOMICIDIO" class="form-control" :selected="objs.declara.HOMICIDIO" :options="listas.estadosVictima" ></b-form-select></b-col></b-row>
                    <b-row hidden><b-col>Reclutamiento forzado</b-col><b-col> <b-form-select v-model="objs.declara.RECLUTAMIENTOFORZADO" class="form-control" :selected="objs.declara.RECLUTAMIENTOFORZADO" :options="listas.estadosVictima" ></b-form-select></b-col></b-row>
                    <b-row hidden><b-col>Despojo forzado de tierras</b-col><b-col> <b-form-select v-model="objs.declara.DESPOJOFORZADODETIERRAS" class="form-control" :selected="objs.declara.DESPOJOFORZADODETIERRAS" :options="listas.estadosVictima" ></b-form-select></b-col></b-row>
                    <b-row hidden><b-col>Acto terrorista</b-col><b-col> <b-form-select v-model="objs.declara.ACTOSTERRORISTA" class="form-control" :selected="objs.declara.ACTOSTERRORISTA" :options="listas.estadosVictima" ></b-form-select></b-col></b-row>
                    <b-row hidden><b-col>Delitos contra la libertad sexual</b-col><b-col> <b-form-select v-model="objs.declara.DELITOSCONTRALIBERTADSEXUAL" class="form-control" :selected="objs.declara.DELITOSCONTRALIBERTADSEXUAL" :options="listas.estadosVictima" ></b-form-select></b-col></b-row>
                    <b-row hidden><b-col>Secuestro</b-col><b-col> <b-form-select v-model="objs.declara.SECUESTRO" class="form-control" :selected="objs.declara.SECUESTRO" :options="listas.estadosVictima" ></b-form-select></b-col></b-row>
                    <b-row hidden><b-col>Desaparicion forzada</b-col><b-col> <b-form-select v-model="objs.declara.DESPARICIONFORZADA" class="form-control" :selected="objs.declara.DESPARICIONFORZADA" :options="listas.estadosVictima" ></b-form-select></b-col></b-row>
                    <b-row hidden><b-col>Lesiones personales</b-col><b-col> <b-form-select v-model="objs.declara.LESIONESPERSONALES" class="form-control" :selected="objs.declara.LESIONESPERSONALES" :options="listas.estadosVictima" ></b-form-select></b-col></b-row>
                    <b-row hidden><b-col>Minas antipersona</b-col><b-col> <b-form-select v-model="objs.declara.MINASANTIPERSONA" class="form-control" :selected="objs.declara.MINASANTIPERSONA" :options="listas.estadosVictima" ></b-form-select></b-col></b-row>
                    <b-row hidden><b-col>Tortura</b-col><b-col> <b-form-select v-model="objs.declara.TORTURA" class="form-control" :selected="objs.declara.TORTURA" :options="listas.estadosVictima" ></b-form-select></b-col></b-row>
                    <b-row hidden><b-col>Masacre</b-col><b-col> <b-form-select v-model="objs.declara.MASACRE" class="form-control" :selected="objs.declara.MASACRE" :options="listas.estadosVictima" ></b-form-select></b-col></b-row>
                    <b-row hidden><b-col>Hostigamiento</b-col><b-col> <b-form-select v-model="objs.declara.HOSTIGAMIENTO" class="form-control" :selected="objs.declara.HOSTIGAMIENTO" :options="listas.estadosVictima" ></b-form-select></b-col></b-row>
                    <b-row hidden><b-col>Violencia de genero</b-col><b-col> <b-form-select v-model="objs.declara.VIOLENCIADEGENERO" class="form-control" :selected="objs.declara.VIOLENCIADEGENERO" :options="listas.estadosVictima" ></b-form-select></b-col></b-row>
                    <b-row><b-col>Alta vulnerabilidad y/o remision otras instituciones</b-col><b-col> <b-form-select v-model="objs.declara.OTRAALTAVULNERABILIDADREMISIONES" class="form-control" :selected="objs.declara.OTRAALTAVULNERABILIDADREMISIONES" :options="listas.estadosVictima"></b-form-select></b-col></b-row>
                    <br/>
                    <b-row>
                        <b-col><b-button v-if="!arr.showNuevaPersona && !creandoPersona" type="submit">Registrar</b-button>
                                <b-button v-if="!arr.showNuevaPersona && creandoPersona" disabled>Registrar</b-button></b-col>
                        <b-col><b-button v-if="arr.showNuevaPersona" @click="updateFamilia">Actualizar</b-button></b-col>
                        <b-col><b-button v-if="arr.showNuevaPersona" @click="limpiarForm(); mdIngreso=true; objs.ingreso.DECLARAID = objs.declara.id; objs.ingreso.FUD = objs.declara.FUD_FICHA">Nueva persona</b-button></b-col>                    
                        <b-col></b-col>
                    </b-row>
                </b-form>
            </div>
        </div>
        <div v-if="mdIngreso" class="card card-secondary">
            <div class="card-header">
                <h3 class="card-title">INGRESO</h3>
                <button type="button" @click="mdIngreso = false" class="close" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="card-body">
                <b-row>
                    <h3>Ingreso: {{objs.ingreso.id}}</h3>
                </b-row>
                <b-row>
                    <b-col sm="2">
                        Id Declaracion: {{ objs.ingreso.DECLARAID }} - FUD: {{ objs.ingreso.FUD }}
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm = "3">
                        Tipo documento: <b-form-select class="form-control" :options='listas.tipoDocumentos' v-model="objs.ingreso.TIPODOCUMENTO"></b-form-select>
                    </b-col>
                    <b-col sm="3">
                        Documento: <b-form-input v-model="objs.ingreso.DOCUMENTO"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="3">
                        Primer nombre: <b-form-input v-model="objs.ingreso.PRIMERNOMBRE"></b-form-input>
                    </b-col>
                    <b-col sm="3">
                        Segundo nombre: <b-form-input v-model="objs.ingreso.SEGUNDONOMBRE"></b-form-input>
                    </b-col>
                    <b-col sm="3">
                        Primer apellido: <b-form-input v-model="objs.ingreso.PRIMERAPELLIDO"></b-form-input>
                    </b-col>
                    <b-col sm="3">
                        Segundo apellido: <b-form-input v-model="objs.ingreso.SEGUNDOAPELLIDO"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="3">
                        Parentesco: <b-form-select class="form-control" v-model="objs.ingreso.PARENTESCO" :options="listas.parentescos"></b-form-select>
                    </b-col>
                    <b-col sm="3">
                        Sede: <b-form-select class="form-control" v-model="objs.ingreso.SEDE" :options="listas.sedeAlbergues"></b-form-select>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="3">
                        Fecha ingreso: <b-form-datepicker
                            v-model="objs.ingreso.FECHAINGRESO"                        
                            locale="es-ES"
                            format="yyyy-MM-dd HH:mm:ss"
                            ></b-form-datepicker>                    
                    </b-col>
                    <b-col sm="3">Permanencia
                        <b-form-checkbox
                            class="form-control"
                            id="checkbox-personaActiva"
                            :checked="personaActiva"
                            v-model="personaActiva"
                            name="checkbox-personaActiva"                        
                            @change="activePerson">
                            La persona esta en el albergue
                        </b-form-checkbox>
                        
                    </b-col>
                    <b-col sm="3" v-if="!personaActiva">
                        
                        Fecha salida: <b-form-datepicker 
                            v-model="objs.ingreso.FECHASALIDA"                        
                            locale="es-ES"
                            format="yyyy-MM-dd HH:mm:ss"
                            ></b-form-datepicker>
                    </b-col>
                </b-row>
                <br/>
                <b-row>
                    <b-col v-if="objs.ingreso.id == ''">
                        <b-button @click="saveIngreso">Registrar</b-button>                    
                    </b-col>
                    <b-col  v-if="objs.ingreso.id != ''">
                        <b-button @click="updateIngreso">actualizar</b-button>
                    </b-col>
                    <b-col>
                        <b-button @click="limpiarForm">Nuevo</b-button>
                    </b-col>                
                </b-row>
            </div>
        </div>

        <div v-if="mdAsistencia" class="card card-secondary">
            <div class="card-header">
                <h3 class="card-title">Programación AHI</h3>
                <button type="button" @click="mdAsistencia = false; arr.showListaIngresos = true;arr.showFormDeclara = false" class="close" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="card-body">
                <b-button @click="showTablaAsistencias = showTablaAsistencias?false:true">Ver tabla asistencias</b-button>
                <b-table v-if="showTablaAsistencias"                    
                    :columns="fieldsAsistencia"
                    :rows="asistenciasPersona">
                </b-table>
                <b-form @submit.prevent="saveAsistencia">
                    <h1>ASISTENCIAS</h1>
                    <b-row>
                        <b-col>
                            <b>id: </b> {{ asistencia.id_asistencia }}
                        </b-col>
                        <b-col>
                            <b>Tipo documento: </b> {{ asistencia.tipoDNI }}
                        </b-col>
                        <b-col>
                            <b>Documento: </b> {{ asistencia.numero }}
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b>Nombres: </b> {{ asistencia.nombres }}
                        </b-col>
                        <b-col>
                            <b>Apellidos: </b> {{ asistencia.apellidos}}
                        </b-col>
                        <b-col>
                            <b>Nucleo: </b> {{ asistencia.nucleo}}
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b>Concepto: </b> {{ asistencia.concepto}}
                        </b-col>                        
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-input-group prepend="dinero">
                                <b-form-input v-model="asistencia.dinero" type="number" required></b-form-input>
                            </b-input-group>
                        </b-col>
                        <b-col>
                            <b-input-group prepend="alimentos">
                                <b-form-input v-model="asistencia.alimentos" type="number" required></b-form-input>
                            </b-input-group>
                        </b-col>
                        <b-col>
                            <b-input-group prepend="kits">
                                <b-form-input v-model="asistencia.kits" type="number" required></b-form-input>
                            </b-input-group>
                        </b-col>
                        <b-col>
                            <b-input-group prepend="otros">
                                <b-form-input v-model="asistencia.otros" type="number" required></b-form-input>
                            </b-input-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b>Fecha atención: </b> {{ asistencia.fechaAtencion }}
                        </b-col>
                        <b-col>
                            <b-input-group prepend="Fecha Entrega">
                                <b-form-datepicker 
                                    locale='es' 
                                    id="fechaEntrega" 
                                    v-model="asistencia.fechaEntrega">
                                </b-form-datepicker>
                            </b-input-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b>VIVANTO: </b> {{ asistencia.estadoVivanto}} - {{ asistencia.fechaEstadoVivanto }}
                        </b-col>
                        <b-col>
                            <b-input-group prepend="Municipio">
                                <b-form-input v-model="asistencia.municipioExpulsor" required></b-form-input>
                            </b-input-group>
                        </b-col>
                        <b-col v-if="false">
                            <b-input-group prepend="otros Hechos">
                                <b-form-input v-model="asistencia.otrosHechos" required></b-form-input>
                            </b-input-group>
                        </b-col>
                        <b-col>
                            <b-input-group prepend="Contacto">
                                <b-form-input v-model="asistencia.contacto" required></b-form-input>
                            </b-input-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-textarea
                                required
                                id="observaciones"
                                v-model="asistencia.observaciones"
                                placeholder="Observaciones"
                                rows="3"
                                max-rows="6"
                                ></b-form-textarea>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b>Remite: </b> {{ asistencia.remitente}}
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-button type="submit">Guardar</b-button>
                        </b-col>
                    </b-row>  
                </b-form>
            </div>
        </div>

    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment-timezone'
import listas from '@/pages/albergue/listas.js'
import objs from '@/pages/albergue/objetos.js'
import download from 'downloadjs'
import store from '@/store/index'
import Datepicker from 'vue3-date-time-picker'
import lugares from '@/pages/diagnostico/lugares.js'
import TableLite from "vue3-table-lite"
export default {
    name: 'Albergues',
    components: {
        'b-table': TableLite,
        'b-form-datepicker': Datepicker
    },
    data () {
        return {
            token: '',
            perfil: localStorage.getItem("userPerfil"),
            perfilPermitido: false,
            bAlberguefull: false,
            scannedCedula: null,
            actaSalida: null,
            arr: {
                showListaIngresos: true,
                showFormDeclara: false,
                showNuevaPersona: false
            },
            listas: listas,           
            objs: objs,
            filter: "",
            totalRows: 1,
            currentPage: 1,
            perPage: 50,
            ingresos: [],
            newIngresos: [],
            fieldsDatoDia: [
                { field: 'FAMILIAS', label: 'Familias' },
                { field: 'PERSONAS', label: 'Personas' }
            ],
            fields: [
                { field: 'id', label: 'id' },
                { field: 'FUD', label: 'FUD' },
                { field: 'DOCUMENTO', label: 'Doc' },
                { field: 'PRIMERNOMBRE', label: 'Nombres' },
                { field: 'SEGUNDONOMBRE', label: '' },
                { field: 'PRIMERAPELLIDO', label: 'Apellidos' },
                { field: 'SEGUNDOAPELLIDO', label: '' },
                { field: 'NPARENTESCO', label: 'Parentesco' },
                { field: 'FECHAINGRESO', label: 'Ingreso' },
                { field: 'actions', label: 'Acciones' } // this is a virtual column, that does not exist in our `items`
            ],
            fieldsAsistencia: [
                { field: 'id_asistencia', label: 'id_asistencia'},
                { field: 'tipoDNI', label: 'tipoDNI'},
                { field: 'numero', label: 'numero'},
                { field: 'numero', label: 'numero'},
                { field: 'apellidos', label: 'apellidos'},
                { field: 'nucleo', label: 'nucleo'},
                { field: 'concepto', label: 'concepto'},
                { field: 'dinero', label: 'dinero'},
                { field: 'alimentos', label: 'alimentos'},
                { field: 'kits', label: 'kits'},
                { field: 'otros', label: 'otros'},
                { field: 'municipioExpulsor', label: 'municipioExpulsor'},
                { field: 'remitente', label: 'remitente'},
                { field: 'fechaAtencion', label: 'fechaAtencion'},
                { field: 'fechaEntrega', label: 'fechaEntrega'},
                { field: 'estadoVivanto', label: 'estadoVivanto'},
                { field: 'fechaEstadoVivanto', label: 'fechaEstadoVivanto'}
            ],
            fechaEstado: new Date(),
            personaActiva: false,
            asistencia: objs.asistencia,
            asistenciasPersona: [],
            showTablaAsistencias: false,
            datoDia: [],
            creandoPersona: false,
            mdIngreso : false,
            mdAsistencia : false,
            salFiles: '',
            inFiles: '',
            lugares: lugares,
            searchTerm: ''
        }
    },
    watch: {
        searchTerm: function (value) {
            this.ingresos = this.newIngresos.filter(
                (x) =>
                x.DOCUMENTO.toLowerCase().includes(value.toLowerCase()) ||
                x.FUD.toLowerCase().includes(value.toLowerCase()) ||
                x.NPARENTESCO.toLowerCase().includes(value.toLowerCase())
            )
        }
    },
    methods: {
        activePerson: function () {
            
            if(!this.personaActiva){      
                objs.ingreso.FECHASALIDA = moment().format("YYYY-MM-DD HH:mm:ss")        
                this.personaActiva = false
            }else if(this.personaActiva){
                objs.ingreso.FECHASALIDA = '1901-01-01 00:00:00'
                this.personaActiva = true
            }
            
        },
        buscaDeclaracion: function (fud) {
            this.token = localStorage.getItem('token');
            let url = store.getters['auth/urlApi'];            
            const params = new URLSearchParams()
            params.append('FUD_FICHA', fud)
            axios.post( url + 'fud', 
                params,
             {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${this.token}`          
            }
            })
            .then((res) => {          
                this.objs.declara.id = res.data.data.id                
                this.objs.declara.TIPODOCUMENTO = res.data.data.TIPODOCUMENTO
                this.objs.declara.DOCUMENTO = res.data.data.DOCUMENTO
                this.objs.declara.PRIMERNOMBRE = res.data.data.PRIMERNOMBRE
                this.objs.declara.SEGUNDONOMBRE = (res.data.data.SEGUNDONOMBRE=='null')?'':res.data.data.SEGUNDONOMBRE
                this.objs.declara.PRIMERAPELLIDO = res.data.data.PRIMERAPELLIDO
                this.objs.declara.SEGUNDOAPELLIDO = (res.data.data.SEGUNDOAPELLIDO=='null')?'':res.data.data.SEGUNDOAPELLIDO
                this.objs.declara.NUCLEOVIVANTO = res.data.data.NUCLEOVIVANTO
                this.objs.declara.NUCLEOINGRESO = res.data.data.NUCLEOINGRESO
                this.objs.declara.LUGARDECLARACION = res.data.data.LUGARDECLARACION
                this.objs.declara.MUNICIPIO = res.data.data.MUNICIPIO
                this.objs.declara.ENTORNO = res.data.data.ENTORNO
                this.objs.declara.COMUNA = res.data.data.COMUNA
                this.objs.declara.DESPLAZAMIENTO = res.data.data.DESPLAZAMIENTO
                this.objs.declara.AMENAZA = res.data.data.AMENAZA
                this.objs.declara.HOMICIDIO = res.data.data.HOMICIDIO
                this.objs.declara.RECLUTAMIENTOFORZADO = res.data.data.RECLUTAMIENTOFORZADO
                this.objs.declara.DESPOJOFORZADODETIERRAS = res.data.data.DESPOJOFORZADODETIERRAS
                this.objs.declara.ACTOSTERRORISTA = res.data.data.ACTOSTERRORISTA
                this.objs.declara.DELITOSCONTRALIBERTADSEXUAL = res.data.data.DELITOSCONTRALIBERTADSEXUAL
                this.objs.declara.SECUESTRO = res.data.data.SECUESTRO
                this.objs.declara.DESPARICIONFORZADA = res.data.data.DESPARICIONFORZADA
                this.objs.declara.LESIONESPERSONALES = res.data.data.LESIONESPERSONALES
                this.objs.declara.MINASANTIPERSONA = res.data.data.MINASANTIPERSONA
                this.objs.declara.TORTURA = res.data.data.TORTURA
                this.objs.declara.MASACRE = res.data.data.MASACRE
                this.objs.declara.HOSTIGAMIENTO = res.data.data.HOSTIGAMIENTO
                this.objs.declara.VIOLENCIADEGENERO = res.data.data.VIOLENCIADEGENERO
                this.objs.declara.OTRAALTAVULNERABILIDADREMISIONES = res.data.data.OTRAALTAVULNERABILIDADREMISIONES
                let estadovict = 0
                switch (res.data.data.ESTADOVICTIMA) {
                    case 'VALORACION - VALORADO':
                        estadovict = 'V'
                        break
                    case 'NO INCLUIDO':
                        estadovict = 'N'
                        break
                    case 'INCLUIDO':
                        estadovict = 'I'
                        break            
                }
                this.objs.declara.ESTADOVICTIMA = estadovict
                this.objs.declara.FECHAESTADO = res.data.data.FECHAESTADO
                this.fechaEstado = res.data.data.FECHAESTADO
                this.objs.declara.FUD_FICHA = res.data.data.FUD_FICHA
                this.arr.showNuevaPersona = true
            })
            .catch((error) => {
                if(error.response && error.response.status == 404){
                    alert(error.response.data.message)
                }    
                this.iraLogin(error)        
            })
        },
        iraLogin: function (error) {
            if(error.response && error.response.status == 401){
                this.$store.dispatch('logout')
                this.$router.push('/login') 
            }
        },
        cargarIngresos () {
            this.token = localStorage.getItem('token');
            let url = store.getters['auth/urlApi'];           
            axios.get( url + 'personasDia', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.token}`          
            }
            })
            .then((res) => {            
                this.ingresos = res.data.data
                this.newIngresos = res.data.data    
                let parentescos = this.listas.parentescos
                let albergues = this.listas.sedeAlbergues
                this.ingresos.forEach(function (ingreso) {  
                                      
                    parentescos.forEach(function (parentesco){
                        if(parentesco.value == ingreso.PARENTESCO){
                            ingreso.NPARENTESCO = parentesco.text
                        }
                    })
                    albergues.forEach(function (sede){
                        if(sede.value == ingreso.SEDE){
                            ingreso.NSEDE = sede.text
                        }
                    })
                })
                this.totalRows = this.ingresos.length            
            })
            .catch((error) => {
                this.iraLogin(error)       
            })
        },
        cargarForm: function (item) {
            this.objs.ingreso.id = item['id']
            this.objs.ingreso.DECLARAID = item['DECLARAID']
            this.objs.ingreso.FUD = item['FUD']
            this.objs.ingreso.TIPODOCUMENTO = item['TIPODOCUMENTO']
            this.objs.ingreso.DOCUMENTO = item['DOCUMENTO']
            this.objs.ingreso.PRIMERNOMBRE = item['PRIMERNOMBRE']
            this.objs.ingreso.SEGUNDONOMBRE = item['SEGUNDONOMBRE']
            this.objs.ingreso.PRIMERAPELLIDO = item['PRIMERAPELLIDO']
            this.objs.ingreso.SEGUNDOAPELLIDO = item['SEGUNDOAPELLIDO']
            this.objs.ingreso.PARENTESCO = item['PARENTESCO']
            this.objs.ingreso.SEDE = item['SEDE']
            this.objs.ingreso.FECHAINGRESO = item['FECHAINGRESO'] 
            this.objs.ingreso.FECHASALIDA = item['FECHASALIDA']
            if (objs.ingreso.FECHASALIDA == '1901-01-01 00:00:00'){
                this.personaActiva = true
            }else{
                this.personaActiva = false
            }
            this.buscarFamilia(this.objs.ingreso.DECLARAID) 
            this.arr.showListaIngresos = false
            this.arr.showFormDeclara = true
            this.mdIngreso = true;
                    
        },
        cargarAsistencia: function (item) {
            this.buscarFamilia(item['DECLARAID'])
            this.buscarAsistencias(item['DOCUMENTO'])
            this.asistencia.tipoDNI = item['TIPODOCUMENTO']
            this.asistencia.numero = item['DOCUMENTO']
            this.asistencia.nombres = item['PRIMERNOMBRE'] + " " + item['SEGUNDONOMBRE']
            this.asistencia.apellidos = item['PRIMERAPELLIDO'] + " " + item['SEGUNDOAPELLIDO']
            this.asistencia.dinero = '0'
            this.asistencia.alimentos = '0'
            this.asistencia.kits = '0'
            this.asistencia.otros = '0'
            this.asistencia.fechaAtencion = moment().format("YYYY-MM-DD")
            this.asistencia.fechaEntrega = moment().format("YYYY-MM-DD")
            
            this.asistencia.municipioExpulsor = this.objs.declara.MUNICIPIO
            this.asistencia.otrosHechos = 'Sin info'
            this.asistencia.contacto = ''
            this.asistencia.remitente = localStorage.getItem('userName')
            this.asistencia.observaciones = ''
            this.arr.showListaIngresos = false
            this.arr.showFormDeclara = true
            this.mdAsistencia = true           
        },
        buscarAsistencias: function (doc) {
            this.token = localStorage.getItem('token');
            let url = store.getters['auth/urlApi'];        
            const params = new FormData()            
            params.append('documento', doc)           
            axios.post( url + 'buscarasistencias', params, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.token}`          
            }
            })
            .then((res) => {  
                if (res.data.data.length != 0){
                    this.asistenciasPersona = res.data.data
                }else{
                    this.asistenciasPersona = [{"Asistencias": "No tiene"}]
                }
                
            })
            .catch((error) => {
                this.iraLogin(error)       
            })
        },
        saveAsistencia: function () {
            if(this.asistencia.nucleo == 0){
                alert("el nucleo familiar según VIVANTO esta vacío. para guardar debe de actualizar el nucleo.")
                return false;
            }
            if(this.asistencia.dinero == 0 && 
                this.asistencia.alimentos == 0 && 
                    this.asistencia.kits == 0 && 
                        this.asistencia.otros == 0){
                            alert("Debe ingresar al menos un item de dinero o alimentos o kits o otros.")
                            return false;  
                        }
            let url = store.getters['auth/urlApi'];
            this.token = localStorage.getItem('token');                   
            const params = new URLSearchParams()            
            params.append('tipoDNI', this.asistencia.tipoDNI)
            params.append('numero', this.asistencia.numero)
            params.append('nombres', this.asistencia.nombres)
            params.append('apellidos', this.asistencia.apellidos)
            params.append('nucleo', this.asistencia.nucleo)
            params.append('concepto', this.asistencia.concepto)
            params.append('dinero', this.asistencia.dinero)
            params.append('alimentos', this.asistencia.alimentos)
            params.append('kits', this.asistencia.kits)
            params.append('otros', this.asistencia.otros)
            params.append('fechaAtencion', this.asistencia.fechaAtencion)
            var fechaEntrega = moment(this.asistencia.fechaEntrega).format("YYYY-MM-DD")
            params.append('fechaEntrega', fechaEntrega)
            params.append('estadoVivanto', this.asistencia.estadoVivanto)
            params.append('fechaEstadoVivanto', this.asistencia.fechaEstadoVivanto)
            params.append('municipioExpulsor', this.asistencia.municipioExpulsor)
            params.append('otrosHechos', this.asistencia.otrosHechos)            
            params.append('contacto', this.asistencia.contacto)            
            params.append('remitente', this.asistencia.remitente)   
            params.append('observaciones', this.asistencia.observaciones)          
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${this.token}`
            }

            url = url + 'saveasistencia' 
            axios.post( url, params, { headers })
            .then(() => {                        
                alert ("Asistencia guardada")  
                this.buscarAsistencias(this.asistencia.numero)
            })
            .catch((error) => {
                console.log(error)
            })
        },
        buscarFamilia: function (idDeclara) {
            this.token = localStorage.getItem('token');
            let url = store.getters['auth/urlApi'];
            axios.get( url + 'declaralbergue/' + idDeclara, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.token}`          
            }
            })
            .then((res) => {   
                this.objs.declara.id = res.data.data.id                
                this.objs.declara.TIPODOCUMENTO = res.data.data.TIPODOCUMENTO
                this.objs.declara.DOCUMENTO = res.data.data.DOCUMENTO
                this.objs.declara.PRIMERNOMBRE = res.data.data.PRIMERNOMBRE
                this.objs.declara.SEGUNDONOMBRE = res.data.data.SEGUNDONOMBRE
                this.objs.declara.PRIMERAPELLIDO = res.data.data.PRIMERAPELLIDO
                this.objs.declara.SEGUNDOAPELLIDO = res.data.data.SEGUNDOAPELLIDO
                this.objs.declara.NUCLEOVIVANTO = res.data.data.NUCLEOVIVANTO
                this.objs.declara.NUCLEOINGRESO = res.data.data.NUCLEOINGRESO
                this.objs.declara.LUGARDECLARACION = res.data.data.LUGARDECLARACION
                this.objs.declara.MUNICIPIO = res.data.data.MUNICIPIO
                this.objs.declara.ENTORNO = res.data.data.ENTORNO
                this.objs.declara.COMUNA = res.data.data.COMUNA
                this.objs.declara.DESPLAZAMIENTO = res.data.data.DESPLAZAMIENTO
                this.objs.declara.AMENAZA = res.data.data.AMENAZA
                this.objs.declara.HOMICIDIO = res.data.data.HOMICIDIO
                this.objs.declara.RECLUTAMIENTOFORZADO = res.data.data.RECLUTAMIENTOFORZADO
                this.objs.declara.DESPOJOFORZADODETIERRAS = res.data.data.DESPOJOFORZADODETIERRAS
                this.objs.declara.ACTOSTERRORISTA = res.data.data.ACTOSTERRORISTA
                this.objs.declara.DELITOSCONTRALIBERTADSEXUAL = res.data.data.DELITOSCONTRALIBERTADSEXUAL
                this.objs.declara.SECUESTRO = res.data.data.SECUESTRO
                this.objs.declara.DESPARICIONFORZADA = res.data.data.DESPARICIONFORZADA
                this.objs.declara.LESIONESPERSONALES = res.data.data.LESIONESPERSONALES
                this.objs.declara.MINASANTIPERSONA = res.data.data.MINASANTIPERSONA
                this.objs.declara.TORTURA = res.data.data.TORTURA
                this.objs.declara.MASACRE = res.data.data.MASACRE
                this.objs.declara.HOSTIGAMIENTO = res.data.data.HOSTIGAMIENTO
                this.objs.declara.VIOLENCIADEGENERO = res.data.data.VIOLENCIADEGENERO
                this.objs.declara.OTRAALTAVULNERABILIDADREMISIONES = res.data.data.OTRAALTAVULNERABILIDADREMISIONES
                let estadovict = 0
                switch (res.data.data.ESTADOVICTIMA) {
                    case 'VALORACION - VALORADO':
                        estadovict = 'V'
                        break
                    case 'NO INCLUIDO':
                        estadovict = 'N'
                        break
                    case 'INCLUIDO':
                        estadovict = 'I'
                        break            
                }
                this.objs.declara.ESTADOVICTIMA = estadovict
                this.objs.declara.FECHAESTADO = res.data.data.FECHAESTADO
                this.fechaEstado = res.data.data.FECHAESTADO
                this.objs.declara.FUD_FICHA = res.data.data.FUD_FICHA
                this.objs.declara.scannedCedula = res.data.data.scannedCedula
                this.objs.declara.actaSalida = res.data.data.actaSalida
                this.arr.showNuevaPersona = true

                //Carga datos del formulario asistencias
                this.asistencia.nucleo = this.objs.declara.NUCLEOVIVANTO
                
                if(this.objs.declara.OTRAALTAVULNERABILIDADREMISIONES == 'I') {
                    this.asistencia.concepto = 'AYUDA HUMANITARIA EMERGENCIA ALTA VULNERABILIDAD'
                    this.asistencia.estadoVivanto = 'INCLUIDO'
                }else{
                    this.asistencia.concepto = 'ATENCION INMEDIATA SALIDA ALBERGUE RED SOCIAL'
                    switch (this.objs.declara.ESTADOVICTIMA) {
                        case 'I':
                            this.asistencia.estadoVivanto = 'INCLUIDO'
                            break;
                        case 'N':
                            this.asistencia.estadoVivanto = 'NO INCLUIDO'
                            break;
                        case 'V':
                            this.asistencia.estadoVivanto = 'VALORACION'
                            break;                    
                        default:
                            this.asistencia.estadoVivanto = 'VALORACION'
                            break;
                    }
                }
                this.lugares.municipio.forEach(element => {
                    if (element.id == this.objs.declara.MUNICIPIO){
                        this.asistencia.municipioExpulsor = element.municipio
                    }
                })
                this.asistencia.fechaEstadoVivanto = this.objs.declara.FECHAESTADO
            })
            .catch((error) => {
                this.iraLogin(error)          
            })
            return true
        },
        nuevoIngreso: function () {
            this.limpiarDeclaracion()
            this.arr.showListaIngresos = false
            this.arr.showFormDeclara = true
            this.arr.showNuevaPersona = false
        },
        onContext(ctx) {            
            this.objs.declara.FECHAESTADO = ctx.selectedYMD
        },
        saveFamilia: function () {
            this.creandoPersona = true;
            let url = store.getters['auth/urlApi'];
            this.token = localStorage.getItem('token');
            let estadovict = 0
            switch (this.objs.declara.ESTADOVICTIMA) {
                case 'V':
                    estadovict = 'VALORACION - VALORADO'
                    break
                case 'N':
                    estadovict = 'NO INCLUIDO'
                    break
                case 'I':
                    estadovict = 'INCLUIDO'
                    break
                default:
                    estadovict = 0
                    break;
            }            
            const params = new FormData()
            params.append('TIPODOCUMENTO', this.objs.declara.TIPODOCUMENTO)
            params.append('DOCUMENTO', this.objs.declara.DOCUMENTO)
            params.append('PRIMERNOMBRE', this.objs.declara.PRIMERNOMBRE)
            params.append('SEGUNDONOMBRE', this.objs.declara.SEGUNDONOMBRE)
            params.append('PRIMERAPELLIDO', this.objs.declara.PRIMERAPELLIDO)
            params.append('SEGUNDOAPELLIDO', this.objs.declara.SEGUNDOAPELLIDO)
            params.append('NUCLEOVIVANTO', this.objs.declara.NUCLEOVIVANTO)
            params.append('NUCLEOINGRESO', this.objs.declara.NUCLEOINGRESO)
            params.append('LUGARDECLARACION', this.objs.declara.LUGARDECLARACION)
            params.append('DEPARTAMENTO', '0')
            params.append('MUNICIPIO', '0')
            params.append('ENTORNO', this.objs.declara.ENTORNO)
            params.append('COMUNA', this.objs.declara.COMUNA)
            params.append('SUBREGION', '0')

            params.append('DESPLAZAMIENTO', this.objs.declara.DESPLAZAMIENTO)
            params.append('AMENAZA', this.objs.declara.AMENAZA)
            params.append('HOMICIDIO', this.objs.declara.HOMICIDIO)
            params.append('RECLUTAMIENTOFORZADO', this.objs.declara.RECLUTAMIENTOFORZADO)
            params.append('DESPOJOFORZADODETIERRAS', this.objs.declara.DESPOJOFORZADODETIERRAS)
            params.append('ACTOSTERRORISTA', this.objs.declara.ACTOSTERRORISTA)
            params.append('DELITOSCONTRALIBERTADSEXUAL', this.objs.declara.DELITOSCONTRALIBERTADSEXUAL)
            params.append('SECUESTRO', this.objs.declara.SECUESTRO)
            params.append('DESPARICIONFORZADA', this.objs.declara.DESPARICIONFORZADA)
            params.append('LESIONESPERSONALES', this.objs.declara.LESIONESPERSONALES)
            params.append('MINASANTIPERSONA', this.objs.declara.MINASANTIPERSONA)
            params.append('TORTURA', this.objs.declara.TORTURA)
            params.append('MASACRE', this.objs.declara.MASACRE)
            params.append('HOSTIGAMIENTO', this.objs.declara.HOSTIGAMIENTO)
            params.append('VIOLENCIADEGENERO', this.objs.declara.VIOLENCIADEGENERO)
            params.append('OTRAALTAVULNERABILIDADREMISIONES', this.objs.declara.OTRAALTAVULNERABILIDADREMISIONES)
            
            params.append('RESPONSABLE', '0')           
            
            params.append('ESTADOVICTIMA', estadovict)
            params.append('FECHAESTADO', this.objs.declara.FECHAESTADO)
            params.append('FUD_FICHA', this.objs.declara.FUD_FICHA)
            params.append('ENVIADOVALORACION', '1901-01-01')
            params.append('cedula', this.scannedCedula)
            params.append('actaSalida', this.actaSalida)
            axios.post( url + 'declaralbergue', 
                params,
            {
            headers: {
                'Accept': 'application/json',                
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${this.token}`
            }
            })
            .then((res) => {
                this.scannedCedula = null;
                this.actaSalida = null;
                alert ("Declaracion registrada")
                this.objs.declara.id = res.data.data.id
                this.arr.showNuevaPersona = true

                this.objs.ingreso.id = ''
                this.objs.ingreso.DECLARAID = this.objs.declara.id
                this.objs.ingreso.FUD = this.objs.declara.FUD_FICHA
                this.objs.ingreso.TIPODOCUMENTO = this.objs.declara.TIPODOCUMENTO
                this.objs.ingreso.DOCUMENTO = this.objs.declara.DOCUMENTO
                this.objs.ingreso.PRIMERNOMBRE = this.objs.declara.PRIMERNOMBRE
                this.objs.ingreso.SEGUNDONOMBRE = this.objs.declara.SEGUNDONOMBRE
                this.objs.ingreso.PRIMERAPELLIDO = this.objs.declara.PRIMERAPELLIDO
                this.objs.ingreso.SEGUNDOAPELLIDO = this.objs.declara.SEGUNDOAPELLIDO
                this.objs.ingreso.PARENTESCO = '1'
                this.objs.ingreso.SEDE = '1'
                this.objs.ingreso.FECHAINGRESO = moment().format("YYYY-MM-DD HH:mm:ss")
                this.objs.ingreso.FECHASALIDA = "1901-01-01 00:00:00"
                this.mdIngreso = true
                this.creandoPersona = false;
            })
            .catch((error) => {
                this.iraLogin(error)
            })
        },
        updateFamilia: function () {
            let url = store.getters['auth/urlApi'];
            this.token = localStorage.getItem('token');
            let estadovict = 0
            switch (this.objs.declara.ESTADOVICTIMA) {
                case 'V':
                    estadovict = 'VALORACION - VALORADO'
                    break
                case 'N':
                    estadovict = 'NO INCLUIDO'
                    break
                case 'I':
                    estadovict = 'INCLUIDO'
                    break
                default:
                    estadovict = 0
                    break;
            }            
            const params = new FormData()
            params.append('TIPODOCUMENTO', this.objs.declara.TIPODOCUMENTO)
            params.append('DOCUMENTO', this.objs.declara.DOCUMENTO)
            params.append('PRIMERNOMBRE', this.objs.declara.PRIMERNOMBRE)
            params.append('SEGUNDONOMBRE', this.objs.declara.SEGUNDONOMBRE)
            params.append('PRIMERAPELLIDO', this.objs.declara.PRIMERAPELLIDO)
            params.append('SEGUNDOAPELLIDO', this.objs.declara.SEGUNDOAPELLIDO)
            params.append('NUCLEOVIVANTO', this.objs.declara.NUCLEOVIVANTO)
            params.append('NUCLEOINGRESO', this.objs.declara.NUCLEOINGRESO)            
            params.append('LUGARDECLARACION', this.objs.declara.LUGARDECLARACION)            
            
            params.append('DEPARTAMENTO', '0')
            params.append('MUNICIPIO', '0')
            params.append('ENTORNO', '0')
            params.append('COMUNA', '0')
            params.append('SUBREGION', '0')
            
            params.append('DESPLAZAMIENTO', this.objs.declara.DESPLAZAMIENTO)
            params.append('AMENAZA', this.objs.declara.AMENAZA)
            params.append('HOMICIDIO', this.objs.declara.HOMICIDIO)
            params.append('RECLUTAMIENTOFORZADO', this.objs.declara.RECLUTAMIENTOFORZADO)
            params.append('DESPOJOFORZADODETIERRAS', this.objs.declara.DESPOJOFORZADODETIERRAS)
            params.append('ACTOSTERRORISTA', this.objs.declara.ACTOSTERRORISTA)
            params.append('DELITOSCONTRALIBERTADSEXUAL', this.objs.declara.DELITOSCONTRALIBERTADSEXUAL)
            params.append('SECUESTRO', this.objs.declara.SECUESTRO)
            params.append('DESPARICIONFORZADA', this.objs.declara.DESPARICIONFORZADA)
            params.append('LESIONESPERSONALES', this.objs.declara.LESIONESPERSONALES)
            params.append('MINASANTIPERSONA', this.objs.declara.MINASANTIPERSONA)
            params.append('TORTURA', this.objs.declara.TORTURA)
            params.append('MASACRE', this.objs.declara.MASACRE)
            params.append('HOSTIGAMIENTO', this.objs.declara.HOSTIGAMIENTO)
            params.append('VIOLENCIADEGENERO', this.objs.declara.VIOLENCIADEGENERO)
            params.append('OTRAALTAVULNERABILIDADREMISIONES', this.objs.declara.OTRAALTAVULNERABILIDADREMISIONES)
            
            params.append('RESPONSABLE', '0')

            params.append('ESTADOVICTIMA', estadovict)
            params.append('FECHAESTADO', this.objs.declara.FECHAESTADO)
            params.append('FUD_FICHA', this.objs.declara.FUD_FICHA)
            params.append('ENVIADOVALORACION', '1901-01-01')
            params.append('cedula', this.scannedCedula)
            params.append('actaSalida', this.actaSalida)
            axios.post( url + 'declaralbergueupdate/' + this.objs.declara.id, 
                params,
            {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${this.token}`
            }
            })
            .then(() => {
                alert ("Familia actualizada")
                this.scannedCedula = null;
                this.actaSalida = null;
                this.buscarFamilia(this.objs.declara.id)
            })
            .catch((error) => {
                this.iraLogin(error)
            })
        },
        cancelarFamilia: function () {
            this.cargarIngresos()
            this.limpiarDeclaracion()
            this.arr.showListaIngresos = true
            this.arr.showFormDeclara = false
        },
        limpiarDeclaracion: function () {
            this.objs.declara.id = '' 
            this.objs.declara.TIPODOCUMENTO = ''
            this.objs.declara.DOCUMENTO = ''
            this.objs.declara.PRIMERNOMBRE = ''
            this.objs.declara.SEGUNDONOMBRE = ''
            this.objs.declara.PRIMERAPELLIDO = ''
            this.objs.declara.SEGUNDOAPELLIDO = ''             
            this.objs.declara.NUCLEOVIVANTO = '0'
            this.objs.declara.NUCLEOINGRESO = '0'
            this.objs.declara.LUGARDECLARACION = ''
            this.objs.declara.DESPLAZAMIENTO = '0'
            this.objs.declara.AMENAZA = '0'
            this.objs.declara.HOMICIDIO = '0'
            this.objs.declara.RECLUTAMIENTOFORZADO = '0'
            this.objs.declara.DESPOJOFORZADODETIERRAS = '0'
            this.objs.declara.ACTOSTERRORISTA = '0'
            this.objs.declara.DELITOSCONTRALIBERTADSEXUAL = '0'
            this.objs.declara.SECUESTRO = '0'
            this.objs.declara.DESPARICIONFORZADA = '0'
            this.objs.declara.LESIONESPERSONALES = '0'
            this.objs.declara.MINASANTIPERSONA = '0'
            this.objs.declara.TORTURA = '0'
            this.objs.declara.MASACRE = '0'
            this.objs.declara.HOSTIGAMIENTO = '0'
            this.objs.declara.VIOLENCIADEGENERO = '0'
            this.objs.declara.OTRAALTAVULNERABILIDADREMISIONES = '0'
            this.objs.declara.ESTADOVICTIMA = '0'
            this.objs.declara.FECHAESTADO = ''
            this.objs.declara.FUD_FICHA = ''
        },
        saveIngreso: function () {                    
            let url = store.getters['auth/urlApi'];
            this.token = localStorage.getItem('token');            
            const params = new URLSearchParams()        
            params.append('DECLARAID', this.objs.ingreso.DECLARAID)
            params.append('FUD', this.objs.ingreso.FUD)
            params.append('TIPODOCUMENTO', this.objs.ingreso.TIPODOCUMENTO)
            params.append('DOCUMENTO', this.objs.ingreso.DOCUMENTO)
            params.append('PRIMERNOMBRE', this.objs.ingreso.PRIMERNOMBRE)
            params.append('SEGUNDONOMBRE', this.objs.ingreso.SEGUNDONOMBRE)
            params.append('PRIMERAPELLIDO', this.objs.ingreso.PRIMERAPELLIDO)
            params.append('SEGUNDOAPELLIDO', this.objs.ingreso.SEGUNDOAPELLIDO)
            params.append('PARENTESCO', this.objs.ingreso.PARENTESCO)

            params.append('FECHANACIMIENTO', '1901-01-01')
            params.append('GENERO', '0')
            params.append('LGTBI', '0')
            params.append('ETNIA', '0')
            params.append('DISCAPACIDAD', '0')
            params.append('GRUPOETAREO', '0')
            params.append('TELEFONO', '0')
            params.append('CORREO', '0')

            params.append('SEDE', this.objs.ingreso.SEDE)
            var fechaIngreso = moment(this.objs.ingreso.FECHAINGRESO).format("YYYY-MM-DD HH:mm:ss")            
            var fechaSalida = moment(this.objs.ingreso.FECHASALIDA).format("YYYY-MM-DD HH:mm:ss")
            params.append('FECHAINGRESO', fechaIngreso)
            params.append('FECHASALIDA', fechaSalida)
            axios.post( url + 'albergue', 
                params,
            {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${this.token}`
            }
            })
            .then((res) => {                
                this.objs.ingreso.id = res.data.data.id
                alert ("Albergue registrado")
            })
            .catch((error) => {
                if(error.response.status == 404){
                    alert("Hacen falta campos por diligenciar")
                }
                this.iraLogin(error)
            })
        },
        updateIngreso: function () {
            let url = store.getters['auth/urlApi'];
            this.token = localStorage.getItem('token');          
            const params = new URLSearchParams()        
            params.append('DECLARAID', this.objs.ingreso.DECLARAID)
            params.append('FUD', this.objs.ingreso.FUD)
            params.append('TIPODOCUMENTO', this.objs.ingreso.TIPODOCUMENTO)
            params.append('DOCUMENTO', this.objs.ingreso.DOCUMENTO)
            params.append('PRIMERNOMBRE', this.objs.ingreso.PRIMERNOMBRE)
            params.append('SEGUNDONOMBRE', this.objs.ingreso.SEGUNDONOMBRE)
            params.append('PRIMERAPELLIDO', this.objs.ingreso.PRIMERAPELLIDO)
            params.append('SEGUNDOAPELLIDO', this.objs.ingreso.SEGUNDOAPELLIDO)
            params.append('PARENTESCO', this.objs.ingreso.PARENTESCO)
            
            params.append('FECHANACIMIENTO', '1901-01-01')
            params.append('GENERO', '0')
            params.append('LGTBI', '0')
            params.append('ETNIA', '0')
            params.append('DISCAPACIDAD', '0')
            params.append('GRUPOETAREO', '0')
            params.append('TELEFONO', '0')
            params.append('CORREO', '0')

            params.append('SEDE', this.objs.ingreso.SEDE)
            var fechaIngreso = moment(this.objs.ingreso.FECHAINGRESO).format("YYYY-MM-DD HH:mm:ss")            
            var fechaSalida = moment(this.objs.ingreso.FECHASALIDA).format("YYYY-MM-DD HH:mm:ss")
            params.append('FECHAINGRESO', fechaIngreso)
            params.append('FECHASALIDA', fechaSalida)
            axios.put( url + 'albergue/' + this.objs.ingreso.id, 
                params,
            {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${this.token}`
            }
            })
            .then(() => {
                alert ("Albergue actualizado")
            })
            .catch((error) => {
                this.iraLogin(error)
            })
        },
        limpiarForm: function () {
            this.objs.ingreso.id = ''
            this.objs.ingreso.TIPODOCUMENTO = ''
            this.objs.ingreso.DOCUMENTO = ''
            this.objs.ingreso.PRIMERNOMBRE = ''
            this.objs.ingreso.SEGUNDONOMBRE = ''
            this.objs.ingreso.PRIMERAPELLIDO = ''
            this.objs.ingreso.SEGUNDOAPELLIDO = ''
            this.objs.ingreso.PARENTESCO = ''
            this.objs.ingreso.SEDE = ''
            this.objs.ingreso.FECHAINGRESO = ''
            this.objs.ingreso.HORAINGRESO = ''
            this.objs.ingreso.FECHASALIDA = ''
            this.objs.ingreso.HORASALIDA = ''
        },
        getDatoDia: function () {
            let url = store.getters['auth/urlApi'];
            this.token = localStorage.getItem('token');
            const params = new URLSearchParams()
            let fecha = moment().format("YYYY-MM-DD")
            params.append('FECHA', fecha)
            axios.post( url + 'albergueDia',
                params,
                {
                headers: {  
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${this.token}`
                }
            })
            .then((data) => {                
                this.datoDia = data.data.data
                
            })
            .catch((error) => {
                this.iraLogin(error)
            })


        },
        verImagen: function (item) {
            console.log(item)
            let url = store.getters['auth/urlApi'];
            this.token = localStorage.getItem('token');
            axios.get( url + 'cedulaAlbergue/' + item.id, {
                headers: {
                    'Accept': 'application/pdf',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`          
                },
                responseType: 'blob',
            })
            .then((res) => {   
                const content = res.headers['content-type'];
                download(res.data, item.DOCUMENTO+".pdf", content)
            })
            .catch((error) => {
                console.log(error)         
            })
        },
        verImgSalida: function (item) {
            let url = store.getters['auth/urlApi'];
            this.token = localStorage.getItem('token');
            axios.get( url + 'actaSalida/' + item.id, {
                headers: {
                    'Accept': 'application/pdf',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`          
                },
                responseType: 'blob',
            })
            .then((res) => {   
                const content = res.headers['content-type'];
                download(res.data, "Acta_salida_"+item.DOCUMENTO+".pdf", content)
            })
            .catch((error) => {
                console.log(error)         
            })
        },
        prevFiles: function (event) {
            this.salFiles = event.target.files[0].name
        },
        prevFilesin: function (event) {
            this.inFiles = event.target.files[0].name
        },
        asignarComuna: function(event) {
            let comuna = ''
            this.lugares.barrio.map(function(lugar) {
                if(lugar.id == event){
                    comuna = lugar.comuna;
                }
            });
            this.objs.declara.COMUNA = comuna;
            
        }
    },
    mounted () {
        this.getDatoDia()
        this.cargarIngresos()
        if(this.perfil == "18" || this.perfil == "2" || this.perfil == "10"){
            this.perfilPermitido = true
        }
        if(this.perfil == "18" || this.perfil == "2" || this.rol == "3"){
            this.bAlberguefull = true
        }
    }

}
</script>