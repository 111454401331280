<template>
    <div>
        <h1>Revision DX</h1>
        <p v-if="!perfilPermitido">Usted no puede acceder a esta area.</p>
        <div v-if="perfilPermitido" class="row text-center">
            <div class="col-md-12">
              <form @submit.prevent="buscarDxs">
                    <div class="input-group input-group-sm">
                        <label for="">Fecha Inicio:</label>
                        <Datepicker
                            v-model="fechaInicio"
                            is24="true"
                            locale="es"
                            format="yyyy-MM-dd"
                            :maxDate="maxDate"
                            :minDate="minDate"
                            required></Datepicker>
                        <label for="">Fecha Fin:</label>
                        <Datepicker 
                            v-model="fechaFin"
                            is24="true"
                            locale="es"
                            format="yyyy-MM-dd"
                            :maxDate="maxDate"
                            :minDate="minDate"
                            required></Datepicker>     
                        <span class="input-group-append">
                            <button class="btn btn-secondary" type="submit">Mostrar</button>                            
                        </span>                        
                    </div>
                </form>  
            </div>
        </div>
        <p></p>
        <div v-if="perfilPermitido && !infoModal.title" class="card card-secondary">
            <div class="card-header">
                <h3 class="card-title">Lista de diagnósticos</h3>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table-lite
                        :is-slot-mode="true"
                        :columns="fields"
                        :rows="dxs"> 
                        <template v-slot:dx_fecha="data">
                            {{data.value.dx_fecha}}
                        </template>
                        <template v-slot:id_1_1_1__p_nombre="data">
                            {{data.value.id_1_1_1__p_nombre}}
                        </template>
                        <template v-slot:id_1_1_2__s_nombre="data">
                            {{data.value.id_1_1_2__s_nombre}}
                        </template>
                        <template v-slot:id_1_1_3__p_apellido="data">
                            {{data.value.id_1_1_3__p_apellido}}
                        </template>
                        <template v-slot:id_1_1_4__s_apellido="data">
                            {{data.value.id_1_1_4__s_apellido}}
                        </template>
                        <template v-slot:id_1_2_2__n_documento="data">
                            {{data.value.id_1_2_2__n_documento}}
                        </template>
                        <template v-slot:comunaArribo="data">
                            {{data.value.comunaArribo   }}
                        </template>
                        <template v-slot:barrioArribo="data">
                            {{data.value.barrioArribo}}
                        </template>
                        <template v-slot:ubi_3_3__asentamiento_o_sector="data">
                            {{data.value.ubi_3_3__asentamiento_o_sector}}
                        </template>
                        <template v-slot:ubi_3_4__direccion="data">
                            {{data.value.ubi_3_4__direccion}}
                        </template>
                        <template v-slot:departamentoHecho="data">
                            {{data.value.departamentoHecho}}
                        </template>
                        <template v-slot:municipioHecho="data">
                            {{data.value.municipioHecho}}
                        </template>
                        <template v-slot:vict_4_4_3__subregion_antioquia="data">
                            {{data.value.vict_4_4_3__subregion_antioquia}}
                        </template>
                        <template v-slot:lugarExacto="data">
                            {{data.value.lugarExacto}}
                        </template>
                        <template v-slot:vict_4_4_5__nombre_tipo_entorno="data">
                            {{data.value.vict_4_4_5__nombre_tipo_entorno}}
                        </template>
                        <template v-slot:tipoMovilidad="data">
                            {{data.value.tipoMovilidad}}
                        </template>
                        <template v-slot:comunaHecho="data">
                            {{data.value.comunaHecho}}
                        </template>
                        <template v-slot:barrioHecho="data">
                            {{data.value.barrioHecho    }}
                        </template>
                        <template v-slot:vict_4_4_9__hecho_Intraurbano_Sector_o_Asentamiento="data">
                            {{data.value.vict_4_4_9__hecho_Intraurbano_Sector_o_Asentamiento}}
                        </template>
                        <template v-slot:vict_4_4_10__hecho_Intraurbano_Direccion="data">
                            {{data.value.vict_4_4_10__hecho_Intraurbano_Direccion}}
                        </template>
                        <template v-slot:vict_4_5_fecha_hecho="data">
                            {{data.value.vict_4_5_fecha_hecho}}
                        </template>
                        <template v-slot:vict_4_1_3__desplazamiento_forzado="data">
                            {{data.value.vict_4_1_3__desplazamiento_forzado}}
                        </template>
                        <template v-slot:vict_4_1_4__homicidio="data">
                            {{data.value.vict_4_1_4__homicidio}}
                        </template>
                        <template v-slot:vict_4_1_5__masacre="data">
                            {{data.value.vict_4_1_5__masacre}}
                        </template>
                        <template v-slot:vict_4_1_6__Tortura_o_tratos_inhumanos_degradantes="data">
                            {{data.value.vict_4_1_6__Tortura_o_tratos_inhumanos_degradantes}}
                        </template>
                        <template v-slot:vict_4_1_7__lesiones_permanentes_o_transitorias="data">
                            {{data.value.vict_4_1_7__lesiones_permanentes_o_transitorias}}
                        </template>
                        <template v-slot:vict_4_1_8__minas_antipersonal_municiones_sin_Explotar="data">
                            {{data.value.vict_4_1_8__minas_antipersonal_municiones_sin_Explotar}}
                        </template>
                        <template v-slot:vict_4_1_9__delitos_contra_la_libertad_y_la_integridad_sexual="data">
                            {{data.value.vict_4_1_9__delitos_contra_la_libertad_y_la_integridad_sexual}}
                        </template>
                        <template v-slot:vict_4_1_10_1__intento_de_reclutamiento="data">
                            {{data.value.vict_4_1_10_1__intento_de_reclutamiento}}
                        </template>
                        <template v-slot:vict_4_1_10_2__reclutamiento_y_utilizacion="data">
                            {{data.value.vict_4_1_10_2__reclutamiento_y_utilizacion}}
                        </template>
                        <template v-slot:vict_4_1_11__acto_terrorista="data">
                            {{data.value.vict_4_1_11__acto_terrorista}}
                        </template>
                        <template v-slot:vict_4_1_12__amenaza="data">
                            {{data.value.vict_4_1_12__amenaza}}
                        </template>
                        <template v-slot:vict_4_1_13__despojo_y_abandono_forzado_de_tierras="data">
                            {{data.value.vict_4_1_13__despojo_y_abandono_forzado_de_tierras}}
                        </template>
                        <template v-slot:actor1="data">
                            {{data.value.actor1}}
                        </template>
                        <template v-slot:actor2="data">
                            {{data.value.actor2}}
                        </template>
                        <template v-slot:vict_4_2_1_1_Si_la_Respuesta_anterior_es_OTROS_cual_Identificar="data">
                            {{data.value.vict_4_2_1_1_Si_la_Respuesta_anterior_es_OTROS_cual_Identificar}}
                        </template>
                        <template v-slot:dx_7_1_nombre_responsable_atencion="data">
                            {{data.value.dx_7_1_nombre_responsable_atencion}}
                        </template>
                        <template v-slot:actions="data">
                            <button type="button" class="btn btn-block btn-default" @click="info(data.value)">Ver</button>
                        </template>
                    </table-lite>
                </div>                
            </div>            
        </div>
        <div v-if="perfilPermitido && infoModal.title" class="card card-secondary">
            <div class="card-header">
                <h3 class="card-title">Revisión Diagnostico</h3>
            </div>
            <div class="card-body">
                <b>RESPONSABLE:</b> {{ infoModal.content.dx_7_1_nombre_responsable_atencion }}
                <form @submit.prevent="submitDx">
                    <div class="row">
                        <div class="col-md-12">
                            <b>ID:</b> {{ infoModal.content.id }}
                            <b>FECHA:</b> {{ infoModal.content.dx_fecha }}, 
                            <b>NOMBRES:</b> {{ infoModal.content.id_1_1_1__p_nombre }} 
                            {{ infoModal.content.id_1_1_2__s_nombre }} 
                            {{ infoModal.content.id_1_1_3__p_apellido }} 
                            {{ infoModal.content.id_1_1_4__s_apellido }}, 
                            <b>DOCUMENTO:</b> {{ infoModal.content.id_1_2_2__n_documento }},
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="input-group input-group-sm">
                                <span class="input-group-append">
                                    <label for="">FECHA HECHO:</label>
                                </span>
                                <Datepicker 
                                    v-model="fechaFin"
                                    is24="true"
                                    locale="es"
                                    format="yyyy-MM-dd"
                                    :maxDate="maxDate"
                                    :minDate="minDate"
                                    required></Datepicker>     
                                <b-form-datepicker v-model="infoModal.content.vict_4_5_fecha_hecho" class="mb-2"></b-form-datepicker>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="input-group input-group-sm">
                                <span class="input-group-append">
                                    MUNICIPIO:
                                </span>
                                <select 
                                    class="custom-select form-control-border" 
                                    :selected="infoModal.content.vict_4_4_2__municipio" 
                                    @change="setLugarHecho" 
                                    v-model="infoModal.content.vict_4_4_2__municipio" 
                                    required>
                                    <option 
                                        v-for="municipio in lugares.municipio" 
                                        :key="municipio.id" 
                                        :value="municipio.id">
                                        {{ municipio.municipio }}
                                    </option>
                                </select>
                            </div> 
                        </div>
                        <div class="col-md-6">
                            <div class="input-group input-group-sm">
                                <b>DEPARTAMENTO:</b> {{ infoModal.content.departamentoHecho }},                                
                                <b>SUBREGION:</b>  {{ infoModal.content.vict_4_4_3__subregion_antioquia }},
                                <b>TIPO MOVILIDAD:</b> {{ lugares.tipoMovilidad[infoModal.content.vict_4_4_6__tipo_movilidad] }}
                            </div>                            
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="input-group input-group-sm">
                                <span class="input-group-append">
                                    LUGAR EXACTO:
                                </span>
                                <select class="custom-select form-control-border" 
                                    :selected='infoModal.content.vict_4_4_4__lugar_exacto_del_municipio' 
                                    v-model="infoModal.content.vict_4_4_4__lugar_exacto_del_municipio" >
                                    <option 
                                        v-for="option in lugarExacto" 
                                        v-bind:key="option.value" 
                                        :value="option.value">
                                        {{option.text}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="input-group input-group-sm">
                                <span class="input-group-append">
                                    ENTORNO:
                                </span>
                                <input class="form-control form-control-border" type="text" v-model="infoModal.content.vict_4_4_5__nombre_tipo_entorno">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <h5>Ubicación del hecho intraurbano</h5>
                        <div class="col-md-4">
                            <div class="input-group input-group-sm">
                                <span class="input-group-append">
                                    BARRIO:
                                </span>
                                <select 
                                    class="custom-select form-control-border" 
                                    :selected="infoModal.content.vict_4_4_8__hecho_Intraurbano_Barrio" 
                                    @change="setBarrioHecho" 
                                    v-model="infoModal.content.vict_4_4_8__hecho_Intraurbano_Barrio">
                                    <option v-for="barrio in lugares.barrio" :key="barrio.id" :value="barrio.id">{{ barrio.barrio }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <b>COMUNA:</b> {{ infoModal.content.vict_4_4_7__hecho_Intraurbano_Comuna_o_Corregimiento }} - {{ infoModal.content.comunaHecho }}
                        </div> 
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="input-group input-group-sm">
                                <span class="input-group-append">
                                    SECTOR INTRAURBANO:
                                </span>
                                <input class="form-control form-control-border" v-model="infoModal.content.vict_4_4_9__hecho_Intraurbano_Sector_o_Asentamiento" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="input-group input-group-sm">
                                <span class="input-group-append">
                                    DIRECCION:
                                </span>
                                <input class="form-control form-control-border" v-model="infoModal.content.vict_4_4_10__hecho_Intraurbano_Direccion"/>
                            </div>
                        </div>
                    </div>                        
                    <div class="row">
                        <h5>Ubicación arribo</h5>
                        <div class="col-md-6">
                            <div class="input-group input-group-sm">
                                <span class="input-group-append">
                                    BARRIO:
                                </span>
                                <select class="custom-select form-control-border" :selected="infoModal.content.ubi_3_2__barrio_o_vereda" @change="setBarrioArribo" v-model="infoModal.content.ubi_3_2__barrio_o_vereda" required>
                                    <option v-for="barrio in lugares.barrio" :key="barrio.id" :value="barrio.id">{{ barrio.barrio }}</option>
                                </select>
                            </div>                                
                        </div>
                        <div class="col-md-6">
                            <b>COMUNA:</b> {{ infoModal.content.ubi_3_1__comuna_o_corregimiento }} - {{ infoModal.content.comunaArribo }}                                
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="input-group input-group-sm">
                                <span class="input-group-append">
                                    SECTOR INTRAURBANO:
                                </span>
                                <input type="text" class="form-control form-control-border" v-model="infoModal.content.ubi_3_3__asentamiento_o_sector"/>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="input-group input-group-sm">
                                <span class="input-group-append">
                                    DIRECCION:
                                </span>
                                <input type="text" class="form-control form-control-bordered" v-model="infoModal.content.ubi_3_4__direccion">
                            </div>
                        </div>
                    </div>                        
                    <div class="row">
                        <h5>Actor armados responsables</h5>
                        <div class="col-md-4">
                            <div class="input-group input-group-sm">
                                <span class="input-group-append">
                                    ACTOR ARMADO 1:
                                </span>
                                <select class="custom-select form-control-border" :selected="infoModal.content.vict_4_2__actor_armado_ocasiono_el_hecho_victimizante" v-model="infoModal.content.vict_4_2__actor_armado_ocasiono_el_hecho_victimizante">
                                    <option v-for="actor in lugares.actorArmado" :key="actor.value" :value="actor.value">{{ actor.text }}</option>
                                </select>
                            </div> 
                        </div>  
                        <div class="col-md-4">
                            <div class="input-group input-group-sm">
                                <span class="input-group-append">
                                    ACTOR ARMADO 2:
                                </span>
                                <select class="custom-select form-control-border" :selected="infoModal.content.vict_4_2_1_actor_armado_ocasiono_el_hecho_victimizante" v-model="infoModal.content.vict_4_2_1_actor_armado_ocasiono_el_hecho_victimizante">
                                    <option v-for="actor in lugares.actorArmado" :key="actor.value" :value="actor.value">{{ actor.text }}</option>
                                </select>
                            </div> 
                        </div>
                        <div class="col-md-4">
                            <div class="input-group input-group-sm">
                                <span class="input-group-append">
                                    OTRO ACTOR ARMADO
                                </span>
                                <input class="form-control form-control-border" v-model="infoModal.content.vict_4_2_1_1_Si_la_Respuesta_anterior_es_OTROS_cual_Identificar"/>
                            </div>
                        </div>    
                    </div>  
                    <div class="row">
                        <h5>Hechos declarados</h5>
                    </div>    
                    <div class="row">
                        <div class="col-md-3">
                            <div class="input-group input-group-sm">
                                <span class="input-group-append">
                                    Desplazamiento
                                </span>
                                <input type="number" class="form-control form-control-border" min="0" max="1" v-model="infoModal.content.vict_4_1_3__desplazamiento_forzado">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group input-group-sm">
                                <span class="input-group-append">
                                    Homicidio
                                </span>
                                <input type="number" class="form-control form-control-border" min="0" max="1" v-model="infoModal.content.vict_4_1_4__homicidio">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group input-group-sm">
                                <span class="input-group-append">
                                    Masacre
                                </span>
                                <input type="number" class="form-control form-control-border" min="0" max="1" v-model="infoModal.content.vict_4_1_5__masacre">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group input-group-sm">
                                <span class="input-group-append">
                                    Tortura
                                </span>
                                <input type="number" class="form-control form-control-border" min="0" max="1" v-model="infoModal.content.vict_4_1_6__Tortura_o_tratos_inhumanos_degradantes">
                            </div>
                        </div>                           
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="input-group input-group-sm">
                                <span class="input-group-append">
                                    Lesiones
                                </span>
                                <input type="number" class="form-control form-control-border" min="0" max="1" v-model="infoModal.content.vict_4_1_7__lesiones_permanentes_o_transitorias">
                            </div>
                        </div> 
                        <div class="col-md-3">
                            <div class="input-group input-group-sm">
                                <span class="input-group-append">
                                    Minas
                                </span>
                                <input type="number" class="form-control form-control-border" min="0" max="1" v-model="infoModal.content.vict_4_1_8__minas_antipersonal_municiones_sin_Explotar">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group input-group-sm">
                                <span class="input-group-append">
                                    Delito sexual
                                </span>
                                <input type="number" class="form-control form-control-border" min="0" max="1" v-model="infoModal.content.vict_4_1_9__delitos_contra_la_libertad_y_la_integridad_sexual">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group input-group-sm">
                                <span class="input-group-append">
                                    Intento reclutamiento
                                </span>
                                <input type="number" class="form-control form-control-border" min="0" max="1" v-model="infoModal.content.vict_4_1_10_1__intento_de_reclutamiento">
                            </div>
                        </div>                                                        
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="input-group input-group-sm">
                                <span class="input-group-append">
                                    Reclutamiento
                                </span>
                                <input type="number" class="form-control form-control-border" min="0" max="1" v-model="infoModal.content.vict_4_1_10_2__reclutamiento_y_utilizacion">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group input-group-sm">
                                <span class="input-group-append">
                                    acto terorista
                                </span>
                                <input type="number" class="form-control form-control-border" min="0" max="1" v-model="infoModal.content.vict_4_1_11__acto_terrorista">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group input-group-sm">
                                <span class="input-group-append">
                                    Amenaza
                                </span>
                                <input type="number" class="form-control form-control-border" min="0" max="1" v-model="infoModal.content.vict_4_1_12__amenaza">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group input-group-sm">
                                <span class="input-group-append">
                                    Abandono
                                </span>
                                <input type="number" class="form-control form-control-border" min="0" max="1" v-model="infoModal.content.vict_4_1_13__despojo_y_abandono_forzado_de_tierras">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <b>Observaciones: </b>
                            <p>{{ infoModal.content.vict_4_7__observaciones }}</p>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-3">
                            <button class="btn btn-secondary" type="submit">Guardar</button>
                        </div>                            
                    </div>
                </form>                    
            </div>
        </div>
    </div>
</template>
<script>
import lugares from '../lugares';
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css';
import TableLite from "vue3-table-lite";
import store from '@/store/index';
import axios from 'axios';
export default {
    name: 'Revisiondx',
    components: { 
        Datepicker,
        "table-lite": TableLite 
    },
    data () {
        return {
            perfil: localStorage.getItem("userPerfil"),
            perfilPermitido: false,
            token: '',
            url: '',
            fechaInicio: '',
            fechaFin: '',
            minDate: '2019-07-01',
            maxDate: new Date(),
            dxs: [],
            lugares: lugares,
            filter: null,
            striped: true,
            bordered: true,
            stickyHeader: "800px",
            fields: [
                { field: 'dx_fecha', label: 'Fecha dx' },
                { field: 'id_1_1_1__p_nombre', label: 'Primer nombre'},
                { field: 'id_1_1_2__s_nombre', label: 'Segundo nombre' },
                { field: 'id_1_1_3__p_apellido', label: 'Primer apellido' },
                { field: 'id_1_1_4__s_apellido', label: 'Segundo apellido'},
                { field: 'id_1_2_2__n_documento', label: 'Documento', stickyColumn: true },
                { field: 'comunaArribo', label: 'Comuna arribo' },
                { field: 'barrioArribo', label: 'Barrio arribo' },
                { field: 'ubi_3_3__asentamiento_o_sector', label: 'Sector arribo' },
                { field: 'ubi_3_4__direccion', label: 'Direccion arribo' },
                { field: 'departamentoHecho', label: 'Departamento' },
                { field: 'municipioHecho', label: 'Municipio' },
                { field: 'vict_4_4_3__subregion_antioquia', label: 'Subregion' },
                { field: 'lugarExacto', label: 'Lugar exacto' },
                { field: 'vict_4_4_5__nombre_tipo_entorno', label: 'Entorno' },
                { field: 'tipoMovilidad', label: 'Tipo movilidad' },
                { field: 'comunaHecho', label: 'Comuna hecho' },
                { field: 'barrioHecho', label: 'Barrio hecho' },
                { field: 'vict_4_4_9__hecho_Intraurbano_Sector_o_Asentamiento', label: 'Sector hecho' },
                { field: 'vict_4_4_10__hecho_Intraurbano_Direccion', label: 'Dirección hecho' },
                { field: 'vict_4_5_fecha_hecho', label: 'Fecha ocurrencia' },
                { field: 'vict_4_1_3__desplazamiento_forzado', label: 'Desplazamiento' },
                { field: 'vict_4_1_4__homicidio', label: 'Homicidio' },
                { field: 'vict_4_1_5__masacre', label: 'Masacre' },
                { field: 'vict_4_1_6__Tortura_o_tratos_inhumanos_degradantes', label: 'Tortura' },
                { field: 'vict_4_1_7__lesiones_permanentes_o_transitorias', label: 'Lesiones' },
                { field: 'vict_4_1_8__minas_antipersonal_municiones_sin_Explotar', label: 'Minas' },
                { field: 'vict_4_1_9__delitos_contra_la_libertad_y_la_integridad_sexual', label: 'Delito sexual' },
                { field: 'vict_4_1_10_1__intento_de_reclutamiento', label: 'Intento reclutamiento' },
                { field: 'vict_4_1_10_2__reclutamiento_y_utilizacion', label: 'Reclutamiento' },
                { field: 'vict_4_1_11__acto_terrorista', label: 'Acto terrorista' },
                { field: 'vict_4_1_12__amenaza', label: 'Amenaza' },
                { field: 'vict_4_1_13__despojo_y_abandono_forzado_de_tierras', label: 'Despojo tierras' },
                { field: 'actor1', label: 'Actor armado 1' },
                { field: 'actor2', label: 'Actor armado 2' },
                { field: 'vict_4_2_1_1_Si_la_Respuesta_anterior_es_OTROS_cual_Identificar', label: 'Otro actor armado' },
                { field: 'dx_7_1_nombre_responsable_atencion', label: 'Responsable' },
                { field: 'actions', label: 'Acciones' }
            ],
            infoModal: {
                id: 'info-modal',
                title: false,
                content: ''
            },
            lugarExacto: [
                { value: '0', text: 'Sin dato'},
                { value: '1', text: '1 - Cabecera municipal'},
                { value: '2', text: '2 - Barrio'},
                { value: '3', text: '3 - Corregimiento'},
                { value: '3', text: '4 - Vereda'}                
            ]
        }
    },
    methods: {
        buscarDxs () {
            if (this.fechaInicio == '' || this.fechaFin  == ''
                || this.fechaInicio == null || this.fechaFin  == null) {
                alert("favor llene los campos de fecha");
                return false
            }
            this.resetInfoModal();
            axios.get( this.url + 'diagnosticoFecha', 
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`          
                },
                params: { 
                    fechaInicial: this.fechaInicio.toISOString().slice(0, 10),
                    fechaFinal: this.fechaFin.toISOString().slice(0, 10)
                }
            })
            .then((res) => {           
                this.dxs = res.data.data
                this.dxs.forEach(fila => {                    
                    let barrio = fila.vict_4_4_8__hecho_Intraurbano_Barrio
                    lugares.barrio.some( item => {
                        if(item.id == barrio){
                            fila.barrioHecho = item.barrio             
                            fila.comunaHecho = item.comuna                    
                        }
                    })        
                    
                    barrio = fila.ubi_3_2__barrio_o_vereda
                    lugares.barrio.some( item => {
                        if(item.id == barrio){
                            fila.barrioArribo = item.barrio             
                            fila.comunaArribo = item.comuna                    
                        }
                    })

                    let municipio = fila.vict_4_4_2__municipio
                    lugares.municipio.some( item => {
                        if(item.id == municipio){
                            fila.municipioHecho = item.municipio             
                            fila.departamentoHecho = item.departamento                    
                        }
                    })

                    fila.lugarExacto = lugares.lugarExacto[fila.vict_4_4_4__lugar_exacto_del_municipio]
                    fila.tipoMovilidad = lugares.tipoMovilidad[fila.vict_4_4_6__tipo_movilidad]
                    
                    let actor = fila.vict_4_2__actor_armado_ocasiono_el_hecho_victimizante
                    lugares.actorArmado.some( item => {
                        if(item.value == actor){
                            fila.actor1 = item.text
                        }
                    })

                    actor = fila.vict_4_2_1_actor_armado_ocasiono_el_hecho_victimizante
                    lugares.actorArmado.some( item => {
                        if(item.value == actor){
                            fila.actor2 = item.text
                        }
                 
                 })
                })        
            })
            .catch((error) => {
                console.log(error)
            })
        },
        info(item) {
            this.resetInfoModal()
            this.infoModal.title = true
            this.infoModal.content = item
            this.setLugarHecho()
            this.setBarrioHecho()
            this.setBarrioArribo()
        },
        resetInfoModal() {
            this.infoModal.title = false
            this.infoModal.content = ''
        },
        submitDx: function () {
            alert(this.infoModal.content.indice)
                                   
            let url = this.$store.getters.getApiUrl
            this.token = this.$store.getters.theToken         
            const params = new URLSearchParams()            
            params.append('vict_4_5_fecha_hecho', this.infoModal.content.vict_4_5_fecha_hecho)
            params.append('vict_4_4_2__municipio', this.infoModal.content.vict_4_4_2__municipio)
            params.append('vict_4_4_1__departamento', this.infoModal.content.vict_4_4_1__departamento)            
            params.append('vict_4_4_3__subregion_antioquia', this.infoModal.content.vict_4_4_3__subregion_antioquia)            
            params.append('vict_4_4_5__nombre_tipo_entorno', this.infoModal.content.vict_4_4_5__nombre_tipo_entorno)            
            params.append('vict_4_4_6__tipo_movilidad', this.infoModal.content.vict_4_4_6__tipo_movilidad)            
            params.append('vict_4_4_4__lugar_exacto_del_municipio', this.infoModal.content.vict_4_4_4__lugar_exacto_del_municipio)            
            params.append('vict_4_4_8__hecho_Intraurbano_Barrio', this.infoModal.content.vict_4_4_8__hecho_Intraurbano_Barrio)            
            params.append('vict_4_4_7__hecho_Intraurbano_Comuna_o_Corregimiento', this.infoModal.content.vict_4_4_7__hecho_Intraurbano_Comuna_o_Corregimiento)            
            params.append('vict_4_4_9__hecho_Intraurbano_Sector_o_Asentamiento', this.infoModal.content.vict_4_4_9__hecho_Intraurbano_Sector_o_Asentamiento)            
            params.append('vict_4_4_10__hecho_Intraurbano_Direccion', this.infoModal.content.vict_4_4_10__hecho_Intraurbano_Direccion)            
            params.append('ubi_3_1__comuna_o_corregimiento', this.infoModal.content.ubi_3_1__comuna_o_corregimiento)            
            params.append('ubi_3_2__barrio_o_vereda', this.infoModal.content.ubi_3_2__barrio_o_vereda)            
            params.append('ubi_3_3__asentamiento_o_sector', this.infoModal.content.ubi_3_3__asentamiento_o_sector)
            params.append('ubi_3_4__direccion', this.infoModal.content.ubi_3_4__direccion)           
            params.append('vict_4_2__actor_armado_ocasiono_el_hecho_victimizante', this.infoModal.content.vict_4_2__actor_armado_ocasiono_el_hecho_victimizante)            
            params.append('vict_4_2_1_actor_armado_ocasiono_el_hecho_victimizante', this.infoModal.content.vict_4_2_1_actor_armado_ocasiono_el_hecho_victimizante)            
            params.append('vict_4_2_1_1_Si_la_Respuesta_anterior_es_OTROS_cual_Identificar', this.infoModal.content.vict_4_2_1_1_Si_la_Respuesta_anterior_es_OTROS_cual_Identificar)            
            params.append('vict_4_1_3__desplazamiento_forzado', this.infoModal.content.vict_4_1_3__desplazamiento_forzado)            
            params.append('vict_4_1_4__homicidio', this.infoModal.content.vict_4_1_4__homicidio)            
            params.append('vict_4_1_5__masacre', this.infoModal.content.vict_4_1_5__masacre)            
            params.append('vict_4_1_6__Tortura_o_tratos_inhumanos_degradantes', this.infoModal.content.vict_4_1_6__Tortura_o_tratos_inhumanos_degradantes)            
            params.append('vict_4_1_7__lesiones_permanentes_o_transitorias', this.infoModal.content.vict_4_1_7__lesiones_permanentes_o_transitorias)            
            params.append('vict_4_1_8__minas_antipersonal_municiones_sin_Explotar', this.infoModal.content.vict_4_1_8__minas_antipersonal_municiones_sin_Explotar)            
            params.append('vict_4_1_9__delitos_contra_la_libertad_y_la_integridad_sexual', this.infoModal.content.vict_4_1_9__delitos_contra_la_libertad_y_la_integridad_sexual)            
            params.append('vict_4_1_10_1__intento_de_reclutamiento', this.infoModal.content.vict_4_1_10_1__intento_de_reclutamiento)            
            params.append('vict_4_1_10_2__reclutamiento_y_utilizacion', this.infoModal.content.vict_4_1_10_2__reclutamiento_y_utilizacion)            
            params.append('vict_4_1_11__acto_terrorista', this.infoModal.content.vict_4_1_11__acto_terrorista)            
            params.append('vict_4_1_12__amenaza', this.infoModal.content.vict_4_1_12__amenaza)            
            params.append('vict_4_1_13__despojo_y_abandono_forzado_de_tierras', this.infoModal.content.vict_4_1_13__despojo_y_abandono_forzado_de_tierras)        

            axios.post( url + 'saverevisiondx/' + this.infoModal.content.id, 
                params,
            {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${this.token}`
            }
            })
            .then(() => {                        
                alert ("dx guardado.")
                this.$bvModal.hide(this.infoModal.id) 
                this.buscarDxs() 
            })
            .catch((error) => {
                this.iraLogin(error)
            })


        },
        iraLogin: function (error) {
            console.log(error)
        },
        setLugarHecho: function () {
            let municipio = this.infoModal.content.vict_4_4_2__municipio
            lugares.municipio.some(function(item){                        
                if(item.id == municipio){                   
                    this.infoModal.content.vict_4_4_1__departamento = item.cod_dept
                    this.infoModal.content.departamentoHecho = item.departamento
                    this.infoModal.content.vict_4_4_3__subregion_antioquia = item.subregion
                    if(item.id == 12){
                        this.infoModal.content.vict_4_4_6__tipo_movilidad = 3
                    }else if(item.cod_dept == 2){
                        this.infoModal.content.vict_4_4_6__tipo_movilidad = 2
                    }else {
                        this.infoModal.content.vict_4_4_6__tipo_movilidad = 1
                    }
                }
            })
        },
        setBarrioHecho: function () {
            let barrio = this.infoModal.content.vict_4_4_8__hecho_Intraurbano_Barrio
            lugares.barrio.some(function(item){                        
                if(item.id == barrio){
                    this.infoModal.content.barrioHecho = item.barrio
                    this.infoModal.content.vict_4_4_7__hecho_Intraurbano_Comuna_o_Corregimiento = item.idcomuna
                    this.infoModal.content.comunaHecho = item.comuna                    
                }
            })
        },
        setBarrioArribo: function () {
            let barrio = this.infoModal.content.ubi_3_2__barrio_o_vereda
            lugares.barrio.some(function(item){                        
                if(item.id == barrio){                   
                    this.infoModal.content.barrioArribo = item.barrio
                    this.infoModal.content.ubi_3_1__comuna_o_corregimiento = item.idcomuna
                    this.infoModal.content.comunaArribo = item.comuna                    
                }
            })
        }
    },
    mounted () {
        this.token = localStorage.getItem('token');
        this.url = store.getters['auth/urlApi'];
        this.perfil = localStorage.getItem("userPerfil");

        if(this.perfil == "22" || this.perfil == "2"){
            this.perfilPermitido = true
        }
    }
}
</script>
