export async function getListaTipo (url, pagina, token) {
    let pag = (pagina == 'tiposSalud')?'getRazonesNoEstudio':pagina
    return await fetch (url + pag, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
    .then(resp => resp.json())
    .then(data => {   
        data = Object.values(data)    
        switch (pagina) {
            case 'getTiposIdentificacion','getParentescos', 'getDiscapacidades':
                for (let index = 0; index < data.length; index++) {
                    const element = data[index];
                    if(element.activo == 0){
                        data.splice(index, 1);
                    }
                }
                break;
            case 'tiposSalud':
                    data.shift();
                    break;      
            default:
                break;
        }
        return data;
        
    });
}

export function csvExport (arrData, titleFile) {
    let csvContent = "data:text/csv;charset=utf-8,";
        csvContent += [
            Object.keys(arrData[0]).join(";"),
            ...arrData.map(item => Object.values(item).join(";"))
        ]
            .join("\n")
            .replace(/(^\[)|(\]$)/gm, "");

        const data = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", data);
        const title = titleFile + ".csv";
        link.setAttribute("download", title);
        link.click();
}

export function calcularEdad (fechaNacimiento){
    var birthday_arr = fechaNacimiento.split("-");
    var birthday_date = new Date(birthday_arr[0], birthday_arr[1] - 1, birthday_arr[2]);
    var ageDifMs = Date.now() - birthday_date.getTime();
    var ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}