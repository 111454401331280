<template>
    <div>
        <p v-if="!perfilPermitido">Usted no puede acceder a esta area.</p>

        <div class="row align-items-center" v-if="perfilPermitido">
            <div class="col-md-4"></div>
            <div class="col-md-4">
                <b-button-group>                
                    <b-dropdown left text="Informes periodo">
                    <b-dropdown-item @click="menu('PRESUPUESTAL')">Ejecución presupuestal</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item @click="menu('ESTADOPRESUPUESTO')">Estado presupuesto</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item @click="menu('INGRESOSFECHAS')">Ingresos fechas</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item @click="menu('SALIDASFECHAS')">Egresos fechas</b-dropdown-item>
                    </b-dropdown>
                    <b-button @click="menu('DATODIA')">Estado día</b-button>
                    <b-button @click="menu('ALBERGUEDX')">Albergue DX</b-button>
                </b-button-group>
            </div>            
        </div>
        
        <b-container v-if="showEjecucionfecha && perfilPermitido">
            <br>
            <div class="card card-secondary">
                <div class="card-header">
                    <h2 class="card-title">Generar reporte por mes</h2>
                </div>
                <div class="card-body">
                    <b>Contrato: </b>
                    <b-form-select v-model="contrato">
                        <b-form-select-option v-for="contrato in contratos" :key="contrato.id" :value="contrato.id">
                            {{ contrato.NROCONTRATO }}
                        </b-form-select-option>
                    </b-form-select>
                    <b-input-group>                        
                        <b-form-datepicker locale="es" v-model="fechaInicio"></b-form-datepicker>
                        <b-form-datepicker locale="es" v-model="fechaFin"></b-form-datepicker>
                        <b-button @click="generarInformeMensual">Generar reporte fechas</b-button>
                    </b-input-group>
                </div>
            </div>
            
            <div class="card card-secondary">
                <div class="card-header">
                    <h2 class="card-title">Estado ejecución</h2>
                </div>
                <div class="card-body">
                    <b-input-group>
                        <b-button class="mb-3"  @click="getEstadoEjecucion()">Ver ejecucion</b-button>
                    </b-input-group>
                    <div class="table-responsive">
                        <b-table
                            :columns="ejecucionesFields"
                            :rows="ejecuciones">
                        </b-table>    
                    </div>
                </div>
            </div>

        </b-container>

        <b-container v-if="showDatodia && perfilPermitido">
            <br>
            <h2>ESTADO DÍA</h2>
            <b-row align-h="center">
                <b-col sm="4">
                    Reporte al día
                    <b-input-group>
                        <b-form-datepicker                        
                            locale='es' 
                            id="fechaCupodia" 
                            v-model="fechaCupodia" 
                            class="mb-3"
                            :min="minDate" :max="maxDate">                        
                        </b-form-datepicker>
                        <b-button class="mb-3"  @click="getDatoDia()">Ver</b-button>
                    </b-input-group>
                    
                </b-col>
            </b-row>
            <b-row align-h="center">
                <b-col sm="3">
                    
                    <b-table 
                        hover 
                        bordered=bordered
                        :columns="datoDiaColumns"
                        :rows="datoDia">
                    </b-table>
                </b-col>                
            </b-row>
        </b-container>
        <div v-if="showPresupuestal && perfilPermitido" class="card card-secondary">
            <div class="card-header">
                <h2 class="card-title">EJECUCION PRESUPUESTAL</h2>
            </div>
            <div class="card-body">
                <b-row align-h="center">                
                    <b-col sm="5">
                        <b-input-group prepend="Fecha inicio">
                            <b-form-datepicker 
                            locale='es' 
                            id="fechaInicio" 
                            v-model="fechaInicio" 
                            class="sm-3"
                            :min="minDate" :max="maxDate"></b-form-datepicker>                        
                        </b-input-group>
                    </b-col>
                    <b-col sm="5">
                        <b-input-group prepend="Fecha fin">
                            <b-form-datepicker 
                            locale='es' 
                            id="fechaFin" 
                            v-model="fechaFin" 
                            class="sm-3"
                            :min="minDate" :max="maxDate"></b-form-datepicker>                        
                        </b-input-group>
                    </b-col>
                    <b-col sm="2">
                        <b-button-group>
                            <b-button @click="generarCostos()">Ver</b-button>
                            <b-button variant="success" v-if="costosAlbergue.length != 0" @click="csvExport(costosAlbergue)">Exportar</b-button>
                        </b-button-group>
                    </b-col>
                </b-row>
                <b-row v-if="costoTotal != 0">
                    <b-col>
                        Costo total: {{ costoTotal }}
                    </b-col>
                </b-row>
                <div class="table-responsive">
                    <b-table 
                        :columns="costosAlbergueFields"
                        :rows="costosAlbergue"
                        >                
                    </b-table>
                </div>
                
                <div class="table-responsive">
                    <b-table 
                        :columns="contratosFields"
                        :rows="contratos"
                        >                
                    </b-table>
                </div>
            </div>
        </div>
        <div v-if="showIngresosFechas && perfilPermitido" class="card card-secondary">
            <div class="card-header">
                <h2 class="card-title">
                    INGRESOS FECHAS
                </h2>
            </div>
            <div class="card-body">
                <b-row align-h="center">                
                    <b-col sm="5">
                        <b-input-group prepend="Fecha inicio">
                            <b-form-datepicker 
                            locale='es' 
                            id="fechaInicio" 
                            v-model="fechaInicio" 
                            class="sm-3"
                            :min="minDate" :max="maxDate"></b-form-datepicker>                        
                        </b-input-group>
                    </b-col>
                    <b-col sm="5">
                        <b-input-group prepend="Fecha fin">
                            <b-form-datepicker 
                            locale='es' 
                            id="fechaFin" 
                            v-model="fechaFin" 
                            class="sm-3"
                            :min="minDate" :max="maxDate"></b-form-datepicker>                        
                        </b-input-group>
                    </b-col>
                    <b-col sm="2">
                        <b-button-group>
                            <b-button @click="ingresosFechas()">Ver</b-button>
                            <b-button variant="success" v-if="ingresosFechasData.length != 0" @click="csvExport(ingresosFechasData)">Exportar</b-button>
                        </b-button-group>
                    </b-col>
                </b-row>
                <div class="table-responsive">
                    <b-table 
                        :columns="ingresosFechasDataFields"
                        :rows="ingresosFechasData"
                        >                
                    </b-table>
                </div>
            </div>
        </div>
        <div v-if="showSalidasFechas && perfilPermitido" class="card card-secondary">
            <div class="card-header">
                <h2 class="card-title">EGRESOS FECHAS</h2>
            </div>
            <div class="card-body">
                <b-row align-h="center">                
                    <b-col sm="5">
                        <b-input-group prepend="Fecha inicio">
                            <b-form-datepicker 
                            locale='es' 
                            id="fechaInicio" 
                            v-model="fechaInicio" 
                            class="sm-3"
                            :min="minDate" :max="maxDate"></b-form-datepicker>                        
                        </b-input-group>
                    </b-col>
                    <b-col sm="5">
                        <b-input-group prepend="Fecha fin">
                            <b-form-datepicker 
                            locale='es' 
                            id="fechaFin" 
                            v-model="fechaFin" 
                            class="sm-3"
                            :min="minDate" :max="maxDate"></b-form-datepicker>                        
                        </b-input-group>
                    </b-col>
                    <b-col sm="2">
                        <b-button-group>
                            <b-button @click="salidasFechas()">Ver</b-button>
                            <b-button variant="success" v-if="salidasFechasData.length != 0" @click="csvExport(salidasFechasData)">Exportar</b-button>
                        </b-button-group>
                    </b-col>
                </b-row>
                <div class="table-responsive">
                    <b-table 
                        :columns="salidasFechasDataFields"
                        :rows="salidasFechasData"
                        >                
                    </b-table>
                </div>
            </div>
        </div>
        <div v-if="showAlberguedx && perfilPermitido" class="card card-secondary">
            <div class="card-header">
                <h3 class="card-title">ALBERGUE DX</h3>
            </div>
            <div class="card-body">
                <b-row>
                    <b-col sm="5">
                        <b-input-group prepend="Fecha inicio">
                            <b-form-datepicker 
                            locale='es' 
                            id="fechaInicio" 
                            v-model="fechaInicio" 
                            class="sm-3"
                            :min="minDate" :max="maxDate"></b-form-datepicker>                        
                        </b-input-group>
                    </b-col>
                    <b-col sm="5">
                        <b-input-group prepend="Fecha fin">
                            <b-form-datepicker 
                            locale='es' 
                            id="fechaFin" 
                            v-model="fechaFin" 
                            class="sm-3"
                            :min="minDate" :max="maxDate"></b-form-datepicker>                        
                        </b-input-group>
                    </b-col>
                    <b-col sm="2">
                        <b-button-group>
                            <b-button @click="getAlbergueDx()">Ver</b-button>
                            <b-button variant="success" v-if="alberguedx.length != 0" @click="csvExport(alberguedx)">Exportar</b-button>
                        </b-button-group>
                    </b-col>
                </b-row>
                <div class="table-responsive">
                    <b-table
                        :columns="albergueDXTitles"
                        :rows="alberguedx"
                        >
                    </b-table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import TableLite from "vue3-table-lite"
import store from '@/store/index'
import Datepicker from 'vue3-date-time-picker'
import moment from 'moment-timezone'
export default {
    name: 'InformeAlbergue',
    components: {
        'b-table': TableLite,
        'b-form-datepicker': Datepicker
    },
    data () {
        return {
            perfil: localStorage.getItem("userPerfil"),
            rol: localStorage.getItem("userRol"),
            perfilPermitido: false,
            showDatodia: false,
            showEjecucionfecha: false,
            showPresupuestal: false,
            showIngresosFechas: false,
            showSalidasFechas: false,
            showAlberguedx: false,
            token: '',
            ejecucionesFields: [
                { field: 'id', label: 'id'},
                { field: 'mes', label: 'mes'},
                { field: 'anio', label: 'anio'},
                { field: 'ingresofamilias', label: 'ingresofamilias'},
                { field: 'ingresopersonas', label: 'ingresopersonas'},
                { field: 'permanenciafamilias', label: 'permanenciafamilias'},
                { field: 'permanenciapersonas', label: 'permanenciapersonas'},
                { field: 'ejecutadomes', label: 'ejecutadomes'},
                { field: 'vulnerabilidadfamilias', label: 'vulnerabilidadfamilias'},
                { field: 'vulnerabilidadpersonas', label: 'vulnerabilidadpersonas'},
                { field: 'nrocontrato', label: 'nrocontrato'},
                { field: 'valorinicial', label: 'valorinicial'},
                { field: 'valorpendiente', label: 'valorpendiente'}
            ],
            ejecuciones: [],
            datoDia: [],
            datoDiaColumns: [
                { field: 'FAMILIAS', label: 'Familias' },
                { field: 'PERSONAS', label: 'Personas' }
            ],
            albergueDXTitles: [
                { field: 'CODIGOFAMILIA', label: 'CODIGOFAMILIA' },
                { field: 'LUGARDECLARACION', label: 'LUGARDECLARACION' },
                { field: 'FECHAINGRESO', label: 'FECHAINGRESO' },
                { field: 'dx_fecha', label: 'dx_fecha' },
                { field: 'TIPODOCUMENTO', label: 'TIPODOCUMENTO' },
                { field: 'DOCUMENTO', label: 'DOCUMENTO' },
                { field: 'PRIMERNOMBRE', label: 'PRIMERNOMBRE' },
                { field: 'SEGUNDONOMBRE', label: 'SEGUNDONOMBRE' },
                { field: 'PRIMERAPELLIDO', label: 'PRIMERAPELLIDO' },
                { field: 'SEGUNDOAPELLIDO', label: 'SEGUNDOAPELLIDO' },
                { field: 'SEXO', label: 'SEXO' },
                { field: 'FECHASALIDA', label: 'FECHASALIDA' },
                { field: 'NUCLEOVIVANTO', label: 'NUCLEOVIVANTO' },
                { field: 'NUCLEOINGRESO', label: 'NUCLEOINGRESO' },
                { field: 'departamento', label: 'departamento' },
                { field: 'municipio', label: 'municipio' },
                { field: 'ENTORNO', label: 'ENTORNO' },
                { field: 'COMUNA', label: 'COMUNA' },
                { field: 'vict_4_4_3__subregion_antioquia', label: 'vict_4_4_3__subregion_antioquia' },
                { field: 'DESPLAZAMIENTO', label: 'DESPLAZAMIENTO' },
                { field: 'AMENAZA', label: 'AMENAZA' },
                { field: 'HOMICIDIO', label: 'HOMICIDIO' },
                { field: 'RECLUTAMIENTOFORZADO', label: 'RECLUTAMIENTOFORZADO' },
                { field: 'DESPOJOFORZADODETIERRAS', label: 'DESPOJOFORZADODETIERRAS' },
                { field: 'ACTOSTERRORISTA', label: 'ACTOSTERRORISTA' },
                { field: 'DELITOSCONTRALIBERTADSEXUAL', label: 'DELITOSCONTRALIBERTADSEXUAL' },
                { field: 'SECUESTRO', label: 'SECUESTRO' },
                { field: 'DESPARICIONFORZADA', label: 'DESPARICIONFORZADA' },
                { field: 'LESIONESPERSONALES', label: 'LESIONESPERSONALES' },
                { field: 'MINASANTIPERSONA', label: 'MINASANTIPERSONA' },
                { field: 'TORTURA', label: 'TORTURA' },
                { field: 'MASACRE', label: 'MASACRE' },
                { field: 'HOSTIGAMIENTO', label: 'HOSTIGAMIENTO' },
                { field: 'VIOLENCIADEGENERO', label: 'VIOLENCIADEGENERO' },
                { field: 'OTRAALTAVULNERABILIDADREMISIONES', label: 'OTRAALTAVULNERABILIDADREMISIONES' },
                { field: 'RESPONSABLE', label: 'RESPONSABLE' },
                { field: 'ESTADOVICTIMA', label: 'ESTADOVICTIMA' },
                { field: 'FECHAESTADO', label: 'FECHAESTADO' },
                { field: 'FUD_FICHA', label: 'FUD_FICHA' },
                { field: 'ENVIADOVALORACION', label: 'ENVIADOVALORACION' }
            ],
            costoAlbergue: 0,
            fechaInicio: '',
            fechaFin: '',
            minDate: '2019-07-01',
            maxDate: new Date(),
            costosAlbergueFields: [
                { field: 'DECLARAID', label: 'DECLARAID'},
                { field: 'TIPODOCUMENTO', label: 'TIPODOCUMENTO'},
                { field: 'DOCUMENTO', label: 'DOCUMENTO'},
                { field: 'NOMBRES', label: 'NOMBRES'},
                { field: 'APELLIDOS', label: 'APELLIDOS'},
                { field: 'PARENTESCO', label: 'PARENTESCO'},
                { field: 'FECHAINGRESO', label: 'FECHAINGRESO'},
                { field: 'HORAINGRESO', label: 'HORAINGRESO'},
                { field: 'FECHASALIDA', label: 'FECHASALIDA'},
                { field: 'HORASALIDA', label: 'HORASALIDA'},
                { field: 'COSTOALBERGUE', label: 'COSTOALBERGUE'}
            ],
            costosAlbergue: [],
            contrato: '',
            contratosFields: [
                { field: 'id', label: 'id'},
                { field: 'NOMBREALBERGUE', label: 'NOMBREALBERGUE'},
                { field: 'NROCONTRATO', label: 'NROCONTRATO'},
                { field: 'FECHAINICIO', label: 'FECHAINICIO'},
                { field: 'FECHAFIN', label: 'FECHAFIN'},
                { field: 'VALORCONTRATO', label: 'VALORCONTRATO'},
                { field: 'VALORCUPODIA', label: 'VALORCUPODIA'},
                { field: 'PERCCUPONOUTIL', label: 'PERCCUPONOUTIL'},
                { field: 'OBSERVACION', label: 'OBSERVACION'}
            ],
            contratos: [],
            ingresosFechasData: [],
            ingresosFechasDataFields: [
                {field: 'DECLARAID', label: 'DECLARAID'},
                {field: 'TIPODOCUMENTO', label: 'TIPODOCUMENTO'},
                {field: 'DOCUMENTO', label: 'DOCUMENTO'},
                {field: 'NOMBRES', label: 'NOMBRES'},
                {field: 'APELLIDOS', label: 'APELLIDOS'},
                {field: 'PARENTESCO', label: 'PARENTESCO'},
                {field: 'FECHAINGRESO', label: 'FECHAINGRESO'}
            ],
            salidasFechasDataFields: [
                { field: 'DECLARAID', label: 'DECLARAID'},
                { field: 'TIPODOCUMENTO', label: 'TIPODOCUMENTO'},
                { field: 'DOCUMENTO', label: 'DOCUMENTO'},
                { field: 'NOMBRES', label: 'NOMBRES'},
                { field: 'APELLIDOS', label: 'APELLIDOS'},
                { field: 'PARENTESCO', label: 'PARENTESCO'},
                { field: 'FECHASALIDA', label: 'FECHASALIDA'},
                { field: '', label: ''},
                
            ],
            salidasFechasData: [],
            costoTotal: 0,
            striped: true,
            bordered: true,
            stickyHeader: "800px",
            alberguedx: [],
            fechaCupodia: moment().format("YYYY-MM-DD")
        }
    },
    methods: {
        menu: function(menu){
            switch (menu) {
                case 'DATODIA':
                    this.showDatodia = true
                    this.showPresupuestal = false
                    this.showIngresosFechas = false
                    this.showSalidasFechas = false
                    this.showAlberguedx = false
                    this.showEjecucionfecha = false
                    break;
                case 'PRESUPUESTAL':
                    this.showDatodia = false
                    this.showPresupuestal = true
                    this.showIngresosFechas = false
                    this.showSalidasFechas = false
                    this.showAlberguedx = false
                    this.showEjecucionfecha = false
                    break;
                case 'INGRESOSFECHAS':
                    this.showDatodia = false
                    this.showPresupuestal = false
                    this.showIngresosFechas = true
                    this.showSalidasFechas = false
                    this.showAlberguedx = false
                    this.showEjecucionfecha = false
                    break;
                case 'SALIDASFECHAS':
                    this.showDatodia = false
                    this.showPresupuestal = false
                    this.showIngresosFechas = false
                    this.showSalidasFechas = true
                    this.showAlberguedx = false
                    this.showEjecucionfecha = false
                    break;
                case 'ALBERGUEDX':
                    this.showDatodia = false
                    this.showPresupuestal = false
                    this.showIngresosFechas = false
                    this.showSalidasFechas = false
                    this.showAlberguedx = true
                    this.showEjecucionfecha = false
                    break;
                case 'ESTADOPRESUPUESTO':
                    this.getEstadoEjecucion()
                    this.showDatodia = false
                    this.showEjecucionfecha = true
                    this.showPresupuestal = false
                    this.showIngresosFechas = false
                    this.showSalidasFechas = false
                    this.showAlberguedx = false
                    break;
                default:
                    break;
            }
        },
        generarInformeMensual: function () {
            this.token = localStorage.getItem('token');
            let url = store.getters['auth/urlApi'];
            const params = new URLSearchParams()
            params.append('contrato', this.contrato)
            params.append('fechaInicial', moment(this.fechaInicio).format("YYYY-MM-DD"))
            params.append('fechaFinal', moment(this.fechaFin).format("YYYY-MM-DD"))
            axios.post( url + 'setInformeMensual',
                params,
                {
                headers: {  
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${this.token}`
                }
            })
            .then((data) => {             
                alert(data.data.infmensual)             
            })
            .catch((error) => {
                this.iraLogin(error)
            })
        },
        getEstadoEjecucion: function() {
            this.token = localStorage.getItem('token');
            let url = store.getters['auth/urlApi'];
            const params = new URLSearchParams()
            params.append('fechaInicial', this.fechaInicio)
            params.append('fechaFinal', this.fechaFin)
            axios.post( url + 'infmensual',
                params,
                {
                headers: {  
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${this.token}`
                }
            })
            .then((data) => {             
                console.log(data.data.data)   
                this.ejecuciones = data.data.data             
            })
            .catch((error) => {
                this.iraLogin(error)
            })
        },
        getDatoDia: function () {
            this.token = localStorage.getItem('token');
            let url = store.getters['auth/urlApi'];
            const params = new URLSearchParams()
            var fecha = moment(this.fechaCupodia).format("YYYY-MM-DD")
            params.append('FECHA', fecha)
            axios.post( url + 'albergueDia',
                params,
                {
                headers: {  
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${this.token}`
                }
            })
            .then((data) => {                
                this.datoDia = data.data.data
                
            })
            .catch((error) => {
                this.iraLogin(error)
            })


        },
        ingresosFechas: function () {
            this.token = localStorage.getItem('token');
            let url = store.getters['auth/urlApi'];
            axios.get( url + 'ingresosFechasAlbergue', 
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`          
                },
                params: { 
                    fechaInicial: moment(this.fechaInicio).format("YYYY-MM-DD"),
                    fechaFinal: moment(this.fechaFin).format("YYYY-MM-DD")
                }
            })
            .then((res) => {            
                this.ingresosFechasData = res.data.data                                
            })
            .catch((error) => {
                this.iraLogin(error)
            })
        },
        salidasFechas: function () {
            this.token = localStorage.getItem('token');
            let url = store.getters['auth/urlApi'];
            axios.get( url + 'salidasFechasAlbergue', 
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`          
                },
                params: { 
                    fechaInicial: moment(this.fechaInicio).format("YYYY-MM-DD"),
                    fechaFinal: moment(this.fechaFin).format("YYYY-MM-DD")
                }
            })
            .then((res) => {  
                console.log(res.data.data)          
                this.salidasFechasData = res.data.data                                
            })
            .catch((error) => {
                this.iraLogin(error)
            })
        },
        generarCostos: function () {
            this.token = localStorage.getItem('token');
            let url = store.getters['auth/urlApi'];
            axios.get( url + 'costoalberguefechas', 
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`          
                },
                params: { 
                    fechaInicial: moment(this.fechaInicio).format("YYYY-MM-DD"),
                    fechaFinal: moment(this.fechaFin).format("YYYY-MM-DD")
                }
            })
            .then((res) => {
                if(res.data.data.length == 0) {alert (res.data.message)}         
                this.costosAlbergue = res.data.data 
                this.costoTotal = res.data.costo                 
            })
            .catch((error) => {
                this.iraLogin(error)
            })
        },
        getAlbergueDx: function () {
            this.token = localStorage.getItem('token');
            let url = store.getters['auth/urlApi'];
            const params = new FormData()            
            var fechaInicio = moment(this.fechaInicio).format("YYYY-MM-DD HH:mm:ss")            
            var fechaFin = moment(this.fechaFin).format("YYYY-MM-DD HH:mm:ss")
            params.append('fechaInicio', fechaInicio)
            params.append('fechaFin', fechaFin)
            axios.post( url + 'alberguedx', params,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`          
                },
                
            })
            .then((res) => {
                if(res.data.data.length == 0) {alert (res.data.message)}         
                this.alberguedx = res.data.data                
            })
            .catch((error) => {
                this.iraLogin(error)
            })
        },
        getContratosAlbergue: function () {
            this.token = localStorage.getItem('token');
            let url = store.getters['auth/urlApi'];
            axios.get( url + 'contratoalbergue', 
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`          
                }
            })
            .then((res) => {                
                this.contratos = res.data.data                
            })
            .catch((error) => {
                this.iraLogin(error)
            })
        },
        csvExport(arrData) {
            let csvContent = "data:text/csv;charset=utf-8,";
            csvContent += [
                Object.keys(arrData[0]).join(";"),
                ...arrData.map(item => Object.values(item).join(";"))
            ]
                .join("\n")
                .replace(/(^\[)|(\]$)/gm, "");

            const data = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", data);
            link.setAttribute("download", "export.csv");
            link.click();
        }      
    },
    mounted () {
        this.getDatoDia()
        this.getContratosAlbergue()
        if(this.perfil == "1" || this.perfil == "2" || this.rol == "3"){
            this.perfilPermitido = true
        }        
    }
}
</script>