export default {
    tipoDocumentos: [
        { value: 'CC', text: 'CEDULA' },
        { value: 'TI', text: 'TARJETA IDENTIDAD' },
        { value: 'RC', text: 'REGISTRO CIVIL' },
        { value: 'PEP', text: 'PERMISO ESPECIAL PERMANENCIA' },
        { value: 'CE', text: 'CEDULA EXTRANJERIA' },
        { value: 'OTRO', text: 'OTRO' },
        { value: 'NS', text: 'NO SABE' }
    ],
    estadosVictima: [
        { value: '0', text: 'NO APLICA' },
        { value: 'V', text: 'VALORACION - VALORADO' },
        { value: 'I', text: 'INCLUIDO' },
        { value: 'N', text: 'NO INCLUIDO' }
    ],
    parentescos: [
        { value: 1, text: 'Jefe(a)' },
        { value: 2, text: 'Cónyuge o compañera' },
        { value: 3, text: 'Hijo(a)' },
        { value: 4, text: 'Nieto(a)' },
        { value: 5, text: 'Padre - Madre' },
        { value: 6, text: 'Hermano(a)' },
        { value: 7, text: 'Yerno - Nuera' },
        { value: 8, text: 'Abuelo(a)' },
        { value: 9, text: 'Suegro(a)' },
        { value: 10, text: 'Tío(a)' },
        { value: 11, text: 'Sobrino(a)' },
        { value: 12, text: 'Otro pariente' },
        { value: 13, text: 'Otro no pariente' },
        { value: 13, text: 'Cuñado(a)' },
    ],
    sedeAlbergues: [
        { value: 1, text: 'GENTE VIVA' }
    ],
    lugarDeclaracion: [
        { value: 'PERSONERIA DE MEDELLIN', text: 'PERSONERIA DE MEDELLIN' },
        { value: 'PRODURADURIA', text: 'PRODURADURIA' },
        { value: 'DEFENSORIA', text: 'DEFENSORIA' },
        { value: 'ALTA VULNERABILIDAD', text: 'ALTA VULNERABILIDAD' }
    ],
    generos: [
        { value: 'HOMBRE', text: 'HOMBRE'},
        { value: 'MUJER', text: 'MUJER'}
    ],
    lgtbi: [
        { value: 'NO', text: 'NO'},
        { value: 'SI', text: 'SI'}
    ],
    etnias: [
        { value: '1', text: '1 - Negro(a) / Afrocolombiano(a) / Palenquero(a).'},
        { value: '2', text: '2 - Indigena.'},
        { value: '3', text: '3 - Gitano(a)/ROM.'},
        { value: '4', text: '4 - Raizal archipielago de San Andres y Providencia.'},
        { value: '5', text: '5 - Ninguna de las anteriores.'},
        { value: '6', text: '6 - No Sabe/No Responde.'},
        { value: '7', text: '7 - Mestizo.'}
    ]
}