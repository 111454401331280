/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {Gatekeeper} from 'gatekeeper-client-sdk';
import store from '@/store';
const getError = (error: any) => {
    const message =
        (error &&
            error.response &&
            error.response.data &&
            error.response.data.message) ||
        'Failed';
    return new Error(message);
};

export const loginByAuth = async (login: string, password: string) => {
    try {
        const token = await Gatekeeper.loginByAuth(login, password); 
        return token;
    } catch (error: any) {
        throw getError(error);
    }
};

export const registerByAuth = async (email: string, password: string) => {
    try {
        const token = await Gatekeeper.registerByAuth(email, password);
        return token;
    } catch (error: any) {
        throw getError(error);
    }
};

export const loginByGoogle = async () => {
    try {
        const token = await Gatekeeper.loginByGoogle();
        return token;
    } catch (error: any) {
        throw getError(error);
    }
};

export const registerByGoogle = async () => {
    try {
        const token = await Gatekeeper.registerByGoogle();
        return token;
    } catch (error: any) {
        throw getError(error);
    }
};

export const loginByFacebook = async () => {
    try {
        const token = await Gatekeeper.loginByFacebook();
        return token;
    } catch (error: any) {
        throw getError(error);
    }
};

export const registerByFacebook = async () => {
    try {
        const token = await Gatekeeper.registerByFacebook();
        return token;
    } catch (error: any) {
        throw getError(error);
    }
};
export const getProfile = async () => {
    try {
        //const user = await Gatekeeper.getProfile();
        const user = {
            name: localStorage.getItem('userName'),
            perfil: localStorage.getItem('perfil'),
            id: localStorage.getItem('id'),
            cargo: localStorage.getItem('cargo'),
            entidad: localStorage.getItem('entidad'),
            rol: localStorage.getItem('rol'),
        };
        return user;
    } catch (error: any) {
        throw getError(error);
    }
};

export const getRol = (perfil: any) => {
    try {
        const token = localStorage.getItem('token');
        const url = store.getters['auth/urlApi'];
        let p = "";
        fetch ( url + 'rol/' + perfil, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(resp => resp.json())
        .then(data => {
            p = data.data.ROL;
        });
        return p;
        /* switch (perfil) {
            case 2:
                p = 'ADMINISTRADOR';
                break;
            case 22:
                p = 'ANALISTA';
                break;
            case 18:
                p = 'ALBERGUE';
                break;
            case 10:
                p = 'GESTION_SOCIAL';
                break;
            case 1:
                p = 'CONSULTA';
                break;
            default:
                p = perfil;
                break;
        } */        
    } catch (error: any) {
        throw getError(error);
    };
}
