export default {
    tipoIdentificacion: [],
    parentescos: [],
    estadosRuv: [],
    etnias: [],
    discapacidades: [],
    causasDiscapacidad: [],
    actividadesPersona: [],
    tiposEstudio: [],
    razonesNoEstudio: [],
    tiposSalud: [],
    razonesNoSalud: [],
    tiposOficios: [
        {value: "0", text: "DESEMPLEADO"},
        {value: "2", text: "ALBAÑIL"},
        {value: "3", text: "CARPINTERO"},
        {value: "4", text: "AMA DE CASA"},
        {value: "5", text: "CONDUCTOR"},
        {value: "6", text: "PINTOR"},
        {value: "7", text: "VENDEDOR"},
        {value: "8", text: "AGRICULTOR"},
        {value: "10", text: "CONSTRUCCIÓN"},
        {value: "11", text: "MODISTA"},
        {value: "13", text: "LOGISTA"},
        {value: "14", text: "ZAPATERO"},
        {value: "15", text: "OFICIOS VARIOS"},
        {value: "16", text: "RECICLADOR"},
        {value: "17", text: "ESTUDIANTE"},
        {value: "18", text: "EMPLEADA DOMESTICA"},
        {value: "19", text: "AGROPECUARIA"},
        {value: "20", text: "VIGILANTE"},
        {value: "21", text: "ESTILISTA"},
        {value: "22", text: "PANADERO"},
        {value: "23", text: "MECANICO"},
        {value: "24", text: "PLOMERO"},
        {value: "26", text: "GANADERO"},
        {value: "27", text: "NINGUNO"},
        {value: "28", text: "COMERCIANTE"},
        {value: "29", text: "MINERIA"},
        {value: "30", text: "PESCADOR"},
        {value: "31", text: "OTRO"}
    ],
    sino: [
        {value: "SI", text: "SI"},
        {value: "NO", text: "NO"}
    ],
    sexo: [
        {value: "Mujer", text: "Mujer"},
        {value: "Hombre", text: "Hombre"}
    ],
    meses: [
        {value: 0, text: "Cero"},
        {value: 1, text: "Uno"},
        {value: 2, text: "Dos"},
        {value: 3, text: "Tres"},
        {value: 4, text: "Cuatro"},
        {value: 5, text: "Cinco"},
        {value: 6, text: "Seis"},
        {value: 7, text: "Siete"},
        {value: 8, text: "Ocho"},
        {value: 9, text: "Nueve"}
    ],
    personaBase: {
        id: 0, 
        fecha_lactancia: "", 
        fecha_gestancia: "", 
        causa_discapacidad_id: 0, 
        idpersona: "", 
        tipoid: 0, 
        nombre1: "", 
        nombre2: " ", 
        apellido1: "", 
        apellido2: " ", 
        incluido: "", 
        oficioactual: 0, 
        anonaci: 1901, 
        mesnaci: 1, 
        dianaci: 1, 
        sexo: "", 
        estadocivil: 0, 
        tipodiscapacidad: 0, 
        tipominoria: 0, 
        gestante: false,
         mesesgestacion: 0, 
         fechacreacion: "", 
         lactante: false, 
         idparentesco: 0, 
         idcabezafamilia: "", 
         fecharegistrosipod: "1901-01-01", 
         nodeclaracion: "", 
         edad: 0, 
         msacudiente: "", 
         actactual: 0, 
         fechamodificacion: "", 
         tipo: 0, 
         asistiaescuela: "", 
         asisteescuela: "", 
         tipoestudio: 0,
         tipoestudioactual: 0, 
         ultimogrado: 0, 
         razonnoestudio: 0, 
         salud: 0, 
         razonnosalud: 0, 
         sisben: "", 
         leeescribe: "", 
         huella: "", 
         fallecido: false, 
         municipio_cedula: "", 
         lgtbi: false, 
         celular: "", 
         email: "", 
         nota: "", 
         fechaNacimiento: ""
    }
}