<template>
    <!-- Content Header (Page header) -->
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1 class="m-0">{{ $t('labels.atencion') }}</h1>
                </div>
                <!-- /.col -->
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item">
                            <router-link to="/">{{ $t('labels.home') }}</router-link>
                        </li>
                        <li class="breadcrumb-item active">Atenciones</li>
                    </ol>
                </div>
                <!-- /.col -->
            </div>
            <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <section class="content">
        <div class="container-fluid">          
            <div class="row">
                <div class="col-sm-4">
                    <form @submit.prevent="buscarFamilia">
                        <div class="card card-secondary">
                            <div class="card-header">
                                <h3 class="card-title">Buscar persona</h3>
                            </div>
                            <div class="card-body">
                                <div class="input-group input-group-sm">
                                    <input type="text" class="form-control" v-model="documento" maxlength="20" required>
                                    <span class="input-group-append">
                                    <button type="submit" class="btn btn-info btn-flat">Buscar</button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            
            
            <table-lite v-if="grupoFamiliar.length != 0 && personaAtencion.idpersona == ''"
                :is-slot-mode="true"
                :columns="columnasGrupoFamiliar"
                :rows="grupoFamiliar"> 
                <template v-slot:nombre="data">
                    {{data.value.nombre}}
                </template>
                <template v-slot:idpersona="data">
                    {{data.value.idpersona}}
                </template>
                <template v-slot:parentesco="data">
                    {{data.value.parentesco}}
                </template>
                <template v-slot:edad="data">
                    {{data.value.edad}}
                </template>
                <template v-slot:actions="data">
                    <button type="button" class="btn btn-block btn-default" @click="verformPersonaAtenciones(data.value)">Atender</button>
                </template>
            </table-lite>

            <div class="card card-secondary" v-if="personaAtencion.idpersona != ''">
                <div class="card-header">
                    <h3 class="card-">Atenciones {{ personaAtencion.nombre }} - {{ personaAtencion.edad }} años</h3>
                </div>
                <form @submit.prevent="regAtencion">
                    <div class="card-body">
                        <div class="from-group">
                            <label for="Atencion">Atención:</label>
                            <select v-model="personaAtencion.idayuda" class="custom-select" required>
                                <option v-for="atencion in listaAtenciones" 
                                    v-bind:key="atencion.idayuda"
                                    :value="atencion.idayuda">
                                    {{ atencion.ayuda }}
                                </option>
                            </select>
                        </div>
                        <div class="from-group">
                            <label for="sede">Sede:</label>
                            <select v-model="personaAtencion.sede" class="custom-select" required>
                                <option v-for="sede in sedes" 
                                    v-bind:key="sede.value"
                                    :value="sede.text">
                                    {{ sede.text }}
                                </option>
                            </select>
                        </div>
                        <div class="from-group">
                            <label for="observaciones">Observaciones:</label>
                            <textarea v-model="personaAtencion.observacion" class="form-control" rows="3" placeholder="Observaciones ..." required></textarea>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button v-if="!guardandoAtencion" class="btn btn-secondary" type="submit">Guardar</button>
                        <button v-else class="btn btn-secondary" disabled type="submit">Guardar</button>
                        <button class="btn btn-default float-right" @click="cancelarAtencion">Salir</button>
                    </div>
                </form>
            </div>

            <div class="table-responsive">
                <table-lite v-if="grupoFamiliar.length != 0 && personaAtencion.idpersona == ''"
                    :columns="columnasAyudasFamilia"
                    :rows="ayudasFamilia">
                </table-lite>
            </div>
            
        </div>
        <!-- /.container-fluid -->
    </section>
    <!-- /.content -->

</template>
<script>
import store from '@/store/index';
import TableLite from "vue3-table-lite";

export default {
    components: {
        "table-lite": TableLite
    },
    data() {
        return {
            guardandoAtencion: false,
            grupoFamiliar: [],
            token: '',
            url: '',
            userPerfil: '',
            searchTerm: '',
            columnasGrupoFamiliar: [
                { field: "nombre", label: "Nombre" },
                { field: "idpersona", label: "Documento" },
                { field: "parentesco", label: "Parentesco" },
                { field: "edad", label: "Edad" },
                { field: "actions", label: "Elegir"}
            ],
            columnasAyudasFamilia: [
                { field: "id", label: "Id" },
                { field: "cantidad", label: "Cantidad" },
                { field: "ayuda", label: "Ayuda" },
                { field: "integrante", label: "Nombre" },
                { field: "sede", label: "Sede"},
                { field: "atencion", label: "Atencion"},
                { field: "entidad", label: "Entidad"},
                { field: "fecha", label: "Fecha"},
                { field: "observaciones", label: "Observación"},
                { field: "creadopor", label: "Creado por"}                
            ],
            personaAtencion: {
                idayuda: '',
                idpersona: '',
                edad: '',
                nombre: '',
                entidad: '',
                atencion: '',
                fecha: '',
                cantidad: '',
                sede: '',
                observacion: '',
                username: '',
            },
            listaAtenciones: [],
            documento: '',
            sedes: [
                { text: 'Belencito', value: '0' },
                { text: 'Caunces', value: '1' },
                { text: 'Juanes de la Paz', value: '2' },
                { text: 'UPJ', value: '3' },
                { text: 'Albergue', value: '4' }   
            ],
            ayudasFamilia: []
        }
    },
    mounted () {
        this.token = localStorage.getItem('token');
        this.url = store.getters['auth/urlApi'];
        this.userPerfil = localStorage.getItem('userPerfil');
               
        fetch( this.url + 'getayudasrol', {
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-Type' : 'application/json',
                'Authorization' : `Bearer ${this.token}`
            },
            body: JSON.stringify({
                USERROL: this.userPerfil
            })
        })
        .then( resp => resp.json())
        .then( data => {
            this.listaAtenciones = Object.values(data);
        })
    },
    methods: {
        regAtencion: function () {
            const fecha = new Date().toISOString().slice(0, 10);
            this.guardandoAtencion = true;
            setTimeout(() => {
                this.guardandoAtencion = false;
            }, 5000);

            fetch( this.url + 'saveatencion', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
                body: JSON.stringify ({
                    IDAYUDA: this.personaAtencion.idayuda,
                    IDPERSONA: this.personaAtencion.idpersona,
                    FECHA: fecha,
                    IDENTIDAD: 1,
                    USERNAME: localStorage.getItem('login'),
                    CANTIDAD: 1,
                    OBSERVACIONES: this.personaAtencion.observacion,
                    VALOR: 0,
                    SEDE: this.personaAtencion.sede,
                })
            })
            .then(resp => resp.json())
            .then(data => {
                alert(data.mensaje);
            });
            return false;
        },
        cancelarAtencion: function() {            
            this.personaAtencion.idayuda = '';
            this.personaAtencion.idpersona = '';
            this.personaAtencion.nombre = '';
            this.personaAtencion.entidad = '';
            this.personaAtencion.atencion = '';
            this.personaAtencion.fecha = '';
            this.personaAtencion.cantidad = '';
            this.personaAtencion.sede = '';
            this.personaAtencion.observacion = '';
            this.personaAtencion.username = '';
        },
        buscarAyudasFamilia: function () {
            fetch( this.url + 'getatencionesfamilia', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
                body: JSON.stringify ({
                    DOCUMENTO: this.documento
                })
            })
            .then(resp => resp.json())
            .then(data => {                
                this.ayudasFamilia = Object.values(data);                
            });
        },
        buscarFamilia: function() {                   
            fetch( this.url + 'getgrupofamiliar', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
                body: JSON.stringify ({
                    DOCUMENTO: this.documento
                })
            })
            .then(resp => resp.json())
            .then(data => {
                this.cancelarAtencion()
                this.grupoFamiliar = Object.values(data);
                this.buscarAyudasFamilia();
            });
        },
        verformPersonaAtenciones: function(data) {
            this.personaAtencion.nombre = data.nombre;
            this.personaAtencion.idpersona = data.idpersona;
            this.personaAtencion.edad = data.edad;
            this.personaAtencion.sede = 'Belencito';
            this.personaAtencion.idayuda = 0;
        }
    }
};
</script>
