import { getListaTipo } from '@/pages/helpers.js';
<template>
    <div>
        <div class="container">
            <b-button v-b-toggle.collapse-2016 variant="secondary">2016</b-button>
            <b-button v-b-toggle.collapse-2017 variant="secondary">2017</b-button>
            <b-button v-b-toggle.collapse-2018 variant="secondary">2018</b-button>
            <b-button v-b-toggle.collapse-2019 variant="secondary">2019</b-button>
            <b-button v-b-toggle.collapse-2020 variant="secondary">2020</b-button>
            <b-button v-b-toggle.collapse-2021 variant="secondary">2021</b-button>
            <b-collapse id="collapse-2016" class="mt-2">
                <b-card>
                    <div class="row" style="text-align:center;">
                        <h4>Atencion Humanitaria Inmediata</h4>
                    </div>
                    <div class="row">
                        <h5>Informe entrega PAQUETE DE ALIMENTOS año 2016 
                            <a style="cursor:pointer;" @click="exportar(info2016AHIAlimentos, 'info2016AHIAlimentos')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2016AHIAlimentos"></b-table>
                    </div>
                    <div class="row">
                        <h5>Informe entrega KIT DE HABITAT año 2016 
                            <a style="cursor:pointer;" @click="exportar(info2016AHIKits, 'info2016AHIKits')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2016AHIKits"></b-table>
                    </div>
                    <div class="row">
                        <h5>Informe entrega TEMPORALIDAD POR BANCO DE BOGOTA año 2016 
                            <a style="cursor:pointer;" @click="exportar(info2016AHIBancoTemp, 'info2016AHIBancoTemp')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2016AHIBancoTemp"></b-table>
                    </div>
                    <div class="row">
                        <h5>Informe entrega TEMPORALIDAD POR FONDO DE EMERGENCIA año 2016 
                            <a style="cursor:pointer;" @click="exportar(info2016AHIFondoTemp, 'info2016AHIFondoTemp')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2016AHIFondoTemp"></b-table>
                    </div>
                    <div class="row" style="text-align:center;">
                        <h4>ALTA VULNERABILIDAD</h4>
                    </div>
                    <div class="row">
                        <h5>Informe entrega PAQUETE DE ALIMENTOS año 2016 
                            <a style="cursor:pointer;" @click="exportar(info2016AVAlimentos, 'info2016AVAlimentos')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2016AVAlimentos"></b-table>
                    </div>
                    <div class="row">
                        <h5>Informe entrega KIT DE HABITAT año 2016 
                            <a style="cursor:pointer;" @click="exportar(info2016AVKits, 'info2016AVKits')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2016AVKits"></b-table>
                    </div>
                    <div class="row">
                        <h5>Informe entrega TEMPORALIDAD POR BANCO DE BOGOTA año 2016 
                            <a style="cursor:pointer;" @click="exportar(info2016AVBancoTemp, 'info2016AVBancoTemp')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2016AVBancoTemp"></b-table>
                    </div>
                    <div class="row">
                        <h5>Informe entrega TEMPORALIDAD POR FONDO DE EMERGENCIA año 2016 
                            <a style="cursor:pointer;" @click="exportar(info2016AVFondoTemp, 'info2016AVFondoTemp')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2016AVFondoTemp"></b-table>
                    </div>
                </b-card>
            </b-collapse>
            <b-collapse id="collapse-2017" class="mt-2">
                <b-card>
                    <div class="row" style="text-align:center;">
                        <h4>Atencion Humanitaria Inmediata</h4>
                    </div>
                    <div class="row">
                        <h5>Informe entrega PAQUETE DE ALIMENTOS año 2017
                            <a style="cursor:pointer;" @click="exportar(info2017AHIAlimentos, 'info2017AHIAlimentos')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2017AHIAlimentos"></b-table>
                    </div>
                    <div class="row">
                        <h5>Informe entrega KIT DE HABITAT año 2017 
                            <a style="cursor:pointer;" @click="exportar(info2017AHIKits, 'info2017AHIKits')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2017AHIKits"></b-table>
                    </div>
                    <div class="row">
                        <h5>Informe entrega TEMPORALIDAD POR BANCO DE BOGOTA año 2017 
                            <a style="cursor:pointer;" @click="exportar(info2017AHIBancoTemp, 'info2017AHIBancoTemp')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2017AHIBancoTemp"></b-table>
                    </div>
                    <div class="row">
                        <h5>Informe entrega TEMPORALIDAD POR FONDO DE EMERGENCIA año 2017 
                            <a style="cursor:pointer;" @click="exportar(info2017AHIFondoTemp, 'info2017AHIFondoTemp')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2017AHIFondoTemp"></b-table>
                    </div>
                    <div class="row" style="text-align:center;">
                        <h4>ALTA VULNERABILIDAD</h4>
                    </div>
                    <div class="row">
                        <h5>Informe entrega PAQUETE DE ALIMENTOS año 2017 
                            <a style="cursor:pointer;" @click="exportar(info2017AVAlimentos, 'info2017AVAlimentos')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2017AVAlimentos"></b-table>
                    </div>
                    <div class="row">
                        <h5>Informe entrega KIT DE HABITAT año 2017
                            <a style="cursor:pointer;" @click="exportar(info2017AVKits, 'info2017AVKits')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2017AVKits"></b-table>
                    </div>
                    <div class="row">
                        <h5>Informe entrega TEMPORALIDAD POR BANCO DE BOGOTA año 2017 
                            <a style="cursor:pointer;" @click="exportar(info2017AVBancoTemp, 'info2017AVBancoTemp')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2017AVBancoTemp"></b-table>
                    </div>
                    <div class="row">
                        <h5>Informe entrega TEMPORALIDAD POR FONDO DE EMERGENCIA año 2017 
                            <a style="cursor:pointer;" @click="exportar(info2017AVFondoTemp, 'info2017AVFondoTemp')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2017AVFondoTemp"></b-table>
                    </div>
                </b-card>
            </b-collapse>
            <b-collapse id="collapse-2018" class="mt-2">
                <b-card>
                    <div class="row" style="text-align:center;">
                        <h4>Atencion Humanitaria Inmediata</h4>
                    </div>
                    <div class="row">
                        <h5>Informe entrega PAQUETE DE ALIMENTOS año 2018
                            <a style="cursor:pointer;" @click="exportar(info2018AHIAlimentos, 'info2018AHIAlimentos')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2018AHIAlimentos"></b-table>
                    </div>
                    <div class="row">
                        <h5>Informe entrega KIT DE HABITAT año 2018 
                            <a style="cursor:pointer;" @click="exportar(info2018AHIKits, 'info2018AHIKits')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2018AHIKits"></b-table>
                    </div>
                    <div class="row">
                        <h5>Informe entrega TEMPORALIDAD POR BANCO DE BOGOTA año 2018 
                            <a style="cursor:pointer;" @click="exportar(info2018AHIBancoTemp, 'info2018AHIBancoTemp')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2018AHIBancoTemp"></b-table>
                    </div>
                    <div class="row">
                        <h5>Informe entrega TEMPORALIDAD POR FONDO DE EMERGENCIA año 2018 
                            <a style="cursor:pointer;" @click="exportar(info2018AHIFondoTemp, 'info2018AHIFondoTemp')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2018AHIFondoTemp"></b-table>
                    </div>
                    <div class="row" style="text-align:center;">
                        <h4>ALTA VULNERABILIDAD</h4>
                    </div>
                    <div class="row">
                        <h5>Informe entrega PAQUETE DE ALIMENTOS año 2018
                            <a style="cursor:pointer;" @click="exportar(info2018AVAlimentos, 'info2018AVAlimentos')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2018AVAlimentos"></b-table>
                    </div>
                    <div class="row">
                        <h5>Informe entrega KIT DE HABITAT año 2018
                            <a style="cursor:pointer;" @click="exportar(info2018AVKits, 'info2018AVKits')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2018AVKits"></b-table>
                    </div>
                    <div class="row">
                        <h5>Informe entrega TEMPORALIDAD POR BANCO DE BOGOTA año 2018 
                            <a style="cursor:pointer;" @click="exportar(info2018AVBancoTemp, 'info2018AVBancoTemp')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2018AVBancoTemp"></b-table>
                    </div>
                    <div class="row">
                        <h5>Informe entrega TEMPORALIDAD POR FONDO DE EMERGENCIA año 2018
                            <a style="cursor:pointer;" @click="exportar(info2018AVFondoTemp, 'info2018AVFondoTemp')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2018AVFondoTemp"></b-table>
                    </div>
                </b-card>
            </b-collapse>
            <b-collapse id="collapse-2019" class="mt-2">
                <b-card>
                    <div class="row" style="text-align:center;">
                        <h4>Atencion Humanitaria Inmediata</h4>
                    </div>
                    <div class="row">
                        <h5>Informe entrega PAQUETE DE ALIMENTOS año 2019
                            <a style="cursor:pointer;" @click="exportar(info2019AHIAlimentos, 'info2019AHIAlimentos')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2019AHIAlimentos"></b-table>
                    </div>
                    <div class="row">
                        <h5>Informe entrega KIT DE HABITAT año 2019 
                            <a style="cursor:pointer;" @click="exportar(info2019AHIKits, 'info2019AHIKits')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2019AHIKits"></b-table>
                    </div>
                    <div class="row">
                        <h5>Informe entrega TEMPORALIDAD POR BANCO DE BOGOTA año 2019 
                            <a style="cursor:pointer;" @click="exportar(info2019AHIBancoTemp, 'info2019AHIBancoTemp')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2019AHIBancoTemp"></b-table>
                    </div>
                    <div class="row">
                        <h5>Informe entrega TEMPORALIDAD POR FONDO DE EMERGENCIA año 2019 
                            <a style="cursor:pointer;" @click="exportar(info2019AHIFondoTemp, 'info2019AHIFondoTemp')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2019AHIFondoTemp"></b-table>
                    </div>
                    <div class="row" style="text-align:center;">
                        <h4>ALTA VULNERABILIDAD</h4>
                    </div>
                    <div class="row">
                        <h5>Informe entrega PAQUETE DE ALIMENTOS año 2019
                            <a style="cursor:pointer;" @click="exportar(info2019AVAlimentos, 'info2019AVAlimentos')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2019AVAlimentos"></b-table>
                    </div>
                    <div class="row">
                        <h5>Informe entrega KIT DE HABITAT año 2018
                            <a style="cursor:pointer;" @click="exportar(info2019AVKits, 'info2019AVKits')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2019AVKits"></b-table>
                    </div>
                    <div class="row">
                        <h5>Informe entrega TEMPORALIDAD POR BANCO DE BOGOTA año 2019 
                            <a style="cursor:pointer;" @click="exportar(info2019AVBancoTemp, 'info2019AVBancoTemp')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2019AVBancoTemp"></b-table>
                    </div>
                    <div class="row">
                        <h5>Informe entrega TEMPORALIDAD POR FONDO DE EMERGENCIA año 2019
                            <a style="cursor:pointer;" @click="exportar(info2019AVFondoTemp, 'info2019AVFondoTemp')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2019AVFondoTemp"></b-table>
                    </div>
                </b-card>
            </b-collapse>
            <b-collapse id="collapse-2020" class="mt-2">
                <b-card>
                    <div class="row" style="text-align:center;">
                        <h4>Atencion Humanitaria Inmediata</h4>
                    </div>
                    <div class="row">
                        <h5>Informe entrega PAQUETE DE ALIMENTOS año 2020
                            <a style="cursor:pointer;" @click="exportar(info2020AHIAlimentos, 'info2020AHIAlimentos')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2020AHIAlimentos"></b-table>
                    </div>
                    <div class="row">
                        <h5>Informe entrega KIT DE HABITAT año 2020 
                            <a style="cursor:pointer;" @click="exportar(info2020AHIKits, 'info2020AHIKits')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2020AHIKits"></b-table>
                    </div>
                    <div class="row">
                        <h5>Informe entrega TEMPORALIDAD POR BANCO DE BOGOTA año 2020 
                            <a style="cursor:pointer;" @click="exportar(info2020AHIBancoTemp, 'info2020AHIBancoTemp')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2020AHIBancoTemp"></b-table>
                    </div>
                    <div class="row">
                        <h5>Informe entrega TEMPORALIDAD POR FONDO DE EMERGENCIA año 2020 
                            <a style="cursor:pointer;" @click="exportar(info2020AHIFondoTemp, 'info2020AHIFondoTemp')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2020AHIFondoTemp"></b-table>
                    </div>
                    <div class="row" style="text-align:center;">
                        <h4>ALTA VULNERABILIDAD</h4>
                    </div>
                    <div class="row">
                        <h5>Informe entrega PAQUETE DE ALIMENTOS año 2020
                            <a style="cursor:pointer;" @click="exportar(info2020AVAlimentos, 'info2020AVAlimentos')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2020AVAlimentos"></b-table>
                    </div>
                    <div class="row">
                        <h5>Informe entrega KIT DE HABITAT año 2018
                            <a style="cursor:pointer;" @click="exportar(info2020AVKits, 'info2020AVKits')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2020AVKits"></b-table>
                    </div>
                    <div class="row">
                        <h5>Informe entrega TEMPORALIDAD POR BANCO DE BOGOTA año 2020 
                            <a style="cursor:pointer;" @click="exportar(info2020AVBancoTemp, 'info2020AVBancoTemp')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2020AVBancoTemp"></b-table>
                    </div>
                    <div class="row">
                        <h5>Informe entrega TEMPORALIDAD POR FONDO DE EMERGENCIA año 2020
                            <a style="cursor:pointer;" @click="exportar(info2020AVFondoTemp, 'info2020AVFondoTemp')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2020AVFondoTemp"></b-table>
                    </div>
                </b-card>
            </b-collapse>
            <b-collapse id="collapse-2021" class="mt-2">
                <b-card>
                    <div class="row" style="text-align:center;">
                        <h4>Atencion Humanitaria Inmediata</h4>
                    </div>
                    <div class="row">
                        <h5>Informe entrega PAQUETE DE ALIMENTOS año 2021
                            <a style="cursor:pointer;" @click="exportar(info2021AHIAlimentos, 'info2021AHIAlimentos')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2021AHIAlimentos"></b-table>
                    </div>
                    <div class="row">
                        <h5>Informe entrega KIT DE HABITAT año 2021 
                            <a style="cursor:pointer;" @click="exportar(info2021AHIKits, 'info2021AHIKits')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2021AHIKits"></b-table>
                    </div>
                    <div class="row">
                        <h5>Informe entrega TEMPORALIDAD POR BANCO DE BOGOTA año 2021 
                            <a style="cursor:pointer;" @click="exportar(info2021AHIBancoTemp, 'info2021AHIBancoTemp')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2021AHIBancoTemp"></b-table>
                    </div>
                    <div class="row">
                        <h5>Informe entrega TEMPORALIDAD POR FONDO DE EMERGENCIA año 2021 
                            <a style="cursor:pointer;" @click="exportar(info2021AHIFondoTemp, 'info2021AHIFondoTemp')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2021AHIFondoTemp"></b-table>
                    </div>
                    <div class="row" style="text-align:center;">
                        <h4>ALTA VULNERABILIDAD</h4>
                    </div>
                    <div class="row">
                        <h5>Informe entrega PAQUETE DE ALIMENTOS año 2021
                            <a style="cursor:pointer;" @click="exportar(info2021AVAlimentos, 'info2021AVAlimentos')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2021AVAlimentos"></b-table>
                    </div>
                    <div class="row">
                        <h5>Informe entrega KIT DE HABITAT año 2011
                            <a style="cursor:pointer;" @click="exportar(info2021AVKits, 'info2021AVKits')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2021AVKits"></b-table>
                    </div>
                    <div class="row">
                        <h5>Informe entrega TEMPORALIDAD POR BANCO DE BOGOTA año 2021 
                            <a style="cursor:pointer;" @click="exportar(info2021AVBancoTemp, 'info2021AVBancoTemp')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2021AVBancoTemp"></b-table>
                    </div>
                    <div class="row">
                        <h5>Informe entrega TEMPORALIDAD POR FONDO DE EMERGENCIA año 2021
                            <a style="cursor:pointer;" @click="exportar(info2021AVFondoTemp, 'info2021AVFondoTemp')"><span>Exportar</span></a>
                        </h5>
                        <b-table responsive striped hover :items="info2021AVFondoTemp"></b-table>
                    </div>
                </b-card>
            </b-collapse>
        </div>
    </div>
</template>
<script>
import {
    csvExport
    } from "@/pages/helpers.js"
export default {
    name: 'informe',
    data () {
        return {
            info2016AHIAlimentos: [
                {tipo: 'TIPO A', familias: '1150', personas: '1878', entregas: '1150', paquetes: '1237', valor: '88373137'},
                {tipo: 'TIPO B', familias: '1432', personas: '4964', entregas: '1432', paquetes: '1434', valor: '222903716'},
                {tipo: 'TIPO C', familias: '946', personas: '4024', entregas: '946', paquetes: '946', valor: '231322400'},
                {tipo: 'TIPO D', familias: '106', personas: '713', entregas: '106', paquetes: '106', valor: '33815166'},
                {tipo: 'TOTAL', familias: '3634', personas: '11579', entregas: '3634', paquetes: '3723', valor: ' 576414419'},                
            ],
            info2016AHIKits: [
                {tipo: 'TIPO A', familias: '583', personas: '837', entregas: '583', paquetes: '583', valor: '33740927'},
                {tipo: 'TIPO B', familias: '1145', personas: '4050', entregas: '1145', paquetes: '1145', valor: '125280175'},
                {tipo: 'TIPO C', familias: '235', personas: '1306', entregas: '235', paquetes: '235', valor: '43965758'},
                {tipo: 'TIPO D', familias: '49', personas: '338', entregas: '49', paquetes: '49', valor: '14113372'},
                {tipo: 'TOTAL', familias: '2012', personas: '6531', entregas: '2012', paquetes: '2012', valor: '217100232'},             
            ],
            info2016AHIBancoTemp: [
                {tipo: 'TIPO A', familias: '1009', personas: '1419', entregas: '1009', valor: '92010000'},
                {tipo: 'TIPO B', familias: '1347', personas: '4831', entregas: '1347', valor: '156240000'},
                {tipo: 'TIPO C', familias: '547', personas: '2662', entregas: '547', valor: '98780000'},
                {tipo: 'TIPO D', familias: '71', personas: '483', entregas: '71', valor: '18050000'},
                {tipo: 'TOTAL', familias: '2974', personas: '9395', entregas: '2974', valor: '365080000'},
            ],
            info2016AHIFondoTemp: [
                {tipo: 'TIPO A', familias: '74', personas: '87', entregas: '74', valor: '7270000'},
                {tipo: 'TIPO B', familias: '39', personas: '111', entregas: '39', valor: '7500000'},
                {tipo: 'TIPO C', familias: '60', personas: '229', entregas: '60', valor: '12290000'},
                {tipo: 'TIPO D', familias: '7', personas: '46', entregas: '7', valor: '1700000'},
                {tipo: 'TOTAL', familias: '244', personas: '473', entregas: '180', valor: '$28760000'},
            ],
            info2016AVAlimentos: [
                {tipo: 'TIPO A', familias: '242', personas: '519', entregas: '242', paquetes: '242', valor: '19054094'},
                {tipo: 'TIPO B', familias: '435', personas: '1631', entregas: '435', paquetes: '435', valor: '67535801'},
                {tipo: 'TIPO C', familias: '210', personas: '1091', entregas: '210', paquetes: '210', valor: '50090735'},
                {tipo: 'TIPO D', familias: '23', personas: '157', entregas: '23', paquetes: '23', valor: '7337253'},
                {tipo: 'TOTAL', familias: '910', personas: '3398', entregas: '910', paquetes: '910', valor: '144017883'},
            ],
            info2016AVKits: [
                {tipo: 'TIPO A', familias: '1', personas: '1', entregas: '1', paquetes: '1', valor: '67520'},
                {tipo: 'TIPO B', familias: '2', personas: '6', entregas: '2', paquetes: '2', valor: '303670'},
                {tipo: 'TIPO C', familias: '1', personas: '6', entregas: '1', paquetes: '1', valor: '226240'},
                {tipo: 'TIPO D', familias: '0', personas: '0', entregas: '0', paquetes: '0', valor: '0'},
                {tipo: 'TOTAL', familias: '4', personas: '13', entregas: '4', paquetes: '4', valor: ' 597430'},
            ],
            info2016AVBancoTemp: [
                {tipo: 'TIPO A', familias: '87', personas: '137', entregas: '87', valor: '7850000'},
                {tipo: 'TIPO B', familias: '160', personas: '590', entregas: '160', valor: '17720000'},
                {tipo: 'TIPO C', familias: '80', personas: '427', entregas: '80', valor: '13560000 '},
                {tipo: 'TIPO D', familias: '6', personas: '45', entregas: '6', valor: '1750000 '},
                {tipo: 'TOTAL', familias: '333', personas: '1199', entregas: '333', valor: '40880000'},
            ],
            info2016AVFondoTemp: [
                {tipo: 'TIPO A', familias: '0', personas: '0', entregas: '0', valor: '0'},
                {tipo: 'TIPO B', familias: '0', personas: '0', entregas: '0', valor: '0'},
                {tipo: 'TIPO C', familias: '0', personas: '0', entregas: '0', valor: '0'},
                {tipo: 'TIPO D', familias: '0', personas: '0', entregas: '0', valor: '0'},
                {tipo: 'TOTAL', familias: '0', personas: '0', entregas: '0', valor: '0'},
            ],
            info2017AHIAlimentos: [
            {tipo: 'TIPO A', familias: '550', personas: '632', entregas: '550', paquetes: '550', valor: '41715624'},
            {tipo: 'TIPO B', familias: '664', personas: '1652', entregas: '664', paquetes: '664', valor: '102820159'},
            {tipo: 'TIPO C', familias: '1575', personas: '5894', entregas: '1575', paquetes: '1575', valor: '376416740'},
            {tipo: 'TIPO D', familias: '287', personas: '1958', entregas: '287', paquetes: '287', valor: '88708669'},
            {tipo: 'TOTAL', familias: '3076', personas: '10136', entregas: '3076', paquetes: '3076', valor: '609661192'},
            ],
            info2017AHIKits: [
            {tipo: 'TIPO A', familias: '335', personas: '336', entregas: '335', paquetes: '335', valor: '34220390'},
            {tipo: 'TIPO B', familias: '364', personas: '728', entregas: '364', paquetes: '364', valor: '57453695'},
            {tipo: 'TIPO C', familias: '1069', personas: '3955', entregas: '1069', paquetes: '1069', valor: '233200750'},
            {tipo: 'TIPO D', familias: '207', personas: '1396', entregas: '207', paquetes: '2807', valor: '69735790'},
            {tipo: 'TOTAL', familias: '1975', personas: '6415', entregas: '1975', paquetes: '4575', valor: '394610625'},
            ],
            info2017AHIBancoTemp: [
            {tipo: 'TIPO A', familias: '489', personas: '489', entregas: '489', valor: '44010000'},
            {tipo: 'TIPO B', familias: '437', personas: '874', entregas: '437', valor: '65550000'},
            {tipo: 'TIPO C', familias: '1411', personas: '5235', entregas: '1411', valor: '285550000'},
            {tipo: 'TIPO D', familias: '260', personas: '1769', entregas: '260', valor: '67400000'},
            {tipo: 'TOTAL', familias: '2597', personas: '8367', entregas: '2597', valor: '462510000'},
            ],
            info2017AHIFondoTemp: [
            {tipo: 'TIPO A', familias: '155', personas: '156', entregas: '155', valor: '17020000'},
            {tipo: 'TIPO B', familias: '114', personas: '228', entregas: '114', valor: '17190000'},
            {tipo: 'TIPO C', familias: '323', personas: '1201', entregas: '323', valor: '67260000'},
            {tipo: 'TIPO D', familias: '62', personas: '431', entregas: '62', valor: '16750000'},
            {tipo: 'TOTAL', familias: '654', personas: '2016', entregas: '654', valor: '118220000'},
            ],
            info2017AVAlimentos: [
            {tipo: 'TIPO A', familias: '38', personas: '47', entregas: '38', paquetes: '38', valor: '2871450'},
            {tipo: 'TIPO B', familias: '98', personas: '238', entregas: '98', paquetes: '98', valor: '15103355'},
            {tipo: 'TIPO C', familias: '223', personas: '879', entregas: '223', paquetes: '223', valor: '53206180'},
            {tipo: 'TIPO D', familias: '40', personas: '257', entregas: '40', paquetes: '40', valor: '12371576'},
            {tipo: 'TOTAL', familias: '399', personas: '1421', entregas: '399', paquetes: '399', valor: '83552561'},
            ],
            info2017AVKits: [
            {tipo: 'TIPO A', familias: '0', personas: '0', entregas: '0', paquetes: '0', valor: '0'},
            {tipo: 'TIPO B', familias: '2', personas: '4', entregas: '2', paquetes: '2', valor: '286365'},
            {tipo: 'TIPO C', familias: '3', personas: '15', entregas: '3', paquetes: '3', valor: '710160'},
            {tipo: 'TIPO D', familias: '0', personas: '0', entregas: '0', paquetes: '0', valor: '0'},
            {tipo: 'TOTAL', familias: '5', personas: '19', entregas: '5', paquetes: '5', valor: '996525'},
            ],
            info2017AVBancoTemp: [
            {tipo: 'TIPO A', familias: '29', personas: '29', entregas: '29', valor: '2610000'},
            {tipo: 'TIPO B', familias: '31', personas: '62', entregas: '31', valor: '4650000'},
            {tipo: 'TIPO C', familias: '106', personas: '398', entregas: '106', valor: '21200000'},
            {tipo: 'TIPO D', familias: '26', personas: '174', entregas: '26', valor: '6500000'},
            {tipo: 'TOTAL', familias: '192', personas: '663', entregas: '192', valor: '34960000'},
            ],
            info2017AVFondoTemp: [
            {tipo: 'TIPO A', familias: '31', personas: '31', entregas: '31', valor: '4620000'},
            {tipo: 'TIPO B', familias: '46', personas: '92', entregas: '46', valor: '8650000'},
            {tipo: 'TIPO C', familias: '84', personas: '313', entregas: '84', valor: '18900000'},
            {tipo: 'TIPO D', familias: '26', personas: '171', entregas: '26', valor: '6800000'},
            {tipo: 'TOTAL', familias: '187', personas: '607', entregas: '187', valor: '38970000'},
            ],
            info2018AHIAlimentos: [
            {tipo: 'TIPO A', familias: '680', personas: '740', entregas: '680', paquetes: '680', valor: '58492537'},
            {tipo: 'TIPO B', familias: '625', personas: '1250', entregas: '625', paquetes: '625', valor: '108200239'},
            {tipo: 'TIPO C', familias: '1837', personas: '6898', entregas: '1837', paquetes: '1837', valor: '485714924'},
            {tipo: 'TIPO D', familias: '335', personas: '2325', entregas: '335', paquetes: '335', valor: '118654606'},
            {tipo: 'TOTAL', familias: '3477', personas: '11213', entregas: '3477', paquetes: '3477', valor: '771062306'},
            ],
            info2018AHIKits: [
            {tipo: 'TIPO A', familias: '365', personas: '408', entregas: '365', paquetes: '370', valor: '35828340'},
            {tipo: 'TIPO B', familias: '368', personas: '736', entregas: '368', paquetes: '368', valor: '64949261'},
            {tipo: 'TIPO C', familias: '1107', personas: '4244', entregas: '1107', paquetes: '1107', valor: '264305142'},
            {tipo: 'TIPO D', familias: '189', personas: '1311', entregas: '189', paquetes: '189', valor: '74316645'},
            {tipo: 'TOTAL', familias: '2029', personas: '6699', entregas: '2029', paquetes: '2034', valor: '439399388'},
            ],
            info2018AHIBancoTemp: [
            {tipo: 'TIPO A', familias: '552', personas: '552', entregas: '552', valor: '49680000'},
            {tipo: 'TIPO B', familias: '523', personas: '1046', entregas: '523', valor: '78450000'},
            {tipo: 'TIPO C', familias: '1413', personas: '5290', entregas: '1413', valor: '284900000'},
            {tipo: 'TIPO D', familias: '238', personas: '1638', entregas: '238', valor: '61150000'},
            {tipo: 'TOTAL', familias: '2726', personas: '8526', entregas: '2726', valor: '474180000'},
            ],
            info2018AHIFondoTemp: [
            {tipo: 'TIPO A', familias: '214', personas: '214', entregas: '214', valor: '20010000'},
            {tipo: 'TIPO B', familias: '159', personas: '318', entregas: '159', valor: '24480000'},
            {tipo: 'TIPO C', familias: '391', personas: '1470', entregas: '391', valor: '80890000'},
            {tipo: 'TIPO D', familias: '71', personas: '493', entregas: '71', valor: '19300000'},
            {tipo: 'TOTAL', familias: '835', personas: '2495', entregas: '835', valor: '144680000'},
            ],


            info2018AVAlimentos: [
            {tipo: 'TIPO A', familias: '47', personas: '56', entregas: '47', paquetes: '53', valor: '4147243'},
            {tipo: 'TIPO B', familias: '48', personas: '96', entregas: '48', paquetes: '48', valor: '7957945'},
            {tipo: 'TIPO C', familias: '181', personas: '710', entregas: '181', paquetes: '181', valor: '46670906'},
            {tipo: 'TIPO D', familias: '61', personas: '422', entregas: '61', paquetes: '61', valor: '20616561'},
            {tipo: 'TOTAL', familias: '337', personas: '1284', entregas: '337', paquetes: '343', valor: '79392655'},
            ],
            info2018AVKits: [
            {tipo: 'TIPO A', familias: '11', personas: '15', entregas: '11', paquetes: '11', valor: '1104142'},
            {tipo: 'TIPO B', familias: '3', personas: '6', entregas: '3', paquetes: '3', valor: '530850'},
            {tipo: 'TIPO C', familias: '5', personas: '22', entregas: '5', paquetes: '5', valor: '1272738'},
            {tipo: 'TIPO D', familias: '3', personas: '18', entregas: '3', paquetes: '3', valor: '1099054'},
            {tipo: 'TOTAL', familias: '22', personas: '61', entregas: '22', paquetes: '22', valor: '4006784'},
            ],
            info2018AVBancoTemp: [
            {tipo: 'TIPO A', familias: '44', personas: '44', entregas: '44', valor: '3960000'},
            {tipo: 'TIPO B', familias: '55', personas: '110', entregas: '55', valor: '8250000'},
            {tipo: 'TIPO C', familias: '166', personas: '662', entregas: '166', valor: '33200000'},
            {tipo: 'TIPO D', familias: '55', personas: '376', entregas: '55', valor: '13750000'},
            {tipo: 'TOTAL', familias: '320', personas: '1192', entregas: '320', valor: '59160000'},
            ],
            info2018AVFondoTemp: [
            {tipo: 'TIPO A', familias: '43', personas: '43', entregas: '43', valor: '5030000'},
            {tipo: 'TIPO B', familias: '31', personas: '62', entregas: '31', valor: '5100000'},
            {tipo: 'TIPO C', familias: '93', personas: '353', entregas: '93', valor: '19450000'},
            {tipo: 'TIPO D', familias: '22', personas: '160', entregas: '22', valor: '5150000'},
            {tipo: 'TOTAL', familias: '189', personas: '618', entregas: '189', valor: '34730000'},
            ],
            info2019AHIAlimentos: [
            {tipo: 'TIPO A', familias: '439', personas: '440', entregas: '439', paquetes: '439', valor: '38902210'},
            {tipo: 'TIPO B', familias: '504', personas: '1008', entregas: '504', paquetes: '504', valor: '93435864'},
            {tipo: 'TIPO C', familias: '1469', personas: '6004', entregas: '1469', paquetes: '1469', valor: '430936818'},
            {tipo: 'TIPO D', familias: '135', personas: '921', entregas: '135', paquetes: '135', valor: '48717600'},
            {tipo: 'TOTAL', familias: '2547', personas: '8373', entregas: '2547', paquetes: '2547', valor: '611992492'},
            ],
            info2019AHIKits: [
            {tipo: 'TIPO A', familias: '164', personas: '164', entregas: '164', paquetes: '164', valor: '20392580'},
            {tipo: 'TIPO B', familias: '245', personas: '490', entregas: '245', paquetes: '245', valor: '51716845'},
            {tipo: 'TIPO C', familias: '772', personas: '3137', entregas: '772', paquetes: '772', valor: '230856507'},
            {tipo: 'TIPO D', familias: '90', personas: '665', entregas: '90', paquetes: '90', valor: '51783386'},
            {tipo: 'TOTAL', familias: '1271', personas: '4456', entregas: '1271', paquetes: '1271', valor: '354749318'},
            ],
            info2019AHIBancoTemp: [
            {tipo: 'TIPO A', familias: '812', personas: '812', entregas: '812', valor: '72810000'},
            {tipo: 'TIPO B', familias: '713', personas: '1426', entregas: '713', valor: '106550000'},
            {tipo: 'TIPO C', familias: '1776', personas: '6625', entregas: '1776', valor: '354000000'},
            {tipo: 'TIPO D', familias: '323', personas: '2253', entregas: '323', valor: '79750000'},
            {tipo: 'TOTAL', familias: '3624', personas: '11116', entregas: '3624', valor: '613110000'},
            ],
            info2019AHIFondoTemp: [
            {tipo: 'TIPO A', familias: '156', personas: '156', entregas: '156', valor: '14590000'},
            {tipo: 'TIPO B', familias: '79', personas: '158', entregas: '79', valor: '12000000'},
            {tipo: 'TIPO C', familias: '151', personas: '562', entregas: '151', valor: '30340000'},
            {tipo: 'TIPO D', familias: '40', personas: '313', entregas: '40', valor: '9600000'},
            {tipo: 'TOTAL', familias: '426', personas: '1189', entregas: '426', valor: '66530000'},
            ],


            info2019AVAlimentos: [
            {tipo: 'TIPO A', familias: '0', personas: '0', entregas: '0', paquetes: '0', valor: '0'},
            {tipo: 'TIPO B', familias: '4', personas: '8', entregas: '4', paquetes: '4', valor: '751324'},
            {tipo: 'TIPO C', familias: '15', personas: '57', entregas: '14', paquetes: '14', valor: '4107332'},
            {tipo: 'TIPO D', familias: '1', personas: '7', entregas: '1', paquetes: '1', valor: '353744'},
            {tipo: 'TOTAL', familias: '20', personas: '72', entregas: '19', paquetes: '19', valor: '5212400'},
            ],
            info2019AVKits: [
            {tipo: 'TIPO A', familias: '0', personas: '0', entregas: '0', paquetes: '0', valor: '0'},
            {tipo: 'TIPO B', familias: '0', personas: '0', entregas: '0', paquetes: '0', valor: '0'},
            {tipo: 'TIPO C', familias: '1', personas: '3', entregas: '1', paquetes: '1', valor: '274594'},
            {tipo: 'TIPO D', familias: '0', personas: '0', entregas: '0', paquetes: '0', valor: '0'},
            {tipo: 'TOTAL', familias: '1', personas: '3', entregas: '1', paquetes: '1', valor: '274594'},
            ],
            info2019AVBancoTemp: [
            {tipo: 'TIPO A', familias: '18', personas: '18', entregas: '18', valor: '1620000'},
            {tipo: 'TIPO B', familias: '17', personas: '34', entregas: '17', valor: '2550000'},
            {tipo: 'TIPO C', familias: '62', personas: '239', entregas: '62', valor: '12400000'},
            {tipo: 'TIPO D', familias: '26', personas: '190', entregas: '26', valor: '6500000'},
            {tipo: 'TOTAL', familias: '123', personas: '481', entregas: '123', valor: '23070000'},
            ],
            info2019AVFondoTemp: [
            {tipo: 'TIPO A', familias: '6', personas: '6', entregas: '6', valor: '540000'},
            {tipo: 'TIPO B', familias: '7', personas: '14', entregas: '7', valor: '1050000'},
            {tipo: 'TIPO C', familias: '4', personas: '16', entregas: '4', valor: '8000000'},
            {tipo: 'TIPO D', familias: '2', personas: '12', entregas: '2', valor: '500000'},
            {tipo: 'TOTAL', familias: '19', personas: '48', entregas: '19', valor: '10090000'},
            ],
            info2020AHIAlimentos: [
            {tipo: 'TIPO A', familias: '384', personas: '384', entregas: '384', paquetes: '384', valor: '27747849'},
            {tipo: 'TIPO B', familias: '316', personas: '632', entregas: '316', paquetes: '632', valor: '46321634'},
            {tipo: 'TIPO C', familias: '996', personas: '3780', entregas: '996', paquetes: '2096', valor: '222813219'},
            {tipo: 'TIPO D', familias: '154', personas: '1056', entregas: '154', paquetes: '376', valor: '36230285'},
            {tipo: 'TOTAL', familias: '1850', personas: '5852', entregas: '1850', paquetes: '3488', valor: '333112987'},
            ],
            info2020AHIKits: [
            {tipo: 'TIPO A', familias: '186', personas: '186', entregas: '186', paquetes: '186', valor: '29006322'},
            {tipo: 'TIPO B', familias: '184', personas: '368', entregas: '184', paquetes: '184', valor: '50738552'},
            {tipo: 'TIPO C', familias: '681', personas: '2574', entregas: '681', paquetes: '681', valor: '269615391'},
            {tipo: 'TIPO D', familias: '121', personas: '826', entregas: '121', paquetes: '121', valor: '68722015'},
            {tipo: 'TOTAL', familias: '1172', personas: '3954', entregas: '1172', paquetes: '1172', valor: '418082280'},
            ],
            info2020AHIBancoTemp: [
            {tipo: 'TIPO A', familias: '322', personas: '322', entregas: '322', valor: '32637000'},
            {tipo: 'TIPO B', familias: '192', personas: '384', entregas: '192', valor: '31987400'},
            {tipo: 'TIPO C', familias: '467', personas: '1728', entregas: '467', valor: '102773400'},
            {tipo: 'TIPO D', familias: '92', personas: '642', entregas: '92', valor: '25467500'},
            {tipo: 'TOTAL', familias: '1073', personas: '3076', entregas: '1073', valor: '192865300'},
            ],
            info2020AHIFondoTemp: [
            {tipo: 'TIPO A', familias: '81', personas: '81', entregas: '81', valor: '8587600'},
            {tipo: 'TIPO B', familias: '44', personas: '88', entregas: '44', valor: '7661400'},
            {tipo: 'TIPO C', familias: '91', personas: '326', entregas: '91', valor: '20796000'},
            {tipo: 'TIPO D', familias: '18', personas: '120', entregas: '18', valor: '5082500'},
            {tipo: 'TOTAL', familias: '234', personas: '615', entregas: '234', valor: '42127500'},
            ],


            info2020AVAlimentos: [
            {tipo: 'TIPO A', familias: '54', personas: '54', entregas: '54', paquetes: '54', valor: '3599355'},
            {tipo: 'TIPO B', familias: '61', personas: '122', entregas: '61', paquetes: '93', valor: '8096853'},
            {tipo: 'TIPO C', familias: '166', personas: '649', entregas: '166', paquetes: '431', valor: '31857557'},
            {tipo: 'TIPO D', familias: '51', personas: '354', entregas: '51', paquetes: '153', valor: '9657513'},
            {tipo: 'TOTAL', familias: '332', personas: '1179', entregas: '332', paquetes: '731', valor: '53211278'},
            ],
            info2020AVKits: [
            {tipo: 'TIPO A', familias: '3', personas: '3', entregas: '3', paquetes: '3', valor: '461739'},
            {tipo: 'TIPO B', familias: '1', personas: '2', entregas: '1', paquetes: '1', valor: '275753'},
            {tipo: 'TIPO C', familias: '2', personas: '9', entregas: '2', paquetes: '2', valor: '791822'},
            {tipo: 'TIPO D', familias: '0', personas: '0', entregas: '0', paquetes: '0', valor: '0'},
            {tipo: 'TOTAL', familias: '6', personas: '14', entregas: '6', paquetes: '6', valor: '1529314'},
            ],
            info2020AVBancoTemp: [
            {tipo: 'TIPO A', familias: '45', personas: '45', entregas: '45', valor: '4973100'},
            {tipo: 'TIPO B', familias: '24', personas: '48', entregas: '24', valor: '4324500'},
            {tipo: 'TIPO C', familias: '95', personas: '369', entregas: '95', valor: '22604900'},
            {tipo: 'TIPO D', familias: '37', personas: '307', entregas: '37', valor: '11035000'},
            {tipo: 'TOTAL', familias: '201', personas: '769', entregas: '201', valor: '42937500'},
            ],
            info2020AVFondoTemp: [
            {tipo: 'TIPO A', familias: '0', personas: '0', entregas: '0', valor: '0'},
            {tipo: 'TIPO B', familias: '2', personas: '4', entregas: '2', valor: '363000'},
            {tipo: 'TIPO C', familias: '9', personas: '34', entregas: '9', valor: '2044000'},
            {tipo: 'TIPO D', familias: '1', personas: '6', entregas: '1', valor: '302500'},
            {tipo: 'TOTAL', familias: '12', personas: '44', entregas: '12', valor: '2709500'},
            ],
            info2021AHIAlimentos: [
            {tipo: 'TIPO A', familias: '610', personas: '610', entregas: '610', paquetes: '610', valor: '45105151'},
            {tipo: 'TIPO B', familias: '436', personas: '872', entregas: '436', paquetes: '872', valor: '65220166'},
            {tipo: 'TIPO C', familias: '1224', personas: '4575', entregas: '1224', paquetes: '3668', valor: '275745749'},
            {tipo: 'TIPO D', familias: '236', personas: '1582', entregas: '236', paquetes: '708', valor: '53872539'},
            {tipo: 'TOTAL', familias: '2506', personas: '7639', entregas: '2506', paquetes: '5858', valor: '439943605'},
            ],
            info2021AHIKits: [
            {tipo: 'TIPO A', familias: '260', personas: '260', entregas: '260', paquetes: '260', valor: '31889780'},
            {tipo: 'TIPO B', familias: '225', personas: '450', entregas: '225', paquetes: '225', valor: '62044425'},
            {tipo: 'TIPO C', familias: '612', personas: '2288', entregas: '612', paquetes: '625', valor: '242297532'},
            {tipo: 'TIPO D', familias: '115', personas: '761', entregas: '115', paquetes: '115', valor: '48105650'},
            {tipo: 'TOTAL', familias: '1212', personas: '3759', entregas: '1212', paquetes: '1225', valor: '384337387'},
            ],
            info2021AHIBancoTemp: [
            {tipo: 'TIPO A', familias: '490', personas: '490', entregas: '490', valor: '53494100'},
            {tipo: 'TIPO B', familias: '321', personas: '588', entregas: '294', valor: '58261500'},
            {tipo: 'TIPO C', familias: '899', personas: '3354', entregas: '899', valor: '217558000'},
            {tipo: 'TIPO D', familias: '170', personas: '1141', entregas: '156', valor: '51425000'},
            {tipo: 'TOTAL', familias: '1880', personas: '5573', entregas: '1839', valor: '380738600'},
            ],
            info2021AHIFondoTemp: [
            {tipo: 'TIPO A', familias: '74', personas: '74', entregas: '74', valor: '8167500'},
            {tipo: 'TIPO B', familias: '33', personas: '33', entregas: '33', valor: '5989500'},
            {tipo: 'TIPO C', familias: '72', personas: '264', entregas: '72', valor: '17424000'},
            {tipo: 'TIPO D', familias: '10', personas: '72', entregas: '10', valor: '3025000'},
            {tipo: 'TOTAL', familias: '189', personas: '443', entregas: '189', valor: '34606000'},
            ],


            info2021AVAlimentos: [
            {tipo: 'TIPO A', familias: '225', personas: '456', entregas: '225', paquetes: '225', valor: '15345474'},
            {tipo: 'TIPO B', familias: '90', personas: '180', entregas: '90', paquetes: '180', valor: '13279488'},
            {tipo: 'TIPO C', familias: '339', personas: '1282', entregas: '339', paquetes: '339', valor: '73930113'},
            {tipo: 'TIPO D', familias: '108', personas: '795', entregas: '108', paquetes: '324', valor: '22774581'},
            {tipo: 'TOTAL', familias: '762', personas: '2713', entregas: '762', paquetes: '1068', valor: '125329656'},
            ],
            info2021AVKits: [
            {tipo: 'TIPO A', familias: '3', personas: '3', entregas: '3', paquetes: '3', valor: '367959'},
            {tipo: 'TIPO B', familias: '1', personas: '2', entregas: '1', paquetes: '1', valor: '275753'},
            {tipo: 'TIPO C', familias: '1', personas: '5', entregas: '1', paquetes: '1', valor: '395911'},
            {tipo: 'TIPO D', familias: '1', personas: '6', entregas: '1', paquetes: '1', valor: '418310'},
            {tipo: 'TOTAL', familias: '6', personas: '16', entregas: '6', paquetes: '6', valor: '1457933'},
            ],
            info2021AVBancoTemp: [
            {tipo: 'TIPO A', familias: '64', personas: '64', entregas: '64', valor: '7078500'},
            {tipo: 'TIPO B', familias: '43', personas: '86', entregas: '43', valor: '7804500'},
            {tipo: 'TIPO C', familias: '133', personas: '518', entregas: '133', valor: '32186000'},
            {tipo: 'TIPO D', familias: '52', personas: '373', entregas: '52', valor: '16032500'},
            {tipo: 'TOTAL', familias: '292', personas: '1041', entregas: '292', valor: '63101500'},
            ],
            info2021AVFondoTemp: [
            {tipo: 'TIPO A', familias: '7', personas: '7', entregas: '7', valor: '762300'},
            {tipo: 'TIPO B', familias: '3', personas: '3', entregas: '3', valor: '544500'},
            {tipo: 'TIPO C', familias: '14', personas: '49', entregas: '14', valor: '3388000'},
            {tipo: 'TIPO D', familias: '6', personas: '47', entregas: '6', valor: '1815000'},
            {tipo: 'TOTAL', familias: '30', personas: '106', entregas: '30', valor: '6509800'},
            ],



        }
    },
    methods: {
        exportar: function (arr, title){
            csvExport(arr, title);
        }
    }
}
</script>