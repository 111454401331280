<template>
    <div>        
        <p v-if="!perfilPermitido">Usted no puede acceder a esta area.</p>
        <h1>Control albergues</h1>
        <div class="text-right mb-3">
            <router-link to="/ListaEnviadosNotificar">
                Notificaciones
            </router-link>
        </div>
        <div v-if="perfilPermitido && !showFormDeclaracion" class="card card-secondary">
            <div class="card-body">
                <b-row>
                    <b-col>
                        <b-button-group>
                            <b-form-input v-model="filterDeclaraciones" type="search" id="filterInput" placeholder="Busqueda"></b-form-input>
                            <b-button variant="success" @click="csvExport(declaraciones)">Exportar</b-button>
                        </b-button-group>
                    </b-col>
                    <b-col>
                        <form @submit.prevent="buscarPorFecha">
                            <b-input-group>
                                <b-form-datepicker 
                                    id="fechaini" 
                                    locale='es' 
                                    v-model="fechaInicio" 
                                    required></b-form-datepicker>
                                <b-form-datepicker 
                                    id="fechafin" 
                                    locale='es' 
                                    v-model="fechaFin" 
                                    required></b-form-datepicker>
                                <b-button type="submit">Buscar por fecha</b-button>
                            </b-input-group>
                        </form>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <div class="table-responsive">
                            <b-table 
                                :is-slot-mode="true"
                                :rows="declaraciones"
                                :columns="fieldsDeclaraciones"
                                @do-search="searchDeclaracion">
                                <template v-slot:actions="data">
                                    <b-button-group>
                                        <b-button 
                                            size="sm" 
                                            @click="mostrarForm(data.value)">
                                            Detalles
                                        </b-button>
                                        <b-button 
                                            v-if="data.value.scannedCedula != '0'"
                                            size="sm" 
                                            @click="verImagen(data.value)">
                                            Acta ingreso
                                        </b-button>
                                        <b-button 
                                            v-if="data.value.actaSalida != '0'"
                                            size="sm" 
                                            @click="verImgSalida(data.value)">
                                            Acta salida
                                        </b-button>
                                    </b-button-group>                            
                                </template>
                                <template v-slot:DOCUMENTO="data">
                                    {{data.value.DOCUMENTO}}
                                </template>
                                <template v-slot:PRIMERNOMBRE="data">
                                    {{data.value.PRIMERNOMBRE}}
                                </template>
                                <template v-slot:SEGUNDONOMBRE="data">
                                    {{data.value.SEGUNDONOMBRE}}
                                </template>
                                <template v-slot:PRIMERAPELLIDO="data">
                                    {{data.value.PRIMERAPELLIDO}}
                                </template>
                                <template v-slot:SEGUNDOAPELLIDO="data">
                                    {{data.value.SEGUNDOAPELLIDO}}
                                </template>
                                <template v-slot:ESTADOVICTIMA="data">
                                    {{data.value.ESTADOVICTIMA}}
                                </template>
                                <template v-slot:FUD_FICHA="data">
                                    {{data.value.FUD_FICHA}}
                                </template> 
                            </b-table>
                        </div>                  
                        
                    </b-col>
                </b-row>
            </div>
        </div>
        <div v-if="perfilPermitido && showFormDeclaracion" class="card card-secondary">
            <div class="card-body">
                <b-form @submit.prevent="saveDeclaracion">
                    
                    <b-row align-h="start">
                        <b-col sm="2">
                            <b>id: </b> {{ declaracion.id }}
                        </b-col>
                        <b-col sm="4">
                            <b-input-group prepend="tipo documento">
                                <b-form-select v-model="declaracion.TIPODOCUMENTO" name="TIPODOCUMENTO"
                                    class="form-control" 
                                    :options="listas.tipoDocumentos"
                                    required></b-form-select>
                            </b-input-group>                                                
                        </b-col>
                        <b-col sm="3">
                            <b-input-group prepend="Documento">
                                <b-input type="number" v-model="declaracion.DOCUMENTO" name="DOCUMENTO" placeholder="DOCUMENTO"></b-input>
                            </b-input-group>
                        </b-col>
                        <b-col v-if="personaSigma.length > 0">
                            <a href="">Datos Sigma</a>
                            <span> {{personaSigma[0].nota}} </span>                              
                        </b-col>
                        <b-col v-else>Cargando Sigma...</b-col>
                    </b-row>
                    <br>
                    <b-row>
                        <b-col sm="3">
                            <b-input-group prepend="Nombre 1">
                                <b-input name="PRIMERNOMBRE" v-model="declaracion.PRIMERNOMBRE" placeholder="PRIMERNOMBRE"></b-input>
                            </b-input-group>
                        </b-col>
                        <b-col sm="3">
                            <b-input-group prepend="Nombre 2">
                                <b-input name="SEGUNDONOMBRE" v-model="declaracion.SEGUNDONOMBRE" placeholder="SEGUNDONOMBRE"></b-input>
                            </b-input-group>
                        </b-col>
                        <b-col sm="3">
                            <b-input-group prepend="Apellido 1">
                                <b-input name="PRIMERAPELLIDO" v-model="declaracion.PRIMERAPELLIDO" placeholder="PRIMERAPELLIDO"></b-input>
                            </b-input-group>
                        </b-col>
                        <b-col sm="3">
                            <b-input-group prepend="Apellido ">
                                <b-input name="SEGUNDOAPELLIDO" v-model="declaracion.SEGUNDOAPELLIDO" placeholder="SEGUNDOAPELLIDO"></b-input>
                            </b-input-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-input-group prepend="Lugar declaración">
                                <b-form-select v-model="declaracion.LUGARDECLARACION" name="LUGARDECLARACION"
                                    class="form-control" 
                                    :options="listas.lugarDeclaracion"
                                    required></b-form-select>
                            </b-input-group>
                        </b-col>
                        <b-col>
                            <b-input-group prepend="Fecha envío valoración">
                                <b-form-datepicker id="ENVIADOVALORACION" v-model="declaracion.ENVIADOVALORACION"></b-form-datepicker>
                            </b-input-group>                                
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-input-group prepend="Estado víctima">
                                <b-form-select v-model="declaracion.ESTADOVICTIMA" name="ESTADOVICTIMA"
                                    class="form-control" 
                                    :options="listas.estadosVictima"
                                    required></b-form-select>
                            </b-input-group>
                        </b-col>
                        <b-col>
                            <b-input-group prepend="Fecha estado">
                                <b-form-datepicker id="FECHAESTADO" v-model="declaracion.FECHAESTADO"></b-form-datepicker>
                            </b-input-group>                                
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-input-group prepend="Codigo Fud">
                                <b-input name="FUD_FICHA" v-model="declaracion.FUD_FICHA" placeholder="Codigo Fud"></b-input>
                            </b-input-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-input-group prepend="Municipio">
                                <b-form-select v-model="declaracion.MUNICIPIO" name="MUNICIPIO"
                                    class="form-control"
                                    @change="asignarDepto"
                                    required>
                                    <b-form-select-option 
                                        v-for="municipio in lugares.municipio" 
                                        :key="municipio.id" 
                                        :value="municipio.id">{{ municipio.municipio }} - {{ municipio.departamento}}</b-form-select-option>    </b-form-select>
                            </b-input-group>
                        </b-col>
                        <b-col>
                            <b>Departamento: </b> {{ this.declaracion.DEPARTAMENTO }}
                            <b>Subregion: </b> {{ this.declaracion.SUBREGION }}
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-input-group prepend="Barrio solo Medellín">
                                <b-form-select v-model="declaracion.ENTORNO" name="ENTORNO"
                                    class="form-control"
                                    @change="asignarComuna($event)"
                                    required>
                                    <b-form-select-option 
                                        v-for="barrio in lugares.barrio" 
                                        :key="barrio.id" 
                                        :value="barrio.id">{{ barrio.barrio }} - {{ barrio.comuna}}</b-form-select-option>    </b-form-select>
                            </b-input-group>
                        </b-col>
                        <b-col>
                            <b>Comuna: </b> {{ declaracion.COMUNA }}
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-input-group prepend="NUCLEO INGRESO">
                                <b-input type="number" name="NUCLEOINGRESO" v-model="declaracion.NUCLEOINGRESO" placeholder="Personas ingreso"></b-input>
                            </b-input-group>
                        </b-col>
                        <b-col>
                            <b-input-group prepend="NUCLEO VIVANTO">
                                <b-input type="number" name="NUCLEOVIVANTO" v-model="declaracion.NUCLEOVIVANTO" placeholder="Personas vivanto"></b-input>
                            </b-input-group>
                        </b-col>
                        <b-col>
                            <b-input-group prepend="RESPONSABLE">
                                <b-input type="text" name="RESPONSABLE" v-model="declaracion.RESPONSABLE" placeholder="Personas vivanto"></b-input>
                            </b-input-group>
                        </b-col>
                    </b-row>
                    
                    <b-row><br></b-row>
                    <b-row><b-col>Desplazamiento</b-col><b-col> <b-form-select v-model="declaracion.DESPLAZAMIENTO" class="form-control" :selected="declaracion.DESPLAZAMIENTO" :options="listas.estadosVictima" required></b-form-select></b-col></b-row>
                    <b-row><b-col>Amenaza</b-col><b-col> <b-form-select v-model="declaracion.AMENAZA" class="form-control" :selected="declaracion.AMENAZA" :options="listas.estadosVictima" required></b-form-select></b-col></b-row>
                    <b-row><b-col>Homicidio</b-col><b-col> <b-form-select v-model="declaracion.HOMICIDIO" class="form-control" :selected="declaracion.HOMICIDIO" :options="listas.estadosVictima" required></b-form-select></b-col></b-row>
                    <b-row><b-col>Reclutamiento forzado</b-col><b-col> <b-form-select v-model="declaracion.RECLUTAMIENTOFORZADO" class="form-control" :selected="declaracion.RECLUTAMIENTOFORZADO" :options="listas.estadosVictima" required></b-form-select></b-col></b-row>
                    <b-row><b-col>Despojo forzado de tierras</b-col><b-col> <b-form-select v-model="declaracion.DESPOJOFORZADODETIERRAS" class="form-control" :selected="declaracion.DESPOJOFORZADODETIERRAS" :options="listas.estadosVictima" required></b-form-select></b-col></b-row>
                    <b-row><b-col>Acto terrorista</b-col><b-col> <b-form-select v-model="declaracion.ACTOSTERRORISTA" class="form-control" :selected="declaracion.ACTOSTERRORISTA" :options="listas.estadosVictima" required></b-form-select></b-col></b-row>
                    <b-row><b-col>Delitos contra la libertad sexual</b-col><b-col> <b-form-select v-model="declaracion.DELITOSCONTRALIBERTADSEXUAL" class="form-control" :selected="declaracion.DELITOSCONTRALIBERTADSEXUAL" :options="listas.estadosVictima" required></b-form-select></b-col></b-row>
                    <b-row><b-col>Secuestro</b-col><b-col> <b-form-select v-model="declaracion.SECUESTRO" class="form-control" :selected="declaracion.SECUESTRO" :options="listas.estadosVictima" required></b-form-select></b-col></b-row>
                    <b-row><b-col>Desaparicion forzada</b-col><b-col> <b-form-select v-model="declaracion.DESPARICIONFORZADA" class="form-control" :selected="declaracion.DESPARICIONFORZADA" :options="listas.estadosVictima" required></b-form-select></b-col></b-row>
                    <b-row><b-col>Lesiones personales</b-col><b-col> <b-form-select v-model="declaracion.LESIONESPERSONALES" class="form-control" :selected="declaracion.LESIONESPERSONALES" :options="listas.estadosVictima" required></b-form-select></b-col></b-row>
                    <b-row><b-col>Minas antipersona</b-col><b-col> <b-form-select v-model="declaracion.MINASANTIPERSONA" class="form-control" :selected="declaracion.MINASANTIPERSONA" :options="listas.estadosVictima" required></b-form-select></b-col></b-row>
                    <b-row><b-col>Tortura</b-col><b-col> <b-form-select v-model="declaracion.TORTURA" class="form-control" :selected="declaracion.TORTURA" :options="listas.estadosVictima" required></b-form-select></b-col></b-row>
                    <b-row><b-col>Masacre</b-col><b-col> <b-form-select v-model="declaracion.MASACRE" class="form-control" :selected="declaracion.MASACRE" :options="listas.estadosVictima" required></b-form-select></b-col></b-row>
                    <b-row><b-col>Hostigamiento</b-col><b-col> <b-form-select v-model="declaracion.HOSTIGAMIENTO" class="form-control" :selected="declaracion.HOSTIGAMIENTO" :options="listas.estadosVictima" required></b-form-select></b-col></b-row>
                    <b-row><b-col>Violencia de genero</b-col><b-col> <b-form-select v-model="declaracion.VIOLENCIADEGENERO" class="form-control" :selected="declaracion.VIOLENCIADEGENERO" :options="listas.estadosVictima" required></b-form-select></b-col></b-row>
                    <b-row><b-col>Alta vulnerabilidad y/o remision otras instituciones</b-col><b-col> <b-form-select v-model="declaracion.OTRAALTAVULNERABILIDADREMISIONES" class="form-control" :selected="declaracion.OTRAALTAVULNERABILIDADREMISIONES" :options="listas.estadosVictima" required></b-form-select></b-col></b-row>
                    <b-row>
                        <b-col>
                            <b-button @click="buscarIntegrantes">Ver integrantes</b-button>
                        </b-col>
                    </b-row>                        
                    <b-row v-if="integrantes.length > 0">
                        <div class="table-responsive">
                            <b-table
                                :is-slot-mode="true"
                                :columns="integrantesFields"
                                :rows="integrantes">
                                <template v-slot:id="data">
                                    {{data.value.id}}
                                </template>
                                <template v-slot:DECLARAID="data">
                                    <b-input type="text" v-model="data.value.DECLARAID" placeholder="DECLARAID"></b-input>
                                </template>
                                <template v-slot:FUD="data">
                                    <b-input type="text" v-model="data.value.FUD" placeholder="FUD" style="width: 125px;"></b-input>
                                </template>
                                <template v-slot:TIPODOCUMENTO="data">
                                    <b-form-select 
                                        class="form-control" 
                                        v-model="data.value.TIPODOCUMENTO" 
                                        :selected="data.value.TIPODOCUMENTO" 
                                        :options="listas.tipoDocumentos"
                                        required></b-form-select>
                                </template>
                                <template v-slot:DOCUMENTO="data">
                                    <b-input type="text" v-model="data.value.DOCUMENTO" placeholder="DOCUMENTO" style="width: 125px;"></b-input>
                                </template>
                                <template v-slot:PRIMERNOMBRE="data">
                                    <b-input type="text" v-model="data.value.PRIMERNOMBRE" placeholder="PRIMER NOMBRE" style="width: 125px;"></b-input>                
                                </template>
                                <template v-slot:SEGUNDONOMBRE="data">
                                    <b-input type="text" v-model="data.value.SEGUNDONOMBRE" placeholder="SEGUNDO NOMBRE" style="width: 125px;"></b-input>
                                </template>
                                <template v-slot:PRIMERAPELLIDO="data">
                                    <b-input type="text" v-model="data.value.PRIMERAPELLIDO" placeholder="PRIMER APELLIDO" style="width: 125px;"></b-input>
                                </template>
                                <template v-slot:SEGUNDOAPELLIDO="data">
                                    <b-input type="text" v-model="data.value.SEGUNDOAPELLIDO" placeholder="SEGUNDO NOMBRE" style="width: 125px;"></b-input>
                                </template>
                                <template v-slot:PARENTESCO="data">
                                    <b-form-select 
                                        class="form-control" 
                                        v-model="data.value.PARENTESCO" 
                                        :options="listas.parentescos">
                                        </b-form-select>
                                </template>
                                <template v-slot:FECHANACIMIENTO="data">
                                    <b-input type="text" v-model="data.value.FECHANACIMIENTO" placeholder="FECHANACIMIENTO" style="width: 125px;"></b-input>
                                </template>
                                <template v-slot:GENERO="data">
                                    <p style="width:100px;">
                                        <b-form-select 
                                            class="form-control" 
                                            v-model="data.value.GENERO" 
                                            :options="listas.generos">
                                            </b-form-select>
                                    </p>
                                </template>
                                <template v-slot:LGTBI="data" >
                                    <p style="width: 90px">
                                        <b-form-select 
                                        class="form-control" 
                                        v-model="data.value.LGTBI" 
                                        :options="listas.lgtbi"
                                        >
                                        </b-form-select>
                                    </p>
                                </template>
                                <template v-slot:ETNIA="data">
                                    <p style="width: 100px;">
                                        <b-form-select 
                                            class="form-control" 
                                            v-model="data.value.ETNIA" 
                                            :options="listas.etnias">
                                            </b-form-select>
                                    </p>
                                </template>
                                <template v-slot:DISCAPACIDAD="data">
                                    <b-input type="text" v-model="data.value.DISCAPACIDAD" placeholder="DISCAPACIDAD" style="width: 125px;"></b-input>
                                </template>
                                <template v-slot:GRUPOETAREO="data">
                                    <b-input type="text" v-model="data.value.GRUPOETAREO" placeholder="GRUPOETAREO" style="width: 125px;"></b-input>
                                </template>
                                <template v-slot:TELEFONO="data">
                                    <b-input type="text" v-model="data.value.TELEFONO" placeholder="TELEFONO" style="width: 125px;"></b-input>
                                </template>
                                <template v-slot:CORREO="data">
                                    <b-input type="text" v-model="data.value.CORREO" placeholder="CORREO" style="width: 125px;"></b-input>
                                </template>
                                <template v-slot:SEDE="data">
                                    <p style="width: 100px;">
                                        <b-form-select 
                                            class="form-control" 
                                            v-model="data.value.SEDE" 
                                            :options="listas.sedeAlbergues">
                                            </b-form-select>
                                    </p>
                                </template>
                                <template v-slot:FECHAINGRESO="data">
                                    <b-input type="text" v-model="data.value.FECHAINGRESO" placeholder="FECHAINGRESO" style="width: 125px;"></b-input>
                                </template>
                                <template v-slot:FECHASALIDA="data">
                                    <b-input type="text" v-model="data.value.FECHASALIDA" placeholder="FECHASALIDA" style="width: 125px;"></b-input>                                    
                                </template>
                                <template v-slot:created_at="data">
                                    <b-input type="text" v-model="data.value.created_at" placeholder="created_at" style="width: 125px;"></b-input>
                                </template>
                                <template v-slot:updated_at="data">
                                    <b-input type="text" v-model="data.value.updated_at" placeholder="updated_at" style="width: 125px;"></b-input>
                                </template>
                                <template v-slot:actions="data">
                                    <b-button
                                            size="sm" 
                                            @click="guardarIntegrante(data.value)" 
                                            class="mr-1">
                                        Actualizar
                                        </b-button>
                                </template>
                            </b-table>
                        </div>
                    </b-row>
                    <b-row v-else><b-col>No hay integrantes</b-col></b-row>
                    <b-row>
                        <b-col sm="3">
                            <b-button @click="cerrarForm">Cerrar</b-button>
                        </b-col>
                        <b-col>
                            <b-button @click="borrarDeclaracion">Eliminar</b-button>
                        </b-col>
                        <b-col>
                            <b-button type="submit">Actualizar</b-button>
                        </b-col>
                        <b-col>
                            <b-button v-if="!registrandoSigma" @click="RegistrarEnSigma">Registrar ingreso en Sigma</b-button>
                            <b-button v-else disabled>Registrando..</b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </div>
        </div>

    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment-timezone'
import TableLite from "vue3-table-lite"
import Datepicker from 'vue3-date-time-picker'
import store from '@/store/index'
import listas from '@/pages/albergue/listas.js'
import lugares from '@/pages/diagnostico/lugares.js'
import download from 'downloadjs'
import BInput from 'bootstrap-vue-3/src/components/BFormInput/BFormInput.vue'
export default {
    name: 'Alberguecontrol',
    components: {
        'b-table': TableLite,
        'b-form-datepicker': Datepicker,
        'b-input': BInput
    },
    data () {
        return {
            token: '',
            showFormDeclaracion: false,
            perfil: localStorage.getItem("userPerfil"),
            perfilPermitido: false,
            declaraciones: [],
            declaracion: [],
            filterDeclaraciones: "",
            fieldsDeclaraciones: [
                { field: 'actions', label: 'Acciones' }, // this is a virtual column, that does not exist in our `items`                
                { field: 'DOCUMENTO', label: 'DOCUMENTO' },
                { field: 'PRIMERNOMBRE', label: 'NOMBRES' },
                { field: 'SEGUNDONOMBRE', label: '' },
                { field: 'PRIMERAPELLIDO', label: 'APELLIDOS' },
                { field: 'SEGUNDOAPELLIDO', label: '' },
                { field: 'ESTADOVICTIMA', label: 'ESTADO' },
                { field: 'FUD_FICHA', label: 'FUD' }               
            ],                        
            listas: listas,
            lugares: lugares,
            fechaInicio: Date("Y-m-d"),
            fechaFin: Date("Y-m-d"),
            integrantesFields: [
                { field: 'id', label: 'id'},
                { field: 'DECLARAID', label: 'DECLARAID'},
                { field: 'FUD', label: 'FUD'},
                { field: 'TIPODOCUMENTO', label: 'TIPODOCUMENTO'},
                { field: 'DOCUMENTO', label: 'DOCUMENTO'},
                { field: 'PRIMERNOMBRE', label: 'PRIMERNOMBRE'},
                { field: 'SEGUNDONOMBRE', label: 'SEGUNDONOMBRE'},
                { field: 'PRIMERAPELLIDO', label: 'PRIMERAPELLIDO'},
                { field: 'SEGUNDOAPELLIDO', label: 'SEGUNDOAPELLIDO'},
                { field: 'PARENTESCO', label: 'PARENTESCO'},
                { field: 'FECHANACIMIENTO', label: 'FECHANACIMIENTO'},
                { field: 'GENERO', label: 'GENERO'},
                { field: 'LGTBI', label: 'LGTBI'},
                { field: 'ETNIA', label: 'ETNIA'},
                { field: 'DISCAPACIDAD', label: 'DISCAPACIDAD'},
                { field: 'GRUPOETAREO', label: 'GRUPOETAREO'},
                { field: 'TELEFONO', label: 'TELEFONO'},
                { field: 'CORREO', label: 'CORREO'},
                { field: 'SEDE', label: 'SEDE'},
                { field: 'FECHAINGRESO', label: 'FECHAINGRESO'},
                { field: 'FECHASALIDA', label: 'FECHASALIDA'},
                { field: 'created_at', label: 'created_at'},
                { field: 'updated_at', label: 'updated_at'},
                { field: 'actions', label: 'Acciones' } // this is a virtual column, that does not exist in our `items`
            ],
            integrantes: [],
            registrandoSigma: false,
            personaSigma: []
        }
    },
    methods: {
        borrarDeclaracion: function () {
            this.token = localStorage.getItem('token');
            let url = store.getters['auth/urlApi'];
            axios.delete( url + 'declaralbergue/' + this.declaracion.id,
            {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${this.token}`
            }
            })
            .then(() => {
                alert ("Familia Borrada");
                this.buscarPorFecha();
                this.cerrarForm();
            })
            .catch((error) => {
                console.log(error)
            })
        },
        guardarIntegrante: function(item) {
            console.log(item);
            this.token = localStorage.getItem('token');
            let url = store.getters['auth/urlApi']; 
            const params = new URLSearchParams()
            params.append('id', item.id)
            params.append('DECLARAID', item.DECLARAID)
            params.append('FUD', item.FUD)
            params.append('TIPODOCUMENTO', item.TIPODOCUMENTO)
            params.append('DOCUMENTO', item.DOCUMENTO)
            params.append('PRIMERNOMBRE', item.PRIMERNOMBRE)
            params.append('SEGUNDONOMBRE', item.SEGUNDONOMBRE)
            params.append('PRIMERAPELLIDO', item.PRIMERAPELLIDO)
            params.append('SEGUNDOAPELLIDO', item.SEGUNDOAPELLIDO)
            
            params.append('PARENTESCO', item.PARENTESCO)
            params.append('FECHANACIMIENTO', item.FECHANACIMIENTO)
            params.append('GENERO', item.GENERO)
            params.append('LGTBI', item.LGTBI)
            params.append('ETNIA', item.ETNIA)
            
            params.append('DISCAPACIDAD', item.DISCAPACIDAD)
            params.append('GRUPOETAREO', item.GRUPOETAREO)
            params.append('TELEFONO', item.TELEFONO)
            params.append('CORREO', item.CORREO)
            params.append('SEDE', item.SEDE)
            params.append('FECHAINGRESO', item.FECHAINGRESO)
            params.append('FECHASALIDA', item.FECHASALIDA)
            
            axios.put( url + 'albergue/' + item.id, 
                params,
            {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${this.token}`
            }
            })
            .then(() => {
                alert ("Persona actualizada")
            })
            .catch((error) => {
                console.log(error)
            })
            
            return false





        },
        buscarIntegrantes: function () {
            this.token = localStorage.getItem('token');
            let url = store.getters['auth/urlApi'];
            const params = new FormData()            
            params.append('DECLARAID', this.declaracion.id)
            
            axios.post( url + 'listaporcodigodeclaracion', params, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.token}`          
            }
            })
            .then((res) => {   
                this.integrantes = res.data.data
            })
            .catch((error) => {
                console.log(error)          
            })
        },
        buscarPorFecha: function () {
            this.token = localStorage.getItem('token');
            let url = store.getters['auth/urlApi'];
            const params = new FormData()            
            params.append('FECHAINICIO', moment(this.fechaInicio).format("YYYY-MM-DD"))
            params.append('FECHAFIN', moment(this.fechaFin).format("YYYY-MM-DD"))
            
            axios.post( url + 'getdeclaracionesfechas', params, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.token}`          
            }
            })
            .then((res) => {   
                this.declaraciones = res.data.data
            })
            .catch((error) => {
                console.log(error)          
            })
            return false
        },
        asignarDepto: function () {
            let municipio = this.declaracion.MUNICIPIO;
            let departamento = '';
            let subregion = '';
            let departamentoid = '';
            this.lugares.municipio.map(function(lugar) {
                if(lugar.id == municipio){
                    departamento = lugar.departamento;
                    departamentoid = lugar.cod_dept;
                    subregion = lugar.subregion;
                }
            });
            this.declaracion.DEPARTAMENTO = departamento;
            this.declaracion.DEPARTAMENTOID = departamentoid;
            this.declaracion.SUBREGION = subregion;
        },
        asignarComuna: function(event) {
            let comuna = ''
            this.lugares.barrio.map(function(lugar) {
                if(lugar.id == event){
                    comuna = lugar.comuna;
                }
            });
            this.objs.declara.COMUNA = comuna;
            
        },
        cerrarForm: function () {
            this.integrantes = [];
            this.declaracion = [];
            this.personaSigma = [];
            this.showFormDeclaracion = false;
        },
        mostrarForm: function (item) {
            switch (item.ESTADOVICTIMA) {
                case 'NO APLICA':
                    item.ESTADOVICTIMA = '0';
                    break;
                case 'VALORACION - VALORADO':
                    item.ESTADOVICTIMA = 'V';
                    break;
                case 'INCLUIDO':
                    item.ESTADOVICTIMA = 'I';
                    break;
                case 'NO INCLUIDO':
                    item.ESTADOVICTIMA = 'N';
                    break;
                default:
                    break;
            }
            this.declaracion = item;
            this.showFormDeclaracion = true;
            this.buscarIntegrantes();
            this.consultaPersonaSigma();
            this.asignarDepto();
        },
        verImagen: function (item) {
            this.token = localStorage.getItem('token');
            let url = store.getters['auth/urlApi'];
            axios.get( url + 'cedulaAlbergue/' + item.id, {
                headers: {
                    'Accept': 'application/pdf',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`          
                },
                responseType: 'blob',
            })
            .then((res) => {   
                const content = res.headers['content-type'];
                download(res.data, item.DOCUMENTO+".pdf", content)
            })
            .catch((error) => {
                console.log(error)         
            })
        },
        verImgSalida: function (item) {
            this.token = localStorage.getItem('token');
            let url = store.getters['auth/urlApi'];
            axios.get( url + 'actaSalida/' + item.id, {
                headers: {
                    'Accept': 'application/pdf',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`          
                },
                responseType: 'blob',
            })
            .then((res) => {   
                const content = res.headers['content-type'];
                download(res.data, "Acta_salida_"+item.DOCUMENTO+".pdf", content)
            })
            .catch((error) => {
                console.log(error)         
            })
        },
        showListaDeclaraciones: function () {
            this.token = localStorage.getItem('token');
            let url = store.getters['auth/urlApi'];
            axios.get( url + 'declaralbergue', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.token}`          
            }
            })
            .then((res) => {   
                this.declaraciones = res.data.data
            })
            .catch((error) => {
                console.log(error)          
            })
            return true
        },
        saveDeclaracion: function () {
            this.token = localStorage.getItem('token');
            let url = store.getters['auth/urlApi'];
            let estadovict = 0
            switch (this.declaracion.ESTADOVICTIMA) {
                case 'V':
                    estadovict = 'VALORACION - VALORADO'
                    break
                case 'N':
                    estadovict = 'NO INCLUIDO'
                    break
                case 'I':
                    estadovict = 'INCLUIDO'
                    break
                default:
                    estadovict = 0
                    break;
            }            
            const params = new URLSearchParams()
            params.append('TIPODOCUMENTO', this.declaracion.TIPODOCUMENTO)
            params.append('DOCUMENTO', this.declaracion.DOCUMENTO)
            params.append('PRIMERNOMBRE', this.declaracion.PRIMERNOMBRE)
            params.append('SEGUNDONOMBRE', this.declaracion.SEGUNDONOMBRE)
            params.append('PRIMERAPELLIDO', this.declaracion.PRIMERAPELLIDO)
            params.append('SEGUNDOAPELLIDO', this.declaracion.SEGUNDOAPELLIDO)
            params.append('NUCLEOVIVANTO', this.declaracion.NUCLEOVIVANTO)
            params.append('NUCLEOINGRESO', this.declaracion.NUCLEOINGRESO)
            params.append('DESPLAZAMIENTO', this.declaracion.DESPLAZAMIENTO)
            
            params.append('DEPARTAMENTO', this.declaracion.DEPARTAMENTOID)
            params.append('MUNICIPIO', this.declaracion.MUNICIPIO)
            params.append('ENTORNO', this.declaracion.ENTORNO)
            params.append('COMUNA', this.declaracion.COMUNA)
            params.append('SUBREGION', this.declaracion.SUBREGION)
            
            params.append('AMENAZA', this.declaracion.AMENAZA)
            params.append('HOMICIDIO', this.declaracion.HOMICIDIO)
            params.append('RECLUTAMIENTOFORZADO', this.declaracion.RECLUTAMIENTOFORZADO)
            params.append('DESPOJOFORZADODETIERRAS', this.declaracion.DESPOJOFORZADODETIERRAS)
            params.append('ACTOSTERRORISTA', this.declaracion.ACTOSTERRORISTA)
            params.append('DELITOSCONTRALIBERTADSEXUAL', this.declaracion.DELITOSCONTRALIBERTADSEXUAL)
            params.append('SECUESTRO', this.declaracion.SECUESTRO)
            params.append('DESPARICIONFORZADA', this.declaracion.DESPARICIONFORZADA)
            params.append('LESIONESPERSONALES', this.declaracion.LESIONESPERSONALES)
            params.append('MINASANTIPERSONA', this.declaracion.MINASANTIPERSONA)
            params.append('TORTURA', this.declaracion.TORTURA)
            params.append('MASACRE', this.declaracion.MASACRE)
            params.append('HOSTIGAMIENTO', this.declaracion.HOSTIGAMIENTO)
            params.append('VIOLENCIADEGENERO', this.declaracion.VIOLENCIADEGENERO)
            params.append('OTRAALTAVULNERABILIDADREMISIONES', this.declaracion.OTRAALTAVULNERABILIDADREMISIONES)
            
            params.append('RESPONSABLE', this.declaracion.RESPONSABLE)
            params.append('LUGARDECLARACION', this.declaracion.LUGARDECLARACION)

            params.append('ESTADOVICTIMA', estadovict)
            params.append('FECHAESTADO', this.declaracion.FECHAESTADO)
            params.append('FUD_FICHA', this.declaracion.FUD_FICHA)
            params.append('ENVIADOVALORACION', this.declaracion.ENVIADOVALORACION)
            console.log("vamos a guardar");
            axios.put( url + 'declaralbergue/' + this.declaracion.id, 
                params,
            {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${this.token}`
            }
            })
            .then(() => {
                alert ("Familia actualizada")
            })
            .catch((error) => {
                console.log(error)
            })
            
            return false
        },
        csvExport(arrData) {
            let csvContent = "data:text/csv;charset=utf-8,";
            csvContent += [
                Object.keys(arrData[0]).join(";"),
                ...arrData.map(item => Object.values(item).join(";"))
            ]
                .join("\n")
                .replace(/(^\[)|(\]$)/gm, "");

            const data = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", data);
            link.setAttribute("download", "export.csv");
            link.click();
        },
        consultaPersonaSigma: function () {
            this.token = localStorage.getItem('token');
            let url = store.getters['auth/urlApi'];
            fetch (url + 'getPersona', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
                body: JSON.stringify ({
                    DOCUMENTO: this.declaracion.DOCUMENTO,
                    TIPODOCUMENTO: this.declaracion.TIPODOCUMENTO
                })
            })
            .then(resp => resp.json())
            .then(data => {
                var dat = Object.values(data);
                if(typeof(dat[0]) === 'object'){
                    dat[0].nota = "Cabeza familia: " + dat[0].idcabezafamilia;
                }else{
                    dat = [{"nota" : dat[0]}]
                }
                this.personaSigma = dat;
            });
        },
        RegistrarEnSigma: function (){
            if(this.declaracion.DOCUMENTO == this.personaSigma[0].idpersona){
                this.registrandoSigma = true;
                var fecha = new Date().toISOString().slice(0, 10);
                this.token = localStorage.getItem('token');
                let url = store.getters['auth/urlApi'];
                var municipio = "";
                var mun = this.declaracion.MUNICIPIO
                this.lugares.municipio.map(
                    function(lugar) {
                    if(lugar.id == mun){
                        municipio = lugar.municipio +"-"+ lugar.departamento;
                    }
                });
                
                var integrantes =  "";
                var parentescos = this.listas.parentescos;
                this.integrantes.map(
                    function (integrante) {
                        if (integrante.PARENTESCO != 1){
                            var par = "";
                            parentescos.map(function(parentesco){
                                if(parentesco.value == integrante.PARENTESCO){
                                    par = parentesco.text;
                                }
                            })
                            integrantes += " "+ integrante.PRIMERNOMBRE +" "+ integrante.SEGUNDONOMBRE 
                                +" "+ integrante.PRIMERAPELLIDO +" "+ integrante.SEGUNDOAPELLIDO +" "+ 
                                integrante.TIPODOCUMENTO +" "+ integrante.DOCUMENTO +" "+ 
                                par;
                        }
                        if(integrante.PARENTESCO == 1){
                            var fech = integrante.FECHAINGRESO.split(" ");
                            fecha = fech[0];
                        }
                        
                    }
                )
                if(this.integrantes.length == 1){
                    integrantes = ", ES REMITIDO AL ALBERGUE SOLO.";
                }else {
                    integrantes = ", ES REMITIDO(A) AL ALBERGUE EN COMPAÑIA DE " + integrantes;
                }
                var observacion = 
                    this.declaracion.PRIMERNOMBRE+" "+this.declaracion.SEGUNDONOMBRE+" "+
                    this.declaracion.PRIMERAPELLIDO+" "+this.declaracion.SEGUNDOAPELLIDO+", "+
                    "DECLARA EN LA "+this.declaracion.LUGARDECLARACION+" EL DIA "+
                    this.declaracion.FECHAESTADO+", " + 
                    "INDICA DESPLAZAMIENTO DE "+municipio+ integrantes;
                
                fetch( url + 'saveatencion', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.token}`
                    },
                    body: JSON.stringify ({
                        IDAYUDA: 26,
                        IDPERSONA: this.declaracion.DOCUMENTO,
                        FECHA: fecha,
                        IDENTIDAD: 1,
                        USERNAME: localStorage.getItem('usuario'),
                        CANTIDAD: this.integrantes.length,
                        OBSERVACIONES: observacion,
                        VALOR: 0,
                        SEDE: 'Belencito',
                    })
                })
                .then(resp => resp.json())
                .then(data => {
                    alert(data.mensaje);
                    this.registrandoSigma = false;
                });
            }else {
                alert ("El albergue solo se puede registrar al Jefe de hogar creado en Sigma.")
            }
        }
    },
    mounted () {

        if(this.perfil == "2" || this.rol == "3"){
            this.perfilPermitido = true
        }
    }
}
</script>