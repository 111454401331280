<template>
    <div>
        <h3>Caracterización DFI</h3>
        <p v-if="!perfilPermitido">Usted no puede acceder a esta area.</p>
        <div v-if="perfilPermitido" class="row text-center">
            <div class="col-md-12">
                <form @submit.prevent="buscarDxs">
                    <div class="input-group input-group-sm">
                        <label for="">Fecha Inicio:</label>
                        <Datepicker 
                            v-model="fechaInicio"
                            is24="true"
                            locale="es"
                            format="yyyy-MM-dd"
                            :maxDate="maxDate"
                            :minDate="minDate"
                            required></Datepicker>
                        <label for="">Fecha Fin:</label>
                        <Datepicker 
                            v-model="fechaFin"
                            is24="true"
                            locale="es"
                            format="yyyy-MM-dd"
                            :maxDate="maxDate"
                            :minDate="minDate"
                            required></Datepicker>     
                        <span class="input-group-append">
                            <button class="btn btn-secondary" type="submit">Mostrar</button>                            
                        </span>
                        
                    </div>
                </form>
            </div>
        </div>
        <p></p>
        <div v-if="perfilPermitido && !infoModal.title" class="card card-secondary">
            <div class="card-header">
                <h3 class="card-title">Lista de diagnósticos</h3>
            </div>
            <div class="card-body">
                <table-lite
                    :is-slot-mode="true"
                    :columns="fields"
                    :rows="dxs"> 
                    <template v-slot:dx_fecha="data">
                        {{data.value.dx_fecha}}
                    </template>
                    <template v-slot:id_1_1_1__p_nombre="data">
                        {{data.value.id_1_1_1__p_nombre}}
                    </template>
                    <template v-slot:id_1_1_2__s_nombre="data">
                        {{data.value.id_1_1_2__s_nombre}}
                    </template>
                    <template v-slot:id_1_1_3__p_apellido="data">
                        {{data.value.id_1_1_3__p_apellido}}
                    </template>
                    <template v-slot:id_1_1_4__s_apellido="data">
                        {{data.value.id_1_1_4__s_apellido}}
                    </template>
                    <template v-slot:id_1_2_2__n_documento="data">
                        {{data.value.id_1_2_2__n_documento}}
                    </template>
                    <template v-slot:categorianame="data">
                        {{data.value.categorianame}}
                    </template>
                    <template v-slot:factordet="data">
                        {{data.value.factordet}}
                    </template>
                    <template v-slot:actions="data">
                        <button type="button" class="btn btn-block btn-default" @click="info(data.value)">Ver</button>
                    </template>
                </table-lite>
            </div>            
        </div>

        <div v-if="perfilPermitido && infoModal.title" class="card card-secondary">
            <div class="card-header">
                <h3 class="card-title">Categorías</h3>
            </div>
            <div class="card-body">
                <form @submit.prevent="submitCategoria">
                    <div class="row">
                        <div class="col-md-12">
                            <b>ID:</b> {{ infoModal.content.id }}
                            <b>FECHA:</b> {{ infoModal.content.dx_fecha }}, 
                            <b>NOMBRES:</b> {{ infoModal.content.id_1_1_1__p_nombre }} 
                            {{ infoModal.content.id_1_1_2__s_nombre }} 
                            {{ infoModal.content.id_1_1_3__p_apellido }} 
                            {{ infoModal.content.id_1_1_4__s_apellido }} 
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <b>DOCUMENTO:</b> {{ infoModal.content.id_1_2_2__n_documento }}, 
                            <b>DEPARTAMENTO:</b> {{ infoModal.content.vict_4_4_1__departamento }}, 
                            <b>MUNICIPIO:</b> {{ infoModal.content.vict_4_4_2__municipio }}, 
                            <b>SUBREGION:</b> {{ infoModal.content.vict_4_4_3__subregion_antioquia }}, 
                            <b>LUGAR EXACTO:</b> {{ infoModal.content.vict_4_4_4__lugar_exacto_del_municipio }} 
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <b>ENTORNO:</b> {{ infoModal.content.vict_4_4_5__nombre_tipo_entorno }}, 
                            <b>TIPO MOVILIDAD:</b> {{ lugares.tipoMovilidad[infoModal.content.vict_4_4_6__tipo_movilidad] }}, 
                            <b>COMUNA:</b> {{ infoModal.content.vict_4_4_7__hecho_Intraurbano_Comuna_o_Corregimiento }}, 
                            <b>BARRIO:</b> {{ infoModal.content.vict_4_4_8__hecho_Intraurbano_Barrio }}, 
                            <b>SECTOR INTRAURBANO:</b> {{ infoModal.content.vict_4_4_9__hecho_Intraurbano_Sector_o_Asentamiento }}, 
                            <b>DIRECCION:</b> {{ infoModal.content.vict_4_4_10__hecho_Intraurbano_Direccion }}, 
                            <b>FECHA HECHO:</b> {{ infoModal.content.vict_4_5_fecha_hecho }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <b>RESPONSABLE:</b> {{ infoModal.content.dx_7_1_nombre_responsable_atencion }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <b>OBSERVACIONES:</b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            {{ infoModal.content.vict_4_7__observaciones }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <b>CATEGORIA:</b> 
                            <select 
                                v-model="infoModal.content.res_6_1_3__categoria" 
                                :selected="infoModal.content.res_6_1_3__categoria" 
                                size="sm" 
                                @change="cambiarFactores(infoModal.content.res_6_1_3__categoria)" 
                                class="custom-select form-control-border">
                                <option v-for="option in categorias" v-bind:key="option.value" :value="option.value">
                                    {{ option.text }}
                                </option>                              
                            </select>
                        </div>
                        <div class="col-md-4">
                            <b>HECHO DETERMINANTE:</b> 
                            <select 
                                v-model="infoModal.content.res_6_1_2__factor_determinante"
                                :selected="infoModal.content.res_6_1_2__factor_determinante" 
                                size="sm" 
                                class="custom-select form-control-border">
                                <option v-for="option in factores" v-bind:key="option.value" :value="option.value">
                                    {{ option.text }}
                                </option>  
                            </select>
                        </div>
                        <div class="col-md-4">
                            <b>OTRO:</b> 
                            <input 
                                type="text" 
                                placeholder="Otro" 
                                v-model="infoModal.content.res_6_1_1__factores_incidencia"
                                class="form-control form-control-border">
                            
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <button v-if="!guardandoCategoria" type="submit" class="btn btn-secondary">Guardar</button>
                            <button v-else type="submit"  disabled class="btn btn-secondary">Guardar</button>
                            <button class="btn btn-default float-right" @click="cancelarAtencion">Salir</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import dfi from './dfi.js'
import lugares from '../lugares.js'
import Datepicker from 'vue3-date-time-picker'
import 'vue3-date-time-picker/dist/main.css'
import TableLite from "vue3-table-lite"
import store from '@/store/index';
export default {
    name: 'caracterizacion',
    components: { 
        Datepicker,
        "table-lite": TableLite 
    },
    data () {
        return {
            guardandoCategoria: false,
            perfil: '',
            perfilPermitido: false,
            token: '',
            url: '',
            fechaInicio: '',
            fechaFin: '',
            minDate: '2019-07-01',
            maxDate: new Date(),
            dxs: [],
            filter: null,
            striped: true,
            bordered: true,
            stickyHeader: "800px",
            fields: [
                { field: 'dx_fecha', label: 'Fecha dx' },
                { field: 'id_1_1_1__p_nombre', label: 'Primer nombre' },
                { field: 'id_1_1_2__s_nombre', label: 'Segundo nombre' },
                { field: 'id_1_1_3__p_apellido', label: 'Primer apellido' },
                { field: 'id_1_1_4__s_apellido', label: 'Segundo apellido'},
                { field: 'id_1_2_2__n_documento', label: 'Documento', stickyColumn: true },
                { field: 'categorianame', label: 'Categoria' },
                { field: 'factordet', label: 'Hecho determinante' },             
                { field: 'actions', label: 'Acciones' }
            ],
            infoModal: {
                id: 'info-modal',
                title: false,
                content: ''
            },
            categorias: [
                { value: 0, text: 'Sin dato' },
                { value: 1, text: 'Violencia homicida' },
                { value: 2, text: 'Delitos contra la integridad y seguridad personal' },
                { value: 3, text: 'Amenaza' },
                { value: 4, text: 'Extorsión' },
                { value: 5, text: 'Vinculación, reclutamiento y utilización de niños, niñas y adolescentes (NNA)'},
                { value: 6, text: 'Riesgos de género' },
                { value: 7, text: 'Despojo y uso de bienes' },
                { value: 8, text: 'Temor por violencia' },
            ],
            factores: [],
            dfi: dfi,
            lugares: lugares
        }        
    },
    methods: {
        buscarDxs () { 
            if (this.fechaInicio == '' || this.fechaFin  == ''
                || this.fechaInicio == null || this.fechaFin  == null) {
                alert("favor llene los campos de fecha");
                return false
            }
            this.resetInfoModal();          
            axios.get( this.url + 'intraurbanoFecha', 
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`          
                },
                params: { 
                    fechaInicial: this.fechaInicio.toISOString().slice(0, 10),
                    fechaFinal: this.fechaFin.toISOString().slice(0, 10)
                }
            })
            .then((res) => {            
                this.dxs = res.data.data
                this.dxs.forEach(fila => {
                    fila.vict_4_4_2__municipio = (fila.vict_4_4_2__municipio == 12)?"MEDELLÍN":fila.vict_4_4_2__municipio
                    fila.vict_4_4_1__departamento = (fila.vict_4_4_1__departamento == 2)?"ANTIOQUIA":fila.vict_4_4_1__departamento

                    let barrio = fila.vict_4_4_8__hecho_Intraurbano_Barrio
                    lugares.barrio.some(function(item){                        
                        if(item.id == barrio){
                            fila.vict_4_4_8__hecho_Intraurbano_Barrio = item.barrio
                            fila.vict_4_4_7__hecho_Intraurbano_Comuna_o_Corregimiento = item.comuna
                        }
                    })
                    fila.vict_4_4_6__tipo_movilidad = lugares.tipoMovilidad[fila.vict_4_4_6__tipo_movilidad]
                    fila.vict_4_4_4__lugar_exacto_del_municipio = lugares.lugarExacto[fila.vict_4_4_4__lugar_exacto_del_municipio]
                    
                    let categoria = fila.res_6_1_3__categoria
                    dfi.categorias.some(function(item){                        
                        if(item.value == categoria){
                            fila.categorianame = item.text                                                                                  
                        }
                    })

                    let factor = fila.res_6_1_2__factor_determinante
                    dfi.categ.some(function(item){                        
                        if(item.id == factor){
                            fila.factordet = item.factor_incidencia                                                                                  
                        }
                    })
                })        
            })
            .catch((error) => {
                this.iraLogin(error)
            })
        },
        iraLogin: function (error) {
            console.log(error)
        },
        cancelarAtencion: function() {            
            this.resetInfoModal();
        },
        submitCategoria () { 
            this.guardandoCategoria = true; 
            setTimeout(() => {
                    this.guardandoCategoria = false;
                }, 10000);                              
            const params = new URLSearchParams()            
            params.append('categoria', this.infoModal.content.res_6_1_3__categoria)
            params.append('factorIncidencia', this.infoModal.content.res_6_1_2__factor_determinante)
            params.append('otro', this.infoModal.content.res_6_1_1__factores_incidencia)            
            axios.post( this.url + 'categorizacion/' + this.infoModal.content.id, 
                params,
            {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${this.token}`
            }
            })
            .then(() => {
                this.guardandoCategoria = false;
                this.buscarDxs()                         
                alert ("Categorización guardada.")
                this.resetInfoModal();
            })
            .catch((error) => {
                this.iraLogin(error)
            })
        },
        info(item, index) {
            this.resetInfoModal();
            this.cambiarFactores(item.res_6_1_3__categoria)
            this.infoModal.title = true;
            this.infoModal.content = item
        },
        resetInfoModal() {
            this.infoModal.title = false;
            this.infoModal.content = '';
        },
        cambiarFactores(categoria) {
            switch (categoria) {
                case 0:
                    this.factores = [{ value: 0, text: 'Sin dato' }]
                    break
                case 1:
                    this.factores = dfi.vh
                    break;
                case 2:
                    this.factores = dfi.dci
                    break;
                case 3:
                    this.factores = dfi.ame
                    break;
                case 4:
                    this.factores = dfi.extor
                    break;
                case 5:
                    this.factores = dfi.vincnna
                    break;
                case 6:
                    this.factores = dfi.rgen
                    break;
                case 7:
                    this.factores = dfi.despo
                    break;
                case 8:
                    this.factores = dfi.tviolen
                    break;
                default:
                    break;
            }
        }
    },
    mounted () {
        this.token = localStorage.getItem('token');
        this.url = store.getters['auth/urlApi'];
        this.perfil = localStorage.getItem("userPerfil");
        if(this.perfil == "22" || this.perfil == "2"){
            this.perfilPermitido = true
        }
    }
}
</script>