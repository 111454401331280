import {Options, Vue} from 'vue-class-component';
import {loginByGoogle, loginByFacebook} from '@/services/auth';
import Checkbox from '@/components/checkbox/checkbox.vue';
import Input from '@/components/input/input.vue';
import Button from '@/components/button/button.vue';
import {useToast} from 'vue-toastification';

@Options({
    components: {
        'app-checkbox': Checkbox,
        'app-input': Input,
        'app-button': Button
    }
})
export default class Login extends Vue {
    private appElement: HTMLElement | null = null;
    public login: string = '';
    public password: string = '';
    public rememberMe: boolean = false;
    public isAuthLoading: boolean = false;
    public isFacebookLoading: boolean = false;
    public isGoogleLoading: boolean = false;
    private toast = useToast();
    public msgErrorshow: boolean = false;

    public images = [
        '@/assets/img/descarga.jpg', 
        '@/assets/img/img1.jpg', 
        '@/assets/img/img2.jpg', 
        '@/assets/img/img3.jpg', 
        '@/assets/img/img4.jpg', 
    ];
    public timer: any = null;
    public currentIndex: number = 0;
    public currentImg: string = '';
    
    public startSlide() {
        this.timer = setInterval(this.next, 4000);
    }

    public next() {
        this.currentIndex += 1;
    }

    public prev() {
        this.currentIndex -= 1;
    }

    public mounted(): void {

        this.appElement = document.getElementById('app') as HTMLElement;
        this.appElement.classList.add('login-page');


        
    }

    public unmounted(): void {
        (this.appElement as HTMLElement).classList.remove('login-page');
    }

    public async loginByAuth(): Promise<void> {
        try {
            const url = this.$store.getters['auth/urlApi'];
            const data = new URLSearchParams();            
            data.append('usuario', this.login);
            data.append('password', this.password);        
            fetch( url + 'login', {
                method: 'POST',
                body: data
            })
            .then (resp => resp.json())
            .then( data => { 
                if(data.data.error){
                    this.msgErrorshow = true;
                    setTimeout(() => {
                        this.msgErrorshow = false;
                    }, 5000);
                }              
                const token = data.data.token;
                const userName = data.data.name;
                localStorage.setItem('login', this.login);
                localStorage.setItem('token', token);
                const rol = data.data.perfil;
                localStorage.setItem('userName', userName);
                localStorage.setItem('userPerfil', rol);
                this.isAuthLoading = true;  
                this.$store.dispatch('auth/login', token);
                this.toast.success('Ingreso con exito');
                this.isAuthLoading = false;    
            });            
        } catch (error: any) {
            console.log(error);
            this.toast.error(error.message);
            this.isAuthLoading = false;
        }
    }

    public async loginByFacebook(): Promise<void> {
        try {
            this.isFacebookLoading = true;
            const token = await loginByFacebook();
            this.$store.dispatch('auth/login', token);
            this.isFacebookLoading = false;
        } catch (error: any) {
            this.toast.error(error.message);
            this.isFacebookLoading = false;
        }
    }

    public async loginByGoogle(): Promise<void> {
        try {
            this.isGoogleLoading = true;
            const token = await loginByGoogle();
            this.$store.dispatch('auth/login', token);
            this.isGoogleLoading = false;
        } catch (error: any) {
            this.toast.error(error.message);
            this.isGoogleLoading = false;
        }
    }
}
