import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router';
import store from '@/store/index';

import Main from '@/modules/main/main.vue';
import Login from '@/modules/login/login.vue';
import Register from '@/modules/register/register.vue';

import Dashboard from '@/pages/dashboard/dashboard.vue';
import NuevoHogar from '@/pages/nuevohogar/nuevohogar.vue';
import Consulta from '@/pages/consulta/consulta.vue';
import Atencion from '@/pages/atencion/atencion.vue';
import Profile from '@/pages/profile/profile.vue';
import ForgotPassword from '@/modules/forgot-password/forgot-password.vue';
import RecoverPassword from '@/modules/recover-password/recover-password.vue';
import PrivacyPolicy from '@/modules/privacy-policy/privacy-policy.vue';
import SubMenu from '@/pages/main-menu/sub-menu/sub-menu.vue';
import Informe from '@/pages/informe/informe.vue';
import Caracterizacion from '@/pages/diagnostico/caracterizacion/caracterizacion.vue'
import Revisiondx from '@/pages/diagnostico/revisiondx/revisiondx.vue'
import InformeBodega from '@/pages/bodega/informe.vue'
import Albergues from '@/pages/albergue/Albergues.vue'
import InformeAlbergue from '@/pages/albergue/InformeAlbergue.vue'
import ControlAlbergues from '@/pages/albergue/Alberguecontrol.vue'
import ListaEnviadosNotificar from '@/pages/albergue/ListaEnviadosNotificar.vue'


const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Main',
        component: Main,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: 'profile',
                name: 'Profile',
                component: Profile,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'informe',
                name: 'Informe',
                component: Informe,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'sub-menu-1',
                name: 'Sub Menu 1',
                component: SubMenu,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'sub-menu-2',
                name: 'Sub Menu 2',
                component: Informe,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '',
                name: 'Dashboard',
                component: Dashboard,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'nuevoHogar',
                name: 'nuevoHogar',
                component: NuevoHogar,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'consulta',
                name: 'consulta',
                component: Consulta,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'atencion',
                name: 'atencion',
                component: Atencion,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'caracterizacion',
                name: 'caracterizacion',
                component: Caracterizacion,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'revisiondx',
                name: 'revisiondx',
                component: Revisiondx,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'informeBodega',
                name: 'informeBodega',
                component: InformeBodega,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'albergues',
                name: 'albergues',
                component: Albergues,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'informeAlbergue',
                name: 'informeAlbergue',
                component: InformeAlbergue,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'ControlAlbergues',
                name: 'ControlAlbergues',
                component: ControlAlbergues,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'ListaEnviadosNotificar',
                name: 'ListaEnviadosNotificar',
                component: ListaEnviadosNotificar,
                meta: {
                    requiresAuth: true
                }
            }
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            requiresUnauth: true
        }
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
        meta: {
            requiresUnauth: true
        }
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
        meta: {
            requiresUnauth: true
        }
    },
    {
        path: '/recover-password',
        name: 'RecoverPassword',
        component: RecoverPassword,
        meta: {
            requiresUnauth: true
        }
    },
    {
        path: '/privacy-policy',
        name: 'RecoverPassword',
        component: PrivacyPolicy
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth && !store.getters['auth/token']) {        
        next('/login');
    } else if (to.meta.requiresUnauth && !!store.getters['auth/token']) {
        next('/');
    } else {
        next();
    }
});

export default router;
